import React, { useEffect } from 'react'
import Footer from './Footer'
import Header from './Header'
import SideBar from './SideBar'
import { Helmet } from 'react-helmet'

const Contacts = () => {

    useEffect(() => {
     window.scrollTo(0,0);

     
    }, [])

    return (
        <div>
        <Helmet>
        <link rel="canonical" href ="https://bilangastore.com/about"/>

        <title>Contacts</title>
        <meta name="description" content="Veuillez nous Contacter pour tout Renseignement. Nous serons heureux de Répondre à toutes vos questions" />
        
      </Helmet>
            <Header/>
            <div className='aboutO'>
                
                <div className="aboutPic">
                <h1> CONTACTS</h1>
                <img src="photo4.jpg" alt="" srcset="" id='imgAbout' 

                    style={{backgroundSize:"cover"}}
                />

                </div>
                
                <div className="aboutIn">

                <div className="aboutIn2">

                <ul>
                    <li>
                        <h1>Adresse</h1>
                        <p>Kisito, Makelekele, Brazzaville, Congo</p>
                    </li>
                    <li>
                        <h1>Téléphone</h1>
                        <p>+242 06 423 0401</p>
                        <p>+242 05 548 9004</p>
                    </li>
                    <li>
                        <h1>Email</h1>
                        <p>Ecrivez nous sur :</p>
                        <p>mbembaisaac10@.com</p>
                    </li>
                    
                </ul>

                </div>

                <>
                    <SideBar/>
                </>
                </div>

            </div>

            <Footer/>
        </div>
    )
}

export default Contacts
