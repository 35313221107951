import React, { useState } from 'react'
import axios from 'axios'
import { Link } from 'react-router-dom'
import { Helmet } from 'react-helmet'
import { FaEye, FaEyeSlash } from 'react-icons/fa'
import { ToastContainer, toast } from 'react-toastify';

const Register = () => {

    


    const [userLastName,setUserLastName] = useState("")
    const [userFirstName,setUserFirstName] = useState("")
    const [username,setUsername] = useState("")
    const [userCity,setUserCity] = useState("")
    const [userStatus,setUserStatus] = useState("")

    // const [email,setEmail] = useState("")

    // const [mobile,setMobile] = useState("")

    const [password,setPassword] = useState("")

        
        const handleSubmit = async (e)=>{
            e.preventDefault();

            try{
            const res = await axios.post("https://b-elanga-store.onrender.com/api/auth/register", {
                userLastName,userFirstName,userCity,username,password,userStatus
            });

            if( res.data){
                toast.success("Succès: Votre compte a été créé ! ");
 
                setTimeout(()=>{window.location.replace("/login");},3000);      
            }
                

               }catch(error){
            
            toast.error(` Echec: Vous avez deja un compte, choissisiez un nom d'utilisateur unique`)

            setTimeout(()=>{window.location.reload();},3000); 
        }

        }

       

        const [passwordType, setPasswordType] = useState("password");

const showPass = ()=>{

    if(passwordType==="password"){
        
        setPasswordType("text");
        return;
    }else{

        setPasswordType("password");
    } 

}


    return (
        <div className='registerO'>
        <Helmet>
        <link rel="canonical" href ="https://bilangastore.com/register"/>

        <title> s'enregistrer</title>
        <meta name="description" content="Creer un compte Gratuit et commencer a vendre et acheter des produits Agricoles" />
        
      </Helmet>
 
        <div className="registerI">
        <form autoComplete="off" 
        onSubmit={handleSubmit}
        >
            <div className="registerForm">
            <h1> S'ENREGISTRER</h1>
                
                <div className="register">
                
                    <input type="text" name="" id="registerI" placeholder='Entrer votre Nom' required
                        onChange={(e)=> setUserLastName(e.target.value)}

                        onInvalid={e=>e.target.setCustomValidity('Veuillez Entrer Votre Nom svp')}

               onInput={e=>e.target.setCustomValidity('')}
                    />
                    
                </div>
                
                <div className="register">
                
                    <input type="text" name="" id="registerI" placeholder='Entrer votre Prénom' required
                        onChange={(e)=> setUserFirstName(e.target.value)}

                        onInvalid={e=>e.target.setCustomValidity('Veuillez Entrer Votre Prénom svp')}

               onInput={e=>e.target.setCustomValidity('')}
                    />
                </div>
                
                <div className="register">
                
                    <input type="text" name="" id="registerI" placeholder='Entrer votre Ville ou Village' required
                        onChange={(e)=> setUserCity(e.target.value)}

                        onInvalid={e=>e.target.setCustomValidity('Entrer votre Ville ou Village svp')}

               onInput={e=>e.target.setCustomValidity('')}
                    />
                </div>
                
                <div className="register">
                
                    <input type="number"  name="" id="registerI" 
                    placeholder=' Votre Numéro ex:(06xxxxxxx)'
                    inputMode="decimal"
                   required
                  
                    min="1"
                    onChange={e=>{setUsername(e.target.value)}}

                    onInvalid={e=>e.target.setCustomValidity('Veuillez remplir ce champ comme 06 XXX XX XX')}

                    onInput={e=>e.target.setCustomValidity('')}
                />
                </div>

                {/* <div className="register">
                
                    <input type="text" name="" id="registerI" placeholder="Entrer votre nom d'utilisateur" required
                        onChange={(e)=> setUsername(e.target.value)}

                         onInvalid={e=>e.target.setCustomValidity("Veuillez Entrer Votre nom d'utilisateur unique svp")}

               onInput={e=>e.target.setCustomValidity('')}

                        
                    />
                </div> */}

                
                {/* <div className="register">
                
                    <input type="email" name="" id="emailIR" placeholder='Entrer Votre email' required 
                        onChange={(e)=> setEmail(e.target.value)}

                onInvalid={e=>e.target.setCustomValidity("Veuillez Entrer Votre email  svp")}

               onInput={e=>e.target.setCustomValidity('')}

                    />
                </div> */}

                {/* <div className="register">
                
                    <input type="number" name="" id="registerI" placeholder="Entrer votre Numéro Mobile " required
                        onChange={(e)=> setMobile(e.target.value)}
                    
                    inputMode="decimal"
                    min="1"

                        onInvalid={e=>e.target.setCustomValidity("Veuillez Entrer Votre Numéro Mobile svp")}

               onInput={e=>e.target.setCustomValidity('')}

                    />
                </div> */}
                
                <div className="register">
                   <input type={passwordType} name="password" 
                      
                     id="registerI" placeholder='Entrer un code pin de 4 chiffres' required
                        onChange={(e)=> setPassword(e.target.value)}

                        onInvalid={e=>e.target.setCustomValidity("Veuillez Entrer Votre Mot de Pass svp")}

               onInput={e=>e.target.setCustomValidity('')}
                    />
                    {passwordType ==="password" ? (<FaEyeSlash size="1.5em" id="eye2" onClick={showPass} />):(<FaEye id="eye2" size="1.5em" 
                        onClick={showPass}
                    />)}
                </div>

                 <div className="register">
                
                    <input type="text" name="" id="registerI" placeholder='Entrer votre status'
                        onChange={(e)=> setUserStatus(e.target.value)}

                        onInvalid={e=>e.target.setCustomValidity('Entrer votre Ville ou Village svp')}

               onInput={e=>e.target.setCustomValidity('')}
                    />
                </div>

                <div className="loginBR">
                    <button id="loginBIR" type="submit">S'Enregistrer</button>
                </div>

                 <div className='loginOptions' >
                    <h3>Déjà un compte ? <span><Link to="/login"> Connectez-vous</Link></span></h3>
                </div>

                
            </div>
            
            </form>

        </div>
            
        </div>

    )
}

export default Register
