
import React from 'react'
import { ThreeCircles, Vortex } from  'react-loader-spinner'


const Loading = () => {
    return (
        <div>
            {/* <Vortex
  visible={true}
  height="100"
  width="100"
  ariaLabel="vortex-loading"
  wrapperStyle={{}}
  wrapperClass="vortex-wrapper"
  colors={['#A7C957', '#BC4749','#A7C957','#BC4749','#BC4749','#A7C957']}
/> */}

<ThreeCircles
  height="60"
  width="60"
  color=""
  wrapperStyle={{}}
  wrapperClass=""
  visible={true}
  ariaLabel="three-circles-rotating"
  outerCircleColor="#A7C957"
  innerCircleColor="#BC4749"
  middleCircleColor="#ffffff"
/>
        </div>
    )
}

export default Loading
