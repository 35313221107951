import React, { useContext, useEffect, useState } from 'react'
import { FaShoppingCart, FaTractor, FaUser } from 'react-icons/fa'
import Header from './Header'
import axios from "axios"
import { Link } from 'react-router-dom'
import Moment from 'react-moment'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Context } from '../context/Context';


const Dashboard = () => {

    const { user, dispatch,admin } = useContext(Context);

    const [monitor, setMonitor] = useState(false);

const moni = ()=>{
if(user){

    if(user.userStatus ==="admin"){
        setMonitor(true);
    }
}
}

 useEffect(() => {
    moni();
    
  }, []);


    const [dash, setDash] = useState(false);
    const [dash3, setDash3] = useState(false);
    const [dash4, setDash4] = useState(false);
    const [dash5, setDash5] = useState(false);
    const [dash6, setDash6] = useState(true);

    
    const change = ()=>{
        setDash(true);
        setDash3(false);
        setDash4(false);
        setDash5(false);
        setDash6(false);
        
    }

    const change3 = ()=>{
        setDash3(true);
        setDash(false);
        setDash4(false);
        setDash5(false);
         setDash6(false);
        
    }
    const change4 = ()=>{
        setDash4(true);
        setDash(false);
        setDash3(false);
         setDash5(false);
          setDash6(false); 
    }

    const change5 = ()=>{
        setDash5(true);
        setDash(false);
        setDash4(false);
        setDash3(false);
         setDash6(false);
        
    }

    const change6 = ()=>{
        setDash5(false);
        setDash(false);
        setDash4(false);
        setDash3(false);
         setDash6(true);
        
    }

    // Users 
    //Get all users

    const [users, setUsers] = useState([]);

    useEffect(() =>{
        window.scrollTo(0,0);
        
         const getUsers = async ()=>{
            const res = await axios.get('https://b-elanga-store.onrender.com/api/users/');
            setUsers(res.data);
            
         };
         getUsers();
    },[])


    // Reservations

    const [reserv,setReserv] = useState([]);

    useEffect(() =>{
        window.scrollTo(0,0);
        
         const getReserv = async ()=>{
            const res = await axios.get('https://b-elanga-store.onrender.com/api/reserv/');
            setReserv(res.data);
            
         };
         getReserv();
    },[])



    //Types de Culture
    //post
    const [nameCulture, setNameCulture] = useState("")
    const handleCulture = async (e)=>{
        e.preventDefault();
        const newPost ={
            nameCulture     
        }

        try{
                const res = await axios.post('https://b-elanga-store.onrender.com/api/cate/', newPost);

                toast.success(`${nameCulture + " "} a été ajouté dans la base  de donnée: Culture Agricole`)

                setTimeout(()=>{window.location.reload();},3000);                   
 
        }catch(error){
            
            toast.error(` Echec: ${nameCulture + " "} n'a pas été ajouté dans la base  de donnée: Culture Agricole`)

            setTimeout(()=>{window.location.reload();},3000);

        } 

    }


    //Get type of Culture
    const [cat, setCat] = useState([]);

    useEffect(() =>{
        window.scrollTo(0,0);
        
         const getCat = async ()=>{
            const res = await axios.get('https://b-elanga-store.onrender.com/api/cate/');
            setCat(res.data);
            
         };
         getCat();
    },[])


    //Fournisseurs
const [lastNameF,setLastNameF]=useState("");
const [firstNameF,setFirstNameF]=useState("");
const [typeCultureF,setTypeCultureF]=useState("");
const [phoneNumberF,setPhoneNumberF]=useState("");
const [farmingAdresseF,setFarmingAdresseF]=useState("");



const addingFournisseur = async (e)=>{
        e.preventDefault();

        const listF ={
            lastNameF,firstNameF,typeCultureF,phoneNumberF,farmingAdresseF,
        }
    

        try{
                const res = await axios.post('https://b-elanga-store.onrender.com/api/fournisseurs/',listF
                   );
toast.success(`${lastNameF + " "} ${firstNameF + " "} a été ajouté dans la base  de donnée: Fournisseur`)

                setTimeout(()=>{window.location.reload();},3000);                   
 
        }catch(error){
            
            toast.error(` Echec: ${lastNameF + " "} ${firstNameF + " "} n'a pas été ajouté dans la base  de donnée: Fournisseur`)

            setTimeout(()=>{window.location.reload();},3000);

        } 
    
    }

// Getting Fournisseurs list
const [fournisseurList,setFournisseurList] = useState([]);

useEffect(() =>{
        
         const getListF = async ()=>{
            const res = await axios.get('https://b-elanga-store.onrender.com/api/fournisseurs/');

            setFournisseurList(res.data);

            
         };
         getListF();


    },[])



    //Acheteurs
const [lastNameA,setLastNameA]=useState("");
const [firstNameA,setFirstNameA]=useState("");
const [typeCultureA,setTypeCultureA]=useState("");
const [phoneNumberA,setPhoneNumberA]=useState("");
const [buyerAdresseA,setBuyerAdresseA]=useState("");


const addingAcheteur = async (e)=>{
        e.preventDefault();

        const listA ={
            lastNameA,firstNameA,typeCultureA,phoneNumberA,buyerAdresseA,
        }
    

        try{
                const res = await axios.post('https://b-elanga-store.onrender.com/api/acheteurs/',listA
                   );

toast.success(`${lastNameA + " "} ${firstNameA + " "} a été ajouté dans la base  de donnée: Acheteur`)

                setTimeout(()=>{window.location.reload();},3000);                   
 
        }catch(error){
            
            toast.error(` Echec: ${lastNameA + " "} ${firstNameA + " "} n'a pas été ajouté dans la base  de donnée: Acheteur`)

            setTimeout(()=>{window.location.reload();},3000); 
        }

    }

    
    // Getting Acheteurs list

    const [acheteurList,setAcheteurList] = useState([]);

useEffect(() =>{
        
         const getListA = async ()=>{
            const res = await axios.get('https://b-elanga-store.onrender.com/api/acheteurs/');

            setAcheteurList(res.data);

            
         };
         getListA();
        


    },[])




    //all SMS Notifications Categories

const [lastNameNoti,setLastNameNoti]=useState("");
const [firstNameNoti,setFirstNameNoti]=useState("");
const [typeCultureNoti,setTypeCultureNoti]=useState("");
const [phoneNumberNoti,setPhoneNumberNoti]=useState("");
const [buyerAdresseNoti,setBuyerAdresseNoti]=useState("");


// Adding SMS nOTIFICATION

const addingSmsNoti = async (e)=>{
        e.preventDefault();

        const listNoti ={
            lastNameNoti,firstNameNoti,typeCultureNoti,phoneNumberNoti,buyerAdresseNoti,
        }

        if(typeCultureNoti ==="Tomates"){
        try{
             const res = await axios.post('https://b-elanga-store.onrender.com/api/tomate/',listNoti
                   );
              
                   toast.success(`${lastNameNoti + " "} ${firstNameNoti + " "} a été ajouté à la Catégorie ${typeCultureNoti}`)

                setTimeout(()=>{window.location.reload();},3000);                   
 
        }catch(error){
            
            toast.error(` Echec: ${lastNameNoti + " "} ${firstNameNoti + " "} n'a pas été ajouté à la Catégorie ${typeCultureNoti}`)

            setTimeout(()=>{window.location.reload();},3000); 
        }

        }

        else if(typeCultureNoti ==="Arachide"){
             try{
             const res = await axios.post('https://b-elanga-store.onrender.com/api/arachide/',listNoti
                   );

 toast.success(`${lastNameNoti + " "} ${firstNameNoti + " "} a été ajouté à la Catégorie ${typeCultureNoti}`)

                setTimeout(()=>{window.location.reload();},3000);                   
 
        }catch(error){
            
            toast.error(` Echec: ${lastNameNoti + " "} ${firstNameNoti + " "} n'a pas été ajouté à la Catégorie ${typeCultureNoti}`)

            setTimeout(()=>{window.location.reload();},3000); 
        }

        }

        else if( typeCultureNoti=== "Tubercules de Manioc"){


    try{
const res = await axios.post('https://b-elanga-store.onrender.com/api/tuberculeDeManioc/',listNoti
                   );
 toast.success(`${lastNameNoti + " "} ${firstNameNoti + " "} a été ajouté à la Catégorie ${typeCultureNoti}`)

                setTimeout(()=>{window.location.reload();},3000);                   
 
        }catch(error){
            
            toast.error(` Echec: ${lastNameNoti + " "} ${firstNameNoti + " "} n'a pas été ajouté à la Catégorie ${typeCultureNoti}`)

            setTimeout(()=>{window.location.reload();},3000); 
        }

    }

 else if(typeCultureNoti === "Banane Plantain"){

    try{
const res = await axios.post('https://b-elanga-store.onrender.com/api/bananePlantain/',listNoti
                   );
 toast.success(`${lastNameNoti + " "} ${firstNameNoti + " "} a été ajouté à la Catégorie ${typeCultureNoti}`)

                setTimeout(()=>{window.location.reload();},3000);                   
 
        }catch(error){
            
            toast.error(` Echec: ${lastNameNoti + " "} ${firstNameNoti + " "} n'a pas été ajouté à la Catégorie ${typeCultureNoti}`)

            setTimeout(()=>{window.location.reload();},3000); 
        }

    }

           
 else if(typeCultureNoti === "Haricot"){

    try{
const res = await axios.post('https://b-elanga-store.onrender.com/api/haricot/',listNoti
                   );
 toast.success(`${lastNameNoti + " "} ${firstNameNoti + " "} a été ajouté à la Catégorie ${typeCultureNoti}`)

                setTimeout(()=>{window.location.reload();},3000);                   
 
        }catch(error){
            
            toast.error(` Echec: ${lastNameNoti + " "} ${firstNameNoti + " "} n'a pas été ajouté à la Catégorie ${typeCultureNoti}`)

            setTimeout(()=>{window.location.reload();},3000); 
        }

    }

 else if(typeCultureNoti === "Igname"){

    try{
const res = await axios.post('https://b-elanga-store.onrender.com/api/igname/',listNoti
                   );
toast.success(`${lastNameNoti + " "} ${firstNameNoti + " "} a été ajouté à la Catégorie ${typeCultureNoti}`)

                setTimeout(()=>{window.location.reload();},3000);                   
 
        }catch(error){
            
            toast.error(` Echec: ${lastNameNoti + " "} ${firstNameNoti + " "} n'a pas été ajouté à la Catégorie ${typeCultureNoti}`)

            setTimeout(()=>{window.location.reload();},3000); 
        }

    }

 else if(typeCultureNoti === "Oignons"){

    try{
const res = await axios.post('https://b-elanga-store.onrender.com/api/oignons/',listNoti
                   );
 toast.success(`${lastNameNoti + " "} ${firstNameNoti + " "} a été ajouté à la Catégorie ${typeCultureNoti}`)

                setTimeout(()=>{window.location.reload();},3000);                   
 
        }catch(error){
            
            toast.error(` Echec: ${lastNameNoti + " "} ${firstNameNoti + " "} n'a pas été ajouté à la Catégorie ${typeCultureNoti}`)

            setTimeout(()=>{window.location.reload();},3000); 
        }
    }

           
    else if(typeCultureNoti === "Ciboules"){
        // 

    try{
const res = await axios.post('https://b-elanga-store.onrender.com/api/ciboules/',listNoti
                   );
toast.success(`${lastNameNoti + " "} ${firstNameNoti + " "} a été ajouté à la Catégorie ${typeCultureNoti}`)

                setTimeout(()=>{window.location.reload();},3000);                   
 
        }catch(error){
            
            toast.error(` Echec: ${lastNameNoti + " "} ${firstNameNoti + " "} n'a pas été ajouté à la Catégorie ${typeCultureNoti}`)

            setTimeout(()=>{window.location.reload();},3000); 
        }
    }
  
 else if(typeCultureNoti === "Piment"){

    try{
const res = await axios.post('https://b-elanga-store.onrender.com/api/piment/',listNoti
                   );
toast.success(`${lastNameNoti + " "} ${firstNameNoti + " "} a été ajouté à la Catégorie ${typeCultureNoti}`)

                setTimeout(()=>{window.location.reload();},3000);                   
 
        }catch(error){
            
            toast.error(` Echec: ${lastNameNoti + " "} ${firstNameNoti + " "} n'a pas été ajouté à la Catégorie ${typeCultureNoti}`)

            setTimeout(()=>{window.location.reload();},3000); 
        }

    }

 else if(typeCultureNoti === "Aubergine"){

    try{
const res = await axios.post('https://b-elanga-store.onrender.com/api/aubergine/',listNoti
                   );
 toast.success(`${lastNameNoti + " "} ${firstNameNoti + " "} a été ajouté à la Catégorie ${typeCultureNoti}`)

                setTimeout(()=>{window.location.reload();},3000);                   
 
        }catch(error){
            
            toast.error(` Echec: ${lastNameNoti + " "} ${firstNameNoti + " "} n'a pas été ajouté à la Catégorie ${typeCultureNoti}`)

            setTimeout(()=>{window.location.reload();},3000); 
        }
    }

    else if(typeCultureNoti === "Concombre"){
        

    try{
const res = await axios.post('https://b-elanga-store.onrender.com/api/concombre/',listNoti
                   );
 toast.success(`${lastNameNoti + " "} ${firstNameNoti + " "} a été ajouté à la Catégorie ${typeCultureNoti}`)

                setTimeout(()=>{window.location.reload();},3000);                   
 
        }catch(error){
            
            toast.error(` Echec: ${lastNameNoti + " "} ${firstNameNoti + " "} n'a pas été ajouté à la Catégorie ${typeCultureNoti}`)

            setTimeout(()=>{window.location.reload();},3000); 
        }
    }

 else if(typeCultureNoti === "Poivron Vert"){

    try{
const res = await axios.post('https://b-elanga-store.onrender.com/api/poivronVert/',listNoti
                   );
 toast.success(`${lastNameNoti + " "} ${firstNameNoti + " "} a été ajouté à la Catégorie ${typeCultureNoti}`)

                setTimeout(()=>{window.location.reload();},3000);                   
 
        }catch(error){
            
            toast.error(` Echec: ${lastNameNoti + " "} ${firstNameNoti + " "} n'a pas été ajouté à la Catégorie ${typeCultureNoti}`)

            setTimeout(()=>{window.location.reload();},3000); 
        }
    }

 else if(typeCultureNoti === "Gingembre"){

    try{
const res = await axios.post('https://b-elanga-store.onrender.com/api/gingembre/',listNoti
                   );
 toast.success(`${lastNameNoti + " "} ${firstNameNoti + " "} a été ajouté à la Catégorie ${typeCultureNoti}`)

                setTimeout(()=>{window.location.reload();},3000);                   
 
        }catch(error){
            
            toast.error(` Echec: ${lastNameNoti + " "} ${firstNameNoti + " "} n'a pas été ajouté à la Catégorie ${typeCultureNoti}`)

            setTimeout(()=>{window.location.reload();},3000); 
        }
    }

           
    else if(typeCultureNoti === "Pomme de Terre"){
        // 

    try{
const res = await axios.post('https://b-elanga-store.onrender.com/api/pommedeTerre/',listNoti
                   );
 toast.success(`${lastNameNoti + " "} ${firstNameNoti + " "} a été ajouté à la Catégorie ${typeCultureNoti}`)

                setTimeout(()=>{window.location.reload();},3000);                   
 
        }catch(error){
            
            toast.error(` Echec: ${lastNameNoti + " "} ${firstNameNoti + " "} n'a pas été ajouté à la Catégorie ${typeCultureNoti}`)

            setTimeout(()=>{window.location.reload();},3000); 
        }

    }

 else if(typeCultureNoti === "Patate Douce"){

    try{
const res = await axios.post('https://b-elanga-store.onrender.com/api/patateDouce/',listNoti
                   );
 toast.success(`${lastNameNoti + " "} ${firstNameNoti + " "} a été ajouté à la Catégorie ${typeCultureNoti}`)

                setTimeout(()=>{window.location.reload();},3000);                   
 
        }catch(error){
            
            toast.error(` Echec: ${lastNameNoti + " "} ${firstNameNoti + " "} n'a pas été ajouté à la Catégorie ${typeCultureNoti}`)

            setTimeout(()=>{window.location.reload();},3000); 
        }
    }

           
        // 
 else if(typeCultureNoti === "Maïs"){

    try{
const res = await axios.post('https://b-elanga-store.onrender.com/api/mais/',listNoti
                   );
toast.success(`${lastNameNoti + " "} ${firstNameNoti + " "} a été ajouté à la Catégorie ${typeCultureNoti}`)

                setTimeout(()=>{window.location.reload();},3000);                   
 
        }catch(error){
            
            toast.error(` Echec: ${lastNameNoti + " "} ${firstNameNoti + " "} n'a pas été ajouté à la Catégorie ${typeCultureNoti}`)

            setTimeout(()=>{window.location.reload();},3000); 
        }

    }

           
        
 else if(typeCultureNoti === "Pois d'Angole"){

    try{
const res = await axios.post('https://b-elanga-store.onrender.com/api/poisdAngole/',listNoti
                   );
 toast.success(`${lastNameNoti + " "} ${firstNameNoti + " "} a été ajouté à la Catégorie ${typeCultureNoti}`)

                setTimeout(()=>{window.location.reload();},3000);                   
 
        }catch(error){
            
            toast.error(` Echec: ${lastNameNoti + " "} ${firstNameNoti + " "} n'a pas été ajouté à la Catégorie ${typeCultureNoti}`)

            setTimeout(()=>{window.location.reload();},3000); 
        }
    }

           
        // 
 else if(typeCultureNoti === "Carotte"){

    try{
const res = await axios.post('https://b-elanga-store.onrender.com/api/carotte/',listNoti
                   );
 toast.success(`${lastNameNoti + " "} ${firstNameNoti + " "} a été ajouté à la Catégorie ${typeCultureNoti}`)

                setTimeout(()=>{window.location.reload();},3000);                   
 
        }catch(error){
            
            toast.error(` Echec: ${lastNameNoti + " "} ${firstNameNoti + " "} n'a pas été ajouté à la Catégorie ${typeCultureNoti}`)

            setTimeout(()=>{window.location.reload();},3000); 
        }
    }
      
        // 

         else if(typeCultureNoti === "Soja"){

    try{
const res = await axios.post('https://b-elanga-store.onrender.com/api/soja/',listNoti
                   );
 toast.success(`${lastNameNoti + " "} ${firstNameNoti + " "} a été ajouté à la Catégorie ${typeCultureNoti}`)

                setTimeout(()=>{window.location.reload();},3000);                   
 
        }catch(error){
            
            toast.error(` Echec: ${lastNameNoti + " "} ${firstNameNoti + " "} n'a pas été ajouté à la Catégorie ${typeCultureNoti}`)

            setTimeout(()=>{window.location.reload();},3000); 
        }
    }
      
    // 

     else if(typeCultureNoti === "Sorgho"){

    try{
const res = await axios.post('https://b-elanga-store.onrender.com/api/sorgho/',listNoti
                   );
 toast.success(`${lastNameNoti + " "} ${firstNameNoti + " "} a été ajouté à la Catégorie ${typeCultureNoti}`)

                setTimeout(()=>{window.location.reload();},3000);                   
 
        }catch(error){
            
            toast.error(` Echec: ${lastNameNoti + " "} ${firstNameNoti + " "} n'a pas été ajouté à la Catégorie ${typeCultureNoti}`)

            setTimeout(()=>{window.location.reload();},3000); 
        }
    }
      
        
        

    }



    // Getting all SMS Notifications Categories


    // Getting All the sms Notification

        const [tomateList,setTomateList] = useState([]);
        const [arachideList,setArachideList] = useState([]);
        const [TuberculesdeManioc,setTuberculesdeManioc] = useState([]);
        const [BananePlantain,setBananePlantain] = useState([]);
        const [Haricot,setHaricot] = useState([]);
        const [Igname,setIgname] = useState([]);
        const [Oignons,setOignons] = useState([]);
        const [Ciboules,setCiboules] = useState([]);
        const [Piment,setPiment] = useState([]);
        const [Aubergine,setAubergine] = useState([]);
        const [Concombre,setConcombre] = useState([]);
        const [PoivronVert,setPoivronVert] = useState([]);
        const [Gingembre,setGingembre] = useState([]);
        const [PommedeTerre,setPommedeTerre] = useState([]);
        const [PatateDouce,setPatateDouce] = useState([]);
        const [Maïs,setMaïs] = useState([]);
         const [PoisdAngole,setPoisdAngole] = useState([]);
        const [Carotte,setCarotte] = useState([]);
        const [Soja,setSoja] = useState([]);
        const [Sorgho,setSorgho] = useState([]);
        
useEffect(() =>{
        
         const getTomateList = async ()=>{
            const res = await axios.get('https://b-elanga-store.onrender.com/api/tomate/');

            setTomateList(res.data);     
         };

         const getArachideList = async ()=>{
            const res = await axios.get('https://b-elanga-store.onrender.com/api/arachide/');
            setArachideList(res.data);     
         };

         const getTuberculesdeManiocList = async ()=>{
            const res = await axios.get('https://b-elanga-store.onrender.com/api/tuberculeDeManioc/');
            setTuberculesdeManioc(res.data);     
         };
         const getBananePlantainList = async ()=>{
            const res = await axios.get('https://b-elanga-store.onrender.com/api/bananePlantain/');
            setBananePlantain(res.data);     
         };
         const getHaricotList = async ()=>{
            const res = await axios.get('https://b-elanga-store.onrender.com/api/haricot/');
            setHaricot(res.data);     
         };
         const getIgnameList = async ()=>{
            const res = await axios.get('https://b-elanga-store.onrender.com/api/igname/');
            setIgname(res.data);     
         };
         const getOignonsList = async ()=>{
            const res = await axios.get('https://b-elanga-store.onrender.com/api/oignons/');
            setOignons(res.data);     
         };
         const getCiboulesList = async ()=>{
            const res = await axios.get('https://b-elanga-store.onrender.com/api/ciboules/');
            setCiboules(res.data);     
         };
         const getPimentList = async ()=>{
            const res = await axios.get('https://b-elanga-store.onrender.com/api/piment/');
            setPiment(res.data);     
         };
         const getAubergineList = async ()=>{
            const res = await axios.get('https://b-elanga-store.onrender.com/api/aubergine/');
            setAubergine(res.data);     
         };
         const getConcombreList = async ()=>{
            const res = await axios.get('https://b-elanga-store.onrender.com/api/concombre/');
            setConcombre(res.data);     
         };
         const getPoivronVertList = async ()=>{
            const res = await axios.get('https://b-elanga-store.onrender.com/api/poivronVert/');
            setPoivronVert(res.data);     
         };
         const getGingembreList = async ()=>{
            const res = await axios.get('https://b-elanga-store.onrender.com/api/gingembre/');
            setGingembre(res.data);     
         };
         const getPommedeTerreList = async ()=>{
            const res = await axios.get('https://b-elanga-store.onrender.com/api/pommedeTerre/');
            setPommedeTerre(res.data);     
         };
         const getPatateDouceList = async ()=>{
            const res = await axios.get('https://b-elanga-store.onrender.com/api/patateDouce/');
            setPatateDouce(res.data);     
         };
         const getMaïsList = async ()=>{
            const res = await axios.get('https://b-elanga-store.onrender.com/api/mais/');
            setMaïs(res.data);     
         };
         const getPoisdAngoleList = async ()=>{
            const res = await axios.get('https://b-elanga-store.onrender.com/api/poisdAngole/');
            setPoisdAngole(res.data);     
         };
         const getCarotteList = async ()=>{
            const res = await axios.get('https://b-elanga-store.onrender.com/api/carotte/');
            setCarotte(res.data);     
         };

         const getSojaList = async ()=>{
            const res = await axios.get('https://b-elanga-store.onrender.com/api/soja/');
            setSoja(res.data);     
         };

         const getSorghoList = async ()=>{
            const res = await axios.get('https://b-elanga-store.onrender.com/api/sorgho/');
            setSorgho(res.data);     
         };

         getTomateList();
         getArachideList();
        getTuberculesdeManiocList();
        getBananePlantainList();
        getHaricotList();
        getIgnameList();
        getOignonsList();
        getCiboulesList();
        getPimentList();
        getAubergineList();
        getConcombreList();
        getPoivronVertList();
        getGingembreList();
        getPommedeTerreList();
        getPatateDouceList();
        getMaïsList();
        getPoisdAngoleList();
        getCarotteList();
        getSojaList();
        getSorghoList();
        

    },[])

    
    return (
    <>
    
    <Header/>
        <div className='dashO'>
        
          <div className="dashMenu">
         <ul>
            <li> <div className="dashMenuIn" >
                <h3> ADMINISTRATEUR</h3>
            </div>  </li>

            <li> <div className="dashMenuIn3" onClick={change6}>
                <FaTractor size="2em"/> <h3>Abonnements </h3>
            </div>  </li>

             <li> <div className="dashMenuIn3" onClick={change4}>
                <FaShoppingCart size="2em"/> <h3>Fournisseurs </h3>
            </div>  </li>

             <li> <div className="dashMenuIn3" onClick={change5}>
                <FaTractor size="2em"/> <h3>Acheteurs </h3>
            </div>  </li>

            <li> <div className="dashMenuIn" onClick={change}>
                <FaUser size="2em"/><h3> Utilisateurs</h3>
            </div>  </li>

            <li> <div className="dashMenuIn3" onClick={change3}>
                <FaTractor size="2em"/> <h3>Culture Agricole</h3>
            </div>  </li>
            
         </ul>

          </div>

          <div className='dashContent' >
            {dash &&(
                <div className='dashUserO'>
                    <div className='contentUser'>
                    <h1> Utilisateurs</h1>
                    <h3>TOTAL : {users.length}</h3>
                    <table>
                        <tr>
                            <th>Nom</th>
                            <th>Prénom</th>
                            <th>Adresse</th>
                            <th>Contacts</th>
                            <th>Début</th>
                            <th>MoMo</th>

                         {
                            monitor &&(
                                <th>Options</th>
                            )
                         }   

                        </tr>

                        {users.map((u)=>(


                        <tr key={u._id}>
                            <td>{u.userLastName}</td>
                            <td>{u.userFirstName}</td>
                            <td>{u.userCity}</td>
                            <td>{u.username}</td>

                            {u.reabonner ? (<td><Moment format='DD-MM-YYYY'>{u.updatedAt}</Moment></td> ):
                            
                            (<td><Moment format='DD-MM-YYYY'>{u.createdAt}</Moment></td> )}
                            
                            <td>{u.momoTransaction}</td>
                            
                             {
                            monitor &&(
                                 <td> <button id='dashContentBtn'     onClick={ async()=>{

        try{

            const res = await axios.delete('https://b-elanga-store.onrender.com/api/users/'+u._id);
            
            toast.success(`L'Utilisateur ${u.username + " "} a été supprimé`)

                setTimeout(()=>{window.location.reload();},3000);                   
 
        }catch(error){
            
            toast.error(` Echec: L'Utilisateur ${u.username + " "} n'a pas été supprimé`)

            setTimeout(()=>{window.location.reload();},3000); 
        }

                        }}
                        >Suprimer</button></td>
                            )
                         }   
                            
                           
                        </tr>
                        ))}

                        
                    </table>

                    </div>
                </div>
            )}

            {dash3 &&(
                <div className='dashUserO'>
                    <div className='contentUser'>
                    <h1> Culture Agricole</h1>
                    <h3>TOTAL : {cat.length}</h3>

                    <form onSubmit={handleCulture}>
                    <div className='addCulture'>
                    <input type="text" required
                        onChange={e=>{setNameCulture(e.target.value)}}
                    />
                    <button id='dashContentBtn2'type="submit">Ajouter</button>
                    </div>
                    

                    </form>
                    <table>
                        <tr>
                            <th>Type de Culture</th>
                           
                            {
                            monitor &&(
                                <th>Options</th>
                            )
                         }   
                           
                            
                            
                            
                        </tr>

                        {cat.map((c)=>(
                            <tr key = {c._id}>
                                <td>
                                    {c.nameCulture}
                                </td>
                                
                                 {
                            monitor &&(
                               <td> <button key={c._id} id='dashContentBtn' onClick={async()=>{

        try{

            const res = await axios.delete('https://b-elanga-store.onrender.com/api/cate/'+c._id);
            
            toast.success(`La Culture de: ${c.nameCulture + " "} a été supprimée`)

                setTimeout(()=>{window.location.reload();},3000);                   
 
        }catch(error){
            
            toast.error(` Echec: La Culture de: ${c.nameCulture + " "} n'a pas été supprimée`)

            setTimeout(()=>{window.location.reload();},3000); 
        }

    
                        }} >Suprimer</button></td>
                            )
                         }   
                                
                                
                            </tr>
                        ))}
                             
                
                    </table>

                    
                    
                    </div>
                </div>
            )}

            {dash4 &&(
                <div className='dashUserO'>
                  
                  <div>
                    <div className="singlePostO">
           
            <div className="singlePostIn">
                <div className="infosSingle">

            <div className="infosClient">
            
            <form  className='reservation'
            onSubmit={addingFournisseur} 
            
            autoComplete="off"
            >
                

                <div className='reservHeading'>

                <h1> Ajouter un Fournisseur</h1>
        
 
                </div>

                 <div className='nameIn'>
                    
                <input id="nameC" type="text" placeholder=' Ecrivez votre Nom' required 
                onChange={e=>{setLastNameF(e.target.value)}}

                onInvalid={e=>e.target.setCustomValidity('Veuillez Entrer Votre Nom svp')}

               onInput={e=>e.target.setCustomValidity('')}

            />
                </div>

                 <div className='nameIn'>
                    
                <input id="nameC" type="text" placeholder=' Ecrivez votre Prenom' required 
                onChange={e=>{setFirstNameF(e.target.value)}}

                onInvalid={e=>e.target.setCustomValidity('Veuillez Entrer votre Prenom')}
                onInput={e=>e.target.setCustomValidity('')}
            />
                </div>

                <div className='cityIn'>
                
                <select name="Ville" id="villeIn"
                value={typeCultureF}
            onChange={e=>{setTypeCultureF(e.target.value)}}  
            required
            onInvalid={e=>e.target.setCustomValidity('Veuillez choisir le Type de Culture')}
            onInput={e=>e.target.setCustomValidity('')}
                
                >

                <option value="Type de Culture">Type de Culture</option>
                <option value="Tubercules de Manioc">Tubercules de Manioc</option>
                <option value="Banane Plantain">Banane Plantain</option>
                <option value="Tomates">Tomates</option>
                <option value="Haricot">Haricot</option>
                <option value="Igname">Igname</option>
                <option value="Arachide">Arachide</option>
                <option value="Oignons">Oignons</option>
                <option value="Ciboules">Ciboules</option>
                <option value="Piment">Piment</option>
                <option value="Aubergine">Aubergine</option>
                <option value="Concombre">Concombre</option>
                <option value="Poivron Vert">Poivron Vert</option>
                <option value="Gingembre">Gingembre</option>
                <option value="Pomme de Terre">Pomme de Terre</option>
                <option value="Patate Douce">Patate Douce</option>
                <option value="Maïs">Maïs</option>
                <option value="Pois d'Angole">Pois d'Angole</option>
                <option value="Carotte">Carotte</option>
                <option value="Soja">Soja</option>
                <option value="Sorgho">Sorgho</option>
                


                </select>

                </div>


                <div className='numberC'>

                <input type="number" name="" id="numberCl" 
                    placeholder=' Votre Numéro ex:(06xxxxxxx)'
                     required
                     onChange={e=>{setPhoneNumberF(e.target.value)}}
                   
                    inputMode="decimal"
                  

                    onInvalid={e=>e.target.setCustomValidity('Veuillez remplir ce champ comme 06 XXX XX XX')}

                    onInput={e=>e.target.setCustomValidity('')}
                />
            </div>

            
                   
                <div className='nameIn'>
                    
                <input id="nameC" type="text" placeholder=' Ecrivez votre Ville ou Vilage' required 
                onChange={e=>{setFarmingAdresseF(e.target.value)}}

                onInvalid={e=>e.target.setCustomValidity('Veuillez Entrer votre  votre Ville ou Vilage')}
                onInput={e=>e.target.setCustomValidity('')}
            />
                </div>

            <div className='btnReserver'>

            <button id='btn-reserv' type="submit"> <h3>
            Ajouter
            </h3> </button>

          

            </div>

            </form>

            </div>

                </div>

            </div>
  

        </div>
                </div>

                    <div className='contentUser2'>
                    <h1> Fournisseurs</h1>
                    <h3>TOTAL : {fournisseurList.length}</h3>
                    <table>
                        <tr>
                            <th>Nom</th>
                            <th>Prénom</th>
                             <th>Culture</th>
                            <th>Contacts</th>
                            <th>Adresse</th>
                            <th>MoMo</th>
                            <th>Début</th>

                            <th>Options</th>
                        </tr>

                        {fournisseurList.map((f)=>(<>
                        <tr key={f._id}>
                        
                            <td>{f.lastNameF}</td>
                            <td>{f.firstNameF}</td>
                            <td>{f.typeCultureF}</td>
                            <td>{f.phoneNumberF}</td>
                            <td>{f.farmingAdresseF}</td>
                            
                            <td> <button id='dashContentBtn'
                            onClick={async()=>{

        try{

            const res = await axios.delete('https://b-elanga-store.onrender.com/api/fournisseurs/admin/'+f._id);
            
            
           toast.success(`Le Fournisseur: ${f.lastNameF + " "} ${f.firstNameF + " "} a été supprimée`)

                setTimeout(()=>{window.location.reload();},3000);                   
 
        }catch(error){
            
            toast.error(` Echec: Le Fournisseur: ${f.lastNameF + " "} ${f.firstNameF + " "} n'a pas été supprimée`)

            setTimeout(()=>{window.location.reload();},3000); 
        }

                        }} 
                            >Suprimer</button></td>
                        
                        </tr>
                        </>))}

                        
                    </table>

                    </div>
                </div>
            )}

            {dash5 &&(
                <div className='dashUserO'>
                <div>
                    <div className="singlePostO">
           
            <div className="singlePostIn">
                <div className="infosSingle">

            <div className="infosClient">
            
            <form  className='reservation'
            onSubmit={addingAcheteur} 
            
            autoComplete="off"
            >
                

                <div className='reservHeading'>

                <h1> Ajouter un Acheteur</h1>
        
 
                </div>

                 <div className='nameIn'>
                    
                <input id="nameC" type="text" placeholder=' Ecrivez votre Nom' required 
                onChange={e=>{setLastNameA(e.target.value)}}

                onInvalid={e=>e.target.setCustomValidity('Veuillez Entrer Votre Nom svp')}

               onInput={e=>e.target.setCustomValidity('')}

            />
                </div>

                 <div className='nameIn'>
                    
                <input id="nameC" type="text" placeholder=' Ecrivez votre Prenom' required 
                onChange={e=>{setFirstNameA(e.target.value)}}

                onInvalid={e=>e.target.setCustomValidity('Veuillez Entrer votre Prenom')}
                onInput={e=>e.target.setCustomValidity('')}
            />
                </div>

                <div className='cityIn'>
                
                <select name="Ville" id="villeIn"
                value={typeCultureA}
            onChange={e=>{setTypeCultureA(e.target.value)}}
            required
            onInvalid={e=>e.target.setCustomValidity('Veuillez choisir le Type de Culture')}
            onInput={e=>e.target.setCustomValidity('')}
                
                >

                <option value="Type de Culture">Type de Culture</option>
                <option value="Tubercules de Manioc">Tubercules de Manioc</option>
                <option value="Banane Plantain">Banane Plantain</option>
                <option value="Tomates">Tomates</option>
                <option value="Haricot">Haricot</option>
                <option value="Igname">Igname</option>
                <option value="Arachide">Arachide</option>
                <option value="Oignons">Oignons</option>
                <option value="Ciboules">Ciboules</option>
                <option value="Piment">Piment</option>
                <option value="Aubergine">Aubergine</option>
                <option value="Concombre">Concombre</option>
                <option value="Poivron Vert">Poivron Vert</option>
                <option value="Gingembre">Gingembre</option>
                <option value="Pomme de Terre">Pomme de Terre</option>
                <option value="Patate Douce">Patate Douce</option>
                <option value="Maïs">Maïs</option>
                <option value="Pois d'Angole">Pois d'Angole</option>
                <option value="Carotte">Carotte</option>
                <option value="Soja">Soja</option>
                <option value="Sorgho">Sorgho</option>
                
                

                </select>

                </div>


                <div className='numberC'>
                <input type="number" name="" id="numberCl" 
                    placeholder=' Votre Numéro ex:(06xxxxxxx)'
                    inputMode="decimal"
                   required
                   max="100000000"
                    min="1"
                    onChange={e=>{setPhoneNumberA(e.target.value)}}

                    onInvalid={e=>e.target.setCustomValidity('Veuillez remplir ce champ comme 06 XXX XX XX')}

                    onInput={e=>e.target.setCustomValidity('')}
                />
            </div>

            
                   
                <div className='nameIn'>
                    
                <input id="nameC" type="text" placeholder=' Ecrivez votre Ville ou Vilage' required 
                onChange={e=>{setBuyerAdresseA(e.target.value)}}

                onInvalid={e=>e.target.setCustomValidity('Veuillez Entrer votre  votre Ville ou Vilage')}
                onInput={e=>e.target.setCustomValidity('')}
            />
                </div>

            <div className='btnReserver'>

            <button id='btn-reserv' type="submit"> <h3>
            AJOUTER
            </h3> </button>

          

            </div>

            </form>

            </div>

                </div>

            </div>
  

        </div>
                </div>
                    <div className='contentUser2'>
                    <h1> Acheteurs</h1>
                    <h3>TOTAL : {acheteurList.length}</h3>
                    <table>
                        <tr>
                            <th>Nom</th>
                            <th>Prénom</th>
                             <th>Culture</th>
                            <th>Contacts</th>
                            <th>Ville</th>
                            <th>MoMo</th>
                            <th>Options</th>
                        </tr>

                        {acheteurList.map((a)=>(<>
                        <tr key={a._id}>
                        
                            <td>{a.lastNameA}</td>
                            <td>{a.firstNameA}</td>
                            <td>{a.typeCultureA}</td>
                            <td>{a.phoneNumberA}</td>
                            <td>{a.buyerAdresseA}</td>
                            <td>{a.buyerAdresseA}</td>
                            <td>{a.momoTransaction}</td>
                            
                            
                            <td> <button id='dashContentBtn'
                            onClick={async()=>{

        try{

            const res = await axios.delete('https://b-elanga-store.onrender.com/api/acheteurs/admin/'+a._id);

            toast.success(`L'Acheteur: ${a.lastNameA + " "} ${a.firstNameA + " "} a été supprimé`)

                setTimeout(()=>{window.location.reload();},3000);                   
 
        }catch(error){
            
            toast.error(` Echec: L'Acheteur: ${a.lastNameA + " "} ${a.firstNameA + " "} n'a pas été supprimé`)

            setTimeout(()=>{window.location.reload();},3000); 
        }

                        }} 
                            >Suprimer</button></td>
                        
                        </tr>
                        </>))}

                        
                    </table>

                    </div>
                </div>
            )}

            {dash6 &&(
                <div className='dashUserO'>

                <div>
                    <div className="singlePostO">
           
            <div className="singlePostIn">
                <div className="infosSingle">

            <div className="infosClient">
            
            <form  className='reservation'
            onSubmit={addingSmsNoti} 
            
            autoComplete="off"
            >
                

                <div className='reservHeading'>

                <h1> Ajouter un Abonnement</h1>
        
 
                </div>

                 <div className='nameIn'>
                    
                <input id="nameC" type="text" placeholder=' Ecrivez votre Nom' required 
                onChange={e=>{setLastNameNoti(e.target.value)}}

                onInvalid={e=>e.target.setCustomValidity('Veuillez Entrer Votre Nom svp')}

               onInput={e=>e.target.setCustomValidity('')}

            />
                </div>

                 <div className='nameIn'>
                    
                <input id="nameC" type="text" placeholder=' Ecrivez votre Prenom' required 
                onChange={e=>{setFirstNameNoti(e.target.value)}}

                onInvalid={e=>e.target.setCustomValidity('Veuillez Entrer votre Prenom')}
                onInput={e=>e.target.setCustomValidity('')}
            />
                </div>

                <div className='cityIn'>
                
                <select name="Ville" id="villeIn"
                value={typeCultureNoti}
            onChange={e=>{setTypeCultureNoti(e.target.value)}}  
            required
            onInvalid={e=>e.target.setCustomValidity('Veuillez choisir le Type de Culture')}
            onInput={e=>e.target.setCustomValidity('')}
                
                >

               <option value="Type de Culture">Type de Culture</option>
                <option value="Tubercules de Manioc">Tubercules de Manioc</option>
                <option value="Banane Plantain">Banane Plantain</option>
                <option value="Tomates">Tomates</option>
                <option value="Haricot">Haricot</option>
                <option value="Igname">Igname</option>
                <option value="Arachide">Arachide</option>
                <option value="Oignons">Oignons</option>
                <option value="Ciboules">Ciboules</option>
                <option value="Piment">Piment</option>
                <option value="Aubergine">Aubergine</option>
                <option value="Concombre">Concombre</option>
                <option value="Poivron Vert">Poivron Vert</option>
                <option value="Gingembre">Gingembre</option>
                <option value="Pomme de Terre">Pomme de Terre</option>
                <option value="Patate Douce">Patate Douce</option>
                <option value="Maïs">Maïs</option>
                <option value="Pois d'Angole">Pois d'Angole</option>
                <option value="Carotte">Carotte</option>
                <option value="Soja">Soja</option>
                <option value="Sorgho">Sorgho</option>
                
                

                </select>

                </div>


                <div className='numberC'>
                <input type="number"  name="" id="numberCl" 
                    placeholder=' Votre Numéro ex:(06xxxxxxx)'
                    inputMode="decimal"
                   required
                  
                    min="1"
                    onChange={e=>{setPhoneNumberNoti(e.target.value)}}

                    onInvalid={e=>e.target.setCustomValidity('Veuillez remplir ce champ comme 06 XXX XX XX')}

                    onInput={e=>e.target.setCustomValidity('')}
                />
            </div>

            
                   
                <div className='nameIn'>
                    
                <input id="nameC" type="text" placeholder=' Ecrivez votre Ville ou Vilage' required 
                onChange={e=>{setBuyerAdresseNoti(e.target.value)}}

                onInvalid={e=>e.target.setCustomValidity('Veuillez Entrer votre  votre Ville ou Vilage')}
                onInput={e=>e.target.setCustomValidity('')}
            />
                </div>

            <div className='btnReserver'>

            <button id='btn-reserv' type="submit"> <h3>
            Ajouter
            </h3> </button>

          

            </div>

            </form>

            </div>

                </div>

            </div>
  

        </div>
                </div>

                    <div className='contentUser2'>
                    <h1> Abonnes Service de Notification</h1>

                    <h1>Tomates </h1>
                    <h3>TOTAL : {tomateList.length}</h3>
                    <table>
                        <tr>
                            <th>Nom</th>
                            <th>Prénom</th>
                             <th>Culture</th>
                            <th>Contacts</th>
                            <th>Adresse</th>
                            <th>Début</th>
                            <th>MoMo</th>
                            <th>Options</th>
                        </tr>

                        {tomateList.map((t)=>(<>
                        <tr key={t._id}>
                        
                            <td>{t.lastNameNoti}</td>
                            <td>{t.firstNameNoti}</td>
                            <td>{t.typeCultureNoti}</td>
                            <td>{t.phoneNumberNoti}</td>
                            <td>{t.buyerAdresseNoti}</td>
                            <td><Moment format='DD-MM-YYYY'>{t.createdAt}</Moment></td>
                            <td>{t.momoTransaction}</td>
                              
                            <td> <button id='dashContentBtn'
                            onClick={async()=>{

        try{

            const res = await axios.delete('https://b-elanga-store.onrender.com/api/tomate/'+t._id);
            
            
           toast.success(`L'Abonné: ${t.lastNameNoti + " "} ${t.firstNameNoti + " "} a été supprimé`)

                setTimeout(()=>{window.location.reload();},3000);                   
 
        }catch(error){
            
            toast.error(` Echec: L'Abonné: ${t.lastNameNoti + " "} ${t.firstNameNoti + " "} n'a pas été supprimé`)

            setTimeout(()=>{window.location.reload();},3000); 
        }

                        }} 
                            >Suprimer</button></td>
                        
                        </tr>
                        </>))}

                        
                    </table>

                    </div>


                    <div className='contentUser2'>
                    <h1> Arachides</h1>
                    <h3>TOTAL : {arachideList.length}</h3>
                    <table>
                        <tr>
                            <th>Nom</th>
                            <th>Prénom</th>
                             <th>Culture</th>
                            <th>Contacts</th>
                            <th>Adresse</th>
                            <th>Début</th>
                            <th>MoMo</th>
                            <th>Options</th>
                        </tr>

                        {arachideList.map((t)=>(<>
                        <tr key={t._id}>
                        
                            <td>{t.lastNameNoti}</td>
                            <td>{t.firstNameNoti}</td>
                            <td>{t.typeCultureNoti}</td>
                            <td>{t.phoneNumberNoti}</td>
                            <td>{t.buyerAdresseNoti}</td>
                            <td><Moment format='DD-MM-YYYY'>{t.createdAt}</Moment></td>
                               <td>{t.momoTransaction}</td>
                            <td> <button id='dashContentBtn'
                            onClick={async()=>{

        try{

            const res = await axios.delete('https://b-elanga-store.onrender.com/api/arachide/'+t._id);
            toast.success(`L'Abonné: ${t.lastNameNoti + " "} ${t.firstNameNoti + " "} a été supprimé`)

                setTimeout(()=>{window.location.reload();},3000);                   
 
        }catch(error){
            
            toast.error(` Echec: L'Abonné: ${t.lastNameNoti + " "} ${t.firstNameNoti + " "} n'a pas été supprimé`)

            setTimeout(()=>{window.location.reload();},3000); 
        }

                        }} 
                            >Suprimer</button></td>
                        
                        </tr>
                        </>))}

                        
                    </table>

                    </div>



                    <div className='contentUser2'>
                    <h1> Tubercules de Manioc</h1>
                    <h3>TOTAL : {TuberculesdeManioc.length}</h3>
                    <table>
                        <tr>
                            <th>Nom</th>
                            <th>Prénom</th>
                             <th>Culture</th>
                            <th>Contacts</th>
                            <th>Adresse</th>
                            <th>Début</th>
                            <th>MoMo</th>
                            <th>Options</th>
                        </tr>

                        {TuberculesdeManioc.map((t)=>(<>
                        <tr key={t._id}>
                        
                            <td>{t.lastNameNoti}</td>
                            <td>{t.firstNameNoti}</td>
                            <td>{t.typeCultureNoti}</td>
                            <td>{t.phoneNumberNoti}</td>
                            <td>{t.buyerAdresseNoti}</td>
                            <td><Moment format='DD-MM-YYYY'>{t.createdAt}</Moment></td>
                               <td>{t.momoTransaction}</td>
                            <td> <button id='dashContentBtn'
                            onClick={async()=>{

        try{

            const res = await axios.delete('https://b-elanga-store.onrender.com/api/tuberculeDeManioc/'+t._id);
            toast.success(`L'Abonné: ${t.lastNameNoti + " "} ${t.firstNameNoti + " "} a été supprimé`)

                setTimeout(()=>{window.location.reload();},3000);                   
 
        }catch(error){
            
            toast.error(` Echec: L'Abonné: ${t.lastNameNoti + " "} ${t.firstNameNoti + " "} n'a pas été supprimé`)

            setTimeout(()=>{window.location.reload();},3000); 
        }

                        }} 
                            >Suprimer</button></td>
                        
                        </tr>
                        </>))}

                        
                    </table>

                    </div>



                    <div className='contentUser2'>
                    <h1> Banane Plantain</h1>
                    <h3>TOTAL : {BananePlantain.length}</h3>
                    <table>
                        <tr>
                            <th>Nom</th>
                            <th>Prénom</th>
                             <th>Culture</th>
                            <th>Contacts</th>
                            <th>Adresse</th>
                            <th>Début</th>
                            <th>MoMo</th>
                            <th>Options</th>
                        </tr>

                        {BananePlantain.map((t)=>(<>
                        <tr key={t._id}>
                        
                            <td>{t.lastNameNoti}</td>
                            <td>{t.firstNameNoti}</td>
                            <td>{t.typeCultureNoti}</td>
                            <td>{t.phoneNumberNoti}</td>
                            <td>{t.buyerAdresseNoti}</td>
                            <td><Moment format='DD-MM-YYYY'>{t.createdAt}</Moment></td>
                               <td>{t.momoTransaction}</td>
                            <td> <button id='dashContentBtn'
                            onClick={async()=>{

        try{

            const res = await axios.delete('https://b-elanga-store.onrender.com/api/bananePlantain/'+t._id);
            toast.success(`L'Abonné: ${t.lastNameNoti + " "} ${t.firstNameNoti + " "} a été supprimé`)

                setTimeout(()=>{window.location.reload();},3000);                   
 
        }catch(error){
            
            toast.error(` Echec: L'Abonné: ${t.lastNameNoti + " "} ${t.firstNameNoti + " "} n'a pas été supprimé`)

            setTimeout(()=>{window.location.reload();},3000); 
        }

            }} 
                            >Suprimer</button></td>
                        
                        </tr>
                        </>))}

                          
                    </table>

                    </div>



                    <div className='contentUser2'>
                    <h1> Haricot</h1>
                    <h3>TOTAL : {Haricot.length}</h3>
                    <table>
                        <tr>
                            <th>Nom</th>
                            <th>Prénom</th>
                             <th>Culture</th>
                            <th>Contacts</th>
                            <th>Adresse</th>
                            <th>Début</th>
                            <th>MoMo</th>
                            <th>Options</th>
                        </tr>

                        {Haricot.map((t)=>(<>
                        <tr key={t._id}>
                        
                            <td>{t.lastNameNoti}</td>
                            <td>{t.firstNameNoti}</td>
                            <td>{t.typeCultureNoti}</td>
                            <td>{t.phoneNumberNoti}</td>
                            <td>{t.buyerAdresseNoti}</td>
                            <td><Moment format='DD-MM-YYYY'>{t.createdAt}</Moment></td>
                               <td>{t.momoTransaction}</td>
                            <td> <button id='dashContentBtn'
                            onClick={async()=>{

        try{

            const res = await axios.delete('https://b-elanga-store.onrender.com/api/haricot/'+t._id);
           toast.success(`L'Abonné: ${t.lastNameNoti + " "} ${t.firstNameNoti + " "} a été supprimé`)

                setTimeout(()=>{window.location.reload();},3000);                   
 
        }catch(error){
            
            toast.error(` Echec: L'Abonné: ${t.lastNameNoti + " "} ${t.firstNameNoti + " "} n'a pas été supprimé`)

            setTimeout(()=>{window.location.reload();},3000); 
        }

                        }} 
                            >Suprimer</button></td>
                        
                        </tr>
                        </>))}

                        
                    </table>

                    </div>



                    <div className='contentUser2'>
                    <h1> Igname</h1>
                    <h3>TOTAL : {Igname.length}</h3>
                    <table>
                        <tr>
                            <th>Nom</th>
                            <th>Prénom</th>
                             <th>Culture</th>
                            <th>Contacts</th>
                            <th>Adresse</th>
                            <th>Début</th>
                            <th>MoMo</th>
                            <th>Options</th>
                        </tr>

                        {Igname.map((t)=>(<>
                        <tr key={t._id}>
                        
                            <td>{t.lastNameNoti}</td>
                            <td>{t.firstNameNoti}</td>
                            <td>{t.typeCultureNoti}</td>
                            <td>{t.phoneNumberNoti}</td>
                            <td>{t.buyerAdresseNoti}</td>
                            <td><Moment format='DD-MM-YYYY'>{t.createdAt}</Moment></td>
                               <td>{t.momoTransaction}</td>
                            <td> <button id='dashContentBtn'
                            onClick={async()=>{

        try{

            const res = await axios.delete('https://b-elanga-store.onrender.com/api/igname/'+t._id);
            toast.success(`L'Abonné: ${t.lastNameNoti + " "} ${t.firstNameNoti + " "} a été supprimé`)

                setTimeout(()=>{window.location.reload();},3000);                   
 
        }catch(error){
            
            toast.error(` Echec: L'Abonné: ${t.lastNameNoti + " "} ${t.firstNameNoti + " "} n'a pas été supprimé`)

            setTimeout(()=>{window.location.reload();},3000); 
        }

                        }} 
                            >Suprimer</button></td>
                        
                        </tr>
                        </>))}

                        
                    </table>

                    </div>



                    <div className='contentUser2'>
                    <h1> Oignons</h1>
                    <h3>TOTAL : {Oignons.length}</h3>
                    <table>
                        <tr>
                            <th>Nom</th>
                            <th>Prénom</th>
                             <th>Culture</th>
                            <th>Contacts</th>
                            <th>Adresse</th>
                            <th>Début</th>
                            <th>MoMo</th>
                            <th>Options</th>
                        </tr>

                        {Oignons.map((t)=>(<>
                        <tr key={t._id}>
                        
                            <td>{t.lastNameNoti}</td>
                            <td>{t.firstNameNoti}</td>
                            <td>{t.typeCultureNoti}</td>
                            <td>{t.phoneNumberNoti}</td>
                            <td>{t.buyerAdresseNoti}</td>
                            <td><Moment format='DD-MM-YYYY'>{t.createdAt}</Moment></td>
                               <td>{t.momoTransaction}</td>
                            <td> <button id='dashContentBtn'
                            onClick={async()=>{

        try{

            const res = await axios.delete('https://b-elanga-store.onrender.com/api/oignons/'+t._id);
            toast.success(`L'Abonné: ${t.lastNameNoti + " "} ${t.firstNameNoti + " "} a été supprimé`)

                setTimeout(()=>{window.location.reload();},3000);                   
 
        }catch(error){
            
            toast.error(` Echec: L'Abonné: ${t.lastNameNoti + " "} ${t.firstNameNoti + " "} n'a pas été supprimé`)

            setTimeout(()=>{window.location.reload();},3000); 
        }

                        }} 
                            >Suprimer</button></td>
                        
                        </tr>
                        </>))}

                        
                    </table>

                    </div>



                    <div className='contentUser2'>
                    <h1> Ciboules</h1>
                    <h3>TOTAL : {Ciboules.length}</h3>
                    <table>
                        <tr>
                            <th>Nom</th>
                            <th>Prénom</th>
                             <th>Culture</th>
                            <th>Contacts</th>
                            <th>Adresse</th>
                            <th>Début</th>
                            <th>MoMo</th>
                            <th>Options</th>
                        </tr>

                        {Ciboules.map((t)=>(<>
                        <tr key={t._id}>
                        
                            <td>{t.lastNameNoti}</td>
                            <td>{t.firstNameNoti}</td>
                            <td>{t.typeCultureNoti}</td>
                            <td>{t.phoneNumberNoti}</td>
                            <td>{t.buyerAdresseNoti}</td>
                            <td><Moment format='DD-MM-YYYY'>{t.createdAt}</Moment></td>
                               <td>{t.momoTransaction}</td>
                            <td> <button id='dashContentBtn'
                            onClick={async()=>{

        try{

            const res = await axios.delete('https://b-elanga-store.onrender.com/api/ciboules/'+t._id);
            toast.success(`L'Abonné: ${t.lastNameNoti + " "} ${t.firstNameNoti + " "} a été supprimé`)

                setTimeout(()=>{window.location.reload();},3000);                   
 
        }catch(error){
            
            toast.error(` Echec: L'Abonné: ${t.lastNameNoti + " "} ${t.firstNameNoti + " "} n'a pas été supprimé`)

            setTimeout(()=>{window.location.reload();},3000); 
        }

                        }} 
                            >Suprimer</button></td>
                        
                        </tr>
                        </>))}

                        
                    </table>

                    </div>



                    <div className='contentUser2'>
                    <h1> Piment</h1>
                    <h3>TOTAL : {Piment.length}</h3>
                    <table>
                        <tr>
                            <th>Nom</th>
                            <th>Prénom</th>
                             <th>Culture</th>
                            <th>Contacts</th>
                            <th>Adresse</th>
                            <th>Début</th>
                            <th>MoMo</th>
                            <th>Options</th>
                        </tr>

                        {Piment.map((t)=>(<>
                        <tr key={t._id}>
                        
                            <td>{t.lastNameNoti}</td>
                            <td>{t.firstNameNoti}</td>
                            <td>{t.typeCultureNoti}</td>
                            <td>{t.phoneNumberNoti}</td>
                            <td>{t.buyerAdresseNoti}</td>
                            <td><Moment format='DD-MM-YYYY'>{t.createdAt}</Moment></td>
                               <td>{t.momoTransaction}</td>
                            <td> <button id='dashContentBtn'
                            onClick={async()=>{

        try{

            const res = await axios.delete('https://b-elanga-store.onrender.com/api/piment/'+t._id);
            toast.success(`L'Abonné: ${t.lastNameNoti + " "} ${t.firstNameNoti + " "} a été supprimé`)

                setTimeout(()=>{window.location.reload();},3000);                   
 
        }catch(error){
            
            toast.error(` Echec: L'Abonné: ${t.lastNameNoti + " "} ${t.firstNameNoti + " "} n'a pas été supprimé`)

            setTimeout(()=>{window.location.reload();},3000); 
        }

                        }} 
                            >Suprimer</button></td>
                        
                        </tr>
                        </>))}

                        
                    </table>

                    </div>



                    <div className='contentUser2'>
                    <h1> Aubergine</h1>
                    <h3>TOTAL : {Aubergine.length}</h3>
                    <table>
                        <tr>
                            <th>Nom</th>
                            <th>Prénom</th>
                             <th>Culture</th>
                            <th>Contacts</th>
                            <th>Adresse</th>
                            <th>Début</th>
                            <th>MoMo</th>
                            <th>Options</th>
                        </tr>

                        {Aubergine.map((t)=>(<>
                        <tr key={t._id}>
                        
                            <td>{t.lastNameNoti}</td>
                            <td>{t.firstNameNoti}</td>
                            <td>{t.typeCultureNoti}</td>
                            <td>{t.phoneNumberNoti}</td>
                            <td>{t.buyerAdresseNoti}</td>
                            <td><Moment format='DD-MM-YYYY'>{t.createdAt}</Moment></td>
                               <td>{t.momoTransaction}</td>
                            <td> <button id='dashContentBtn'
                            onClick={async()=>{

        try{

            const res = await axios.delete('https://b-elanga-store.onrender.com/api/aubergine/'+t._id);
            toast.success(`L'Abonné: ${t.lastNameNoti + " "} ${t.firstNameNoti + " "} a été supprimé`)

                setTimeout(()=>{window.location.reload();},3000);                   
 
        }catch(error){
            
            toast.error(` Echec: L'Abonné: ${t.lastNameNoti + " "} ${t.firstNameNoti + " "} n'a pas été supprimé`)

            setTimeout(()=>{window.location.reload();},3000); 
        }

                        }} 
                            >Suprimer</button></td>
                        
                        </tr>
                        </>))}

                        
                    </table>

                    </div>



                    <div className='contentUser2'>
                    <h1> Concombre</h1>
                    <h3>TOTAL : {Concombre.length}</h3>
                    <table>
                        <tr>
                            <th>Nom</th>
                            <th>Prénom</th>
                             <th>Culture</th>
                            <th>Contacts</th>
                           <th>Adresse</th>
                            <th>Début</th>
                            <th>MoMo</th>
                            <th>Options</th>
                        </tr>

                        {Concombre.map((t)=>(<>
                        <tr key={t._id}>
                        
                            <td>{t.lastNameNoti}</td>
                            <td>{t.firstNameNoti}</td>
                            <td>{t.typeCultureNoti}</td>
                            <td>{t.phoneNumberNoti}</td>
                            <td>{t.buyerAdresseNoti}</td>
                            <td><Moment format='DD-MM-YYYY'>{t.createdAt}</Moment></td>
                               <td>{t.momoTransaction}</td>
                            <td> <button id='dashContentBtn'
                            onClick={async()=>{

        try{

            const res = await axios.delete('https://b-elanga-store.onrender.com/api/concombre/'+t._id);
           toast.success(`L'Abonné: ${t.lastNameNoti + " "} ${t.firstNameNoti + " "} a été supprimé`)

                setTimeout(()=>{window.location.reload();},3000);                   
 
        }catch(error){
            
            toast.error(` Echec: L'Abonné: ${t.lastNameNoti + " "} ${t.firstNameNoti + " "} n'a pas été supprimé`)

            setTimeout(()=>{window.location.reload();},3000); 
        }

                        }} 
                            >Suprimer</button></td>
                        
                        </tr>
                        </>))}

                        
                    </table>

                    </div>



                    <div className='contentUser2'>
                    <h1> Poivron Vert</h1>
                    <h3>TOTAL : {PoivronVert.length}</h3>
                    <table>
                        <tr>
                            <th>Nom</th>
                            <th>Prénom</th>
                             <th>Culture</th>
                            <th>Contacts</th>
                            <th>Adresse</th>
                            <th>Début</th>
                            <th>MoMo</th>
                            <th>Options</th>
                        </tr>

                        {PoivronVert.map((t)=>(<>
                        <tr key={t._id}>
                        
                            <td>{t.lastNameNoti}</td>
                            <td>{t.firstNameNoti}</td>
                            <td>{t.typeCultureNoti}</td>
                            <td>{t.phoneNumberNoti}</td>
                            <td>{t.buyerAdresseNoti}</td>
                            <td><Moment format='DD-MM-YYYY'>{t.createdAt}</Moment></td>
                               <td>{t.momoTransaction}</td>
                            <td> <button id='dashContentBtn'
                            onClick={async()=>{

        try{

            const res = await axios.delete('https://b-elanga-store.onrender.com/api/poivronVert/'+t._id);
           toast.success(`L'Abonné: ${t.lastNameNoti + " "} ${t.firstNameNoti + " "} a été supprimé`)

                setTimeout(()=>{window.location.reload();},3000);                   
 
        }catch(error){
            
            toast.error(` Echec: L'Abonné: ${t.lastNameNoti + " "} ${t.firstNameNoti + " "} n'a pas été supprimé`)

            setTimeout(()=>{window.location.reload();},3000); 
        }

                        }} 
                            >Suprimer</button></td>
                        
                        </tr>
                        </>))}

                        
                    </table>

                    </div>



                    <div className='contentUser2'>
                    <h1> Gingembre</h1>
                    <h3>TOTAL : {Gingembre.length}</h3>
                    <table>
                        <tr>
                            <th>Nom</th>
                            <th>Prénom</th>
                             <th>Culture</th>
                            <th>Contacts</th>
                            <th>Adresse</th>
                            <th>Début</th>
                            <th>MoMo</th>
                            <th>Options</th>
                        </tr>

                        {Gingembre.map((t)=>(<>
                        <tr key={t._id}>
                        
                            <td>{t.lastNameNoti}</td>
                            <td>{t.firstNameNoti}</td>
                            <td>{t.typeCultureNoti}</td>
                            <td>{t.phoneNumberNoti}</td>
                            <td>{t.buyerAdresseNoti}</td>
                            <td><Moment format='DD-MM-YYYY'>{t.createdAt}</Moment></td>
                               <td>{t.momoTransaction}</td>
                            <td> <button id='dashContentBtn'
                            onClick={async()=>{

        try{

            const res = await axios.delete('https://b-elanga-store.onrender.com/api/gingembre/'+t._id);
            toast.success(`L'Abonné: ${t.lastNameNoti + " "} ${t.firstNameNoti + " "} a été supprimé`)

                setTimeout(()=>{window.location.reload();},3000);                   
 
        }catch(error){
            
            toast.error(` Echec: L'Abonné: ${t.lastNameNoti + " "} ${t.firstNameNoti + " "} n'a pas été supprimé`)

            setTimeout(()=>{window.location.reload();},3000); 
        }

                        }} 
                            >Suprimer</button></td>
                        
                        </tr>
                        </>))}

                        
                    </table>

                    </div>



                    <div className='contentUser2'>
                    <h1> Pomme de Terre</h1>
                    <h3>TOTAL : {PommedeTerre.length}</h3>
                    <table>
                        <tr>
                            <th>Nom</th>
                            <th>Prénom</th>
                             <th>Culture</th>
                            <th>Contacts</th>
                            <th>Adresse</th>
                            <th>Début</th>
                            <th>MoMo</th>
                            <th>Options</th>
                        </tr>

                        {PommedeTerre.map((t)=>(<>
                        <tr key={t._id}>
                        
                            <td>{t.lastNameNoti}</td>
                            <td>{t.firstNameNoti}</td>
                            <td>{t.typeCultureNoti}</td>
                            <td>{t.phoneNumberNoti}</td>
                            <td>{t.buyerAdresseNoti}</td>
                            <td><Moment format='DD-MM-YYYY'>{t.createdAt}</Moment></td>
                               <td>{t.momoTransaction}</td>
                            <td> <button id='dashContentBtn'
                            onClick={async()=>{

        try{

            const res = await axios.delete('https://b-elanga-store.onrender.com/api/pommedeTerre/'+t._id);
           toast.success(`L'Abonné: ${t.lastNameNoti + " "} ${t.firstNameNoti + " "} a été supprimé`)

                setTimeout(()=>{window.location.reload();},3000);                   
 
        }catch(error){
            
            toast.error(` Echec: L'Abonné: ${t.lastNameNoti + " "} ${t.firstNameNoti + " "} n'a pas été supprimé`)

            setTimeout(()=>{window.location.reload();},3000); 
        }

    
                        }} 
                            >Suprimer</button></td>
                        
                        </tr>
                        </>))}

                        
                    </table>

                    </div>



                    <div className='contentUser2'>
                    <h1> Patate Douce</h1>
                    <h3>TOTAL : {PatateDouce.length}</h3>
                    <table>
                        <tr>
                            <th>Nom</th>
                            <th>Prénom</th>
                             <th>Culture</th>
                            <th>Contacts</th>
                            <th>Adresse</th>
                            <th>Début</th>
                            <th>MoMo</th>
                            <th>Options</th>
                        </tr>

                        {PatateDouce.map((t)=>(<>
                        <tr key={t._id}>
                        
                            <td>{t.lastNameNoti}</td>
                            <td>{t.firstNameNoti}</td>
                            <td>{t.typeCultureNoti}</td>
                            <td>{t.phoneNumberNoti}</td>
                            <td>{t.buyerAdresseNoti}</td>
                            <td><Moment format='DD-MM-YYYY'>{t.createdAt}</Moment></td>
                               <td>{t.momoTransaction}</td>
                            <td> <button id='dashContentBtn'
                            onClick={async()=>{

        try{

            const res = await axios.delete('https://b-elanga-store.onrender.com/api/patateDouce/'+t._id);
           toast.success(`L'Abonné: ${t.lastNameNoti + " "} ${t.firstNameNoti + " "} a été supprimé`)

                setTimeout(()=>{window.location.reload();},3000);                   
 
        }catch(error){
            
            toast.error(` Echec: L'Abonné: ${t.lastNameNoti + " "} ${t.firstNameNoti + " "} n'a pas été supprimé`)

            setTimeout(()=>{window.location.reload();},3000); 
        }

                        }} 
                            >Suprimer</button></td>
                        
                        </tr>
                        </>))}

                        
                    </table>

                    </div>



                    <div className='contentUser2'>
                    <h1> Maïs</h1>
                    <h3>TOTAL : {Maïs.length}</h3>
                    <table>
                        <tr>
                            <th>Nom</th>
                            <th>Prénom</th>
                             <th>Culture</th>
                            <th>Contacts</th>
                            <th>Adresse</th>
                            <th>Début</th>
                            <th>MoMo</th>
                            <th>Options</th>
                        </tr>

                        {Maïs.map((t)=>(<>
                        <tr key={t._id}>
                        
                            <td>{t.lastNameNoti}</td>
                            <td>{t.firstNameNoti}</td>
                            <td>{t.typeCultureNoti}</td>
                            <td>{t.phoneNumberNoti}</td>
                            <td>{t.buyerAdresseNoti}</td>
                            <td><Moment format='DD-MM-YYYY'>{t.createdAt}</Moment></td>
                               <td>{t.momoTransaction}</td>
                            <td> <button id='dashContentBtn'
                            onClick={async()=>{

        try{

            const res = await axios.delete('https://b-elanga-store.onrender.com/api/mais/'+t._id);
           toast.success(`L'Abonné: ${t.lastNameNoti + " "} ${t.firstNameNoti + " "} a été supprimé`)

                setTimeout(()=>{window.location.reload();},3000);                   
 
        }catch(error){
            
            toast.error(` Echec: L'Abonné: ${t.lastNameNoti + " "} ${t.firstNameNoti + " "} n'a pas été supprimé`)

            setTimeout(()=>{window.location.reload();},3000); 
        }

                        }} 
                            >Suprimer</button></td>
                        
                        </tr>
                        </>))}

                        
                    </table>

                    </div>



                    <div className='contentUser2'>
                    <h1> Pois d'Angole</h1>
                    <h3>TOTAL : {PoisdAngole.length}</h3>
                    <table>
                        <tr>
                            <th>Nom</th>
                            <th>Prénom</th>
                             <th>Culture</th>
                            <th>Contacts</th>
                            <th>Adresse</th>
                            <th>Début</th>
                            <th>MoMo</th>
                            
                            <th>Options</th>
                        </tr>

                        {PoisdAngole.map((t)=>(<>
                        <tr key={t._id}>
                        
                            <td>{t.lastNameNoti}</td>
                            <td>{t.firstNameNoti}</td>
                            <td>{t.typeCultureNoti}</td>
                            <td>{t.phoneNumberNoti}</td>
                            <td>{t.buyerAdresseNoti}</td>
                            <td><Moment format='DD-MM-YYYY'>{t.createdAt}</Moment></td>
                               <td>{t.momoTransaction}</td>
                            <td> <button id='dashContentBtn'
                            onClick={async()=>{

        try{

            const res = await axios.delete('https://b-elanga-store.onrender.com/api/poisdAngole/'+t._id);
            toast.success(`L'Abonné: ${t.lastNameNoti + " "} ${t.firstNameNoti + " "} a été supprimé`)

                setTimeout(()=>{window.location.reload();},3000);                   
 
        }catch(error){
            
            toast.error(` Echec: L'Abonné: ${t.lastNameNoti + " "} ${t.firstNameNoti + " "} n'a pas été supprimé`)

            setTimeout(()=>{window.location.reload();},3000); 
        }

                        }} 
                            >Suprimer</button></td>
                        
                        </tr>
                        </>))}

                        
                    </table>

                    </div>



                    <div className='contentUser2'>
                    <h1> Carotte</h1>
                    <h3>TOTAL : {Carotte.length}</h3>
                    <table>
                        <tr>
                            <th>Nom</th>
                            <th>Prénom</th>
                             <th>Culture</th>
                            <th>Contacts</th>
                            <th>Adresse</th>
                            <th>Début</th>
                            <th>MoMo</th>
                            
                            <th>Options</th>
                        </tr>

                        {Carotte.map((t)=>(<>
                        <tr key={t._id}>
                        
                            <td>{t.lastNameNoti}</td>
                            <td>{t.firstNameNoti}</td>
                            <td>{t.typeCultureNoti}</td>
                            <td>{t.phoneNumberNoti}</td>
                            <td>{t.buyerAdresseNoti}</td>
                            <td><Moment format='DD-MM-YYYY'>{t.createdAt}</Moment></td>
                               <td>{t.momoTransaction}</td>
                            <td> <button id='dashContentBtn'
                            onClick={async()=>{

        try{

            const res = await axios.delete('https://b-elanga-store.onrender.com/api/carotte/'+t._id);
           toast.success(`L'Abonné: ${t.lastNameNoti + " "} ${t.firstNameNoti + " "} a été supprimé`)

                setTimeout(()=>{window.location.reload();},3000);                   
 
        }catch(error){
            
            toast.error(` Echec: L'Abonné: ${t.lastNameNoti + " "} ${t.firstNameNoti + " "} n'a pas été supprimé`)

            setTimeout(()=>{window.location.reload();},3000); 
        }

                        }} 
                            >Suprimer</button></td>
                        
                        </tr>
                        </>))}

                        
                    </table>

                    </div>


                    <div className='contentUser2'>
                    <h1> Sorgho</h1>
                    <h3>TOTAL : {Sorgho.length}</h3>
                    <table>
                        <tr>
                            <th>Nom</th>
                            <th>Prénom</th>
                             <th>Culture</th>
                            <th>Contacts</th>
                            <th>Adresse</th>
                            <th>Début</th>
                            <th>MoMo</th>
                            
                            <th>Options</th>
                        </tr>

                        {Sorgho.map((t)=>(<>
                        <tr key={t._id}>
                        
                            <td>{t.lastNameNoti}</td>
                            <td>{t.firstNameNoti}</td>
                            <td>{t.typeCultureNoti}</td>
                            <td>{t.phoneNumberNoti}</td>
                            <td>{t.buyerAdresseNoti}</td>
                            <td><Moment format='DD-MM-YYYY'>{t.createdAt}</Moment></td>
                               <td>{t.momoTransaction}</td>
                            <td> <button id='dashContentBtn'
                            onClick={async()=>{

        try{

            const res = await axios.delete('https://b-elanga-store.onrender.com/api/sorgho/'+t._id);
           toast.success(`L'Abonné: ${t.lastNameNoti + " "} ${t.firstNameNoti + " "} a été supprimé`)

                setTimeout(()=>{window.location.reload();},3000);                   
 
        }catch(error){
            
            toast.error(` Echec: L'Abonné: ${t.lastNameNoti + " "} ${t.firstNameNoti + " "} n'a pas été supprimé`)

            setTimeout(()=>{window.location.reload();},3000); 
        }

                        }} 
                            >Suprimer</button></td>
                        
                        </tr>
                        </>))}

                        
                    </table>

                    </div>


                    <div className='contentUser2'>
                    <h1> Soja</h1>
                    <h3>TOTAL : {Soja.length}</h3>
                    <table>
                        <tr>
                            <th>Nom</th>
                            <th>Prénom</th>
                             <th>Culture</th>
                            <th>Contacts</th>
                            <th>Adresse</th>
                            <th>Début</th>
                            <th>MoMo</th>
                            
                            <th>Options</th>
                        </tr>

                        {Soja.map((t)=>(<>
                        <tr key={t._id}>
                        
                            <td>{t.lastNameNoti}</td>
                            <td>{t.firstNameNoti}</td>
                            <td>{t.typeCultureNoti}</td>
                            <td>{t.phoneNumberNoti}</td>
                            <td>{t.buyerAdresseNoti}</td>
                            <td><Moment format='DD-MM-YYYY'>{t.createdAt}</Moment></td>
                               <td>{t.momoTransaction}</td>
                            <td> <button id='dashContentBtn'
                            onClick={async()=>{

        try{

            const res = await axios.delete('https://b-elanga-store.onrender.com/api/soja/'+t._id);
           toast.success(`L'Abonné: ${t.lastNameNoti + " "} ${t.firstNameNoti + " "} a été supprimé`)

                setTimeout(()=>{window.location.reload();},3000);                   
 
        }catch(error){
            
            toast.error(` Echec: L'Abonné: ${t.lastNameNoti + " "} ${t.firstNameNoti + " "} n'a pas été supprimé`)

            setTimeout(()=>{window.location.reload();},3000); 
        }

                        }} 
                            >Suprimer</button></td>
                        
                        </tr>
                        </>))}

                        
                    </table>

                    </div>


                </div>
            )}
            
          </div>

          <ToastContainer/>
           
        </div>
    </>)
}




export default Dashboard
