import { BrowserRouter, Route, Routes } from 'react-router-dom'
import About from './components/About';
import Contacts from './components/Contacts';
import Dashboard from './components/Dashboard';
import Home from './components/Home';
import Login from './components/Login';
import Publish from './components/Publish';
import Register from './components/Register';
import SinglePost from './components/SinglePost';
import Termes from './components/Termes';
import { Context } from './context/Context';
import { useContext, useEffect, useState } from 'react';
import { Helmet } from 'react-helmet'
import ReactGA from "react-ga4";
import axios from "axios"
import Loading from './components/Loading';
import LoadingPage from './components/LoadingPage';
import Agriculteurs from './components/Agriculteurs';
import Acheteurs from './components/Acheteurs';
import Profile from './components/Profile';
import Reabonnement from './components/Reabonnement';


function App() {
  const { user } = useContext(Context);


const [isFetching, setIsFetching] = useState(true);


const [monitor, setMonitor] = useState(false);

const moni = ()=>{
if(user){

    if(user.userStatus ==="admin"){
        setMonitor(true);
    }
}
}

 useEffect(() => {
    moni();
    
  }, []);


useEffect(() =>{
        
        const fectchPost = async ()=>{
            const res = await axios.get('https://b-elanga-store.onrender.com/api/post/');
            
            res.data &&(setTimeout(()=>{setIsFetching(false)},0));
            
            
        }

        fectchPost();
       
        
    },[])

 useEffect(() => {
    moni();
    
  }, []);

  useEffect(()=>{
        ReactGA.initialize("G-TRZCDYNL7B");
        ReactGA.send("pageview");
    },[]);

  //G-TRZCDYNL7B

  return (
    <div>
    <Helmet>
        <link rel="canonical" href ="https://bilangastore.com/"/>

        <title>Bilanga Store</title>
        <meta name="description" content="BILANGA STORE est une Startup Congolaise qui met en relation les Agriculteurs du Congo et les différents Revendeurs, pour la Vente et l'Achat des produits Agricoles. 

Bilanga Store se veut accessible à tous Agriculteurs et Revendeurs. Les Agriculteurs peuvent s'abonner a nos services, en passant leurs Annonces par appel en Langue Locale ou officielle, pour la vente de leurs produits Agricoles. Les Revendeurs peuvent aussi s'abonner au service de Notification par SMS, leur permettant de recevoir en temps réel, toutes Informations y compris les contacts des différents Agriculteurs quand une Annonce concernant un produit Agricole est postée à l'instant t." />
        
      </Helmet>

      {/* <LoadingPage/> */}

{/* <Route  exact path='/register' element={user ? <Register/>:<Home/>}/>  */}


    <BrowserRouter>
      <Routes>
         <Route  exact path='/' element={isFetching ?<LoadingPage/>:<Home/>}/> 

          <Route  exact path='/post/:id' element={<SinglePost/>}/> 
          
          <Route  exact path='/publier' element={user ?<Publish/> :<Login/>}/> 
           
         
          <Route  exact path='/login' element={user ? <Home/> :<Login/>}/> 

          <Route  exact path='/register' element={monitor ? <Register/>:<Home/>}/>

          <Route  exact path='/about' element={<About/>}/> 
          <Route  exact path='/contacts' element={<Contacts/>}/> 
          <Route  exact path='/termes' element={<Termes/>}/> 

          <Route  exact path='/agriculteurs' element={<Agriculteurs/>}/> 
          
          <Route  exact path='/acheteurs' element={<Acheteurs/>}/> 

          <Route  exact path='/profile/:id' element={<Profile/>}/> 
          
          <Route  exact path='/reabonnement/:id' element={<Reabonnement/>}/> 
          

          <Route  exact path='/admin' element={ monitor ? <Dashboard/> : <Home/>}/> 
            
          
          
       </Routes>
    </BrowserRouter>
      
    </div>
  );
}

export default App;
