import React, { useEffect, useState } from 'react'
import { FaFacebookSquare, FaLinkedin, FaWhatsappSquare} from 'react-icons/fa'
import { Link } from 'react-router-dom'
import axios from "axios"


const Footer = () => {

    return (
        <div className='footerO'>
            <div className="footerIn">
                <div className="productO">
                <div className='typeFooter'> 
                <h1>Types de Culture</h1>
                </div>
                <div className="product">

                <div className="productFirst">
                    <ul>
                        <li><Link to={"/?cate=Tubercules de Manioc"}><h3>Tubercules de Manioc</h3> </Link>  </li>

                        <li><Link to={"/?cate=Tomates"}><h3>Tomates</h3> </Link>  </li>

                        <li><Link to={"/?cate=Igname"}><h3> Igname</h3> </Link>  </li>

                        <li><Link to={"/?cate=Oignons"}><h3> Oignons</h3> </Link>  </li>

                        <li><Link to={"/?cate=Piment"}><h3> Piment</h3> </Link>  </li>

                        <li><Link to={"/?cate=Concombre"}><h3> Concombre</h3> </Link>  </li>

                        <li><Link to={"/?cate=Gingembre"}><h3> Gingembre</h3> </Link>  </li>

                        <li><Link to={"/?cate=Patate Douce"}><h3> Patate Douce</h3> </Link>  </li>

                        <li><Link to={"/?cate=Carotte"}><h3>Carotte</h3> </Link>  </li>

                        <li><Link to={"/?cate=Soja"}><h3>Soja</h3> </Link>  </li>
 
                   
                    </ul>
                </div>
                <div className="productSecond">
                    <ul>
                        <li><Link to={"/?cate=Banane Plantain"}><h3> Banane Plantain</h3> </Link>  </li>
                        
                        <li><Link to={"/?cate=Haricot"}><h3>Haricot</h3> </Link>  </li>

                        <li><Link to={"/?cate=Arachide"}><h3>Arachide</h3> </Link>  </li>

                        <li><Link to={"/?cate=Ciboules"}><h3>Ciboules</h3> </Link>  </li>
                        <li><Link to={"/?cate=Aubergine"}><h3>Aubergine</h3> </Link>  </li>
                        <li><Link to={"/?cate=Poivron Vert"}><h3>Poivron Vert</h3> </Link>  </li>

                        <li><Link to={"/?cate=Pomme de Terre"}><h3>Pomme de Terre</h3> </Link>  </li>

                        <li><Link to={"/?cate=Maïs"}><h3>Maïs</h3> </Link>  </li>

                        <li><Link to={"/?cate=Pois d'Angole"}><h3> Pois d'Angole</h3> </Link>  </li>

                         <li><Link to={"/?cate=Sorgho"}><h3>Sorgho</h3> </Link>  </li>


                    </ul>
                </div>

                </div>
                </div>
                <div className="infos">
                    <div className="infosHead">
                        <h1>Informations</h1>
                    </div>

                    <div className="infosSociete">
                        <ul>
                            <li> <Link to="/about"> <h3>
                            A propos de BILANGA <span id='store'> STORE</span>
                            </h3> </Link></li>
                            <li> <Link to="/termes"> <h3> Termes & Conditions</h3> </Link> </li>
                            <li> <Link to="/contacts"><h3>
                            Contacts
                            </h3></Link> </li>
                            
                        </ul>
                    </div>

                    <div className="socialO">
                        <div className="socialHead">
                            <h2> Retrouvez nous sur :</h2>
                        </div>

                        <div className="socialIcon">
                            <h4><a href="https://www.facebook.com/profile.php?id=100089238043095&mibextid=ZbWKwL" target="_blank">                    
               <FaFacebookSquare size="3.5em" id='footerFa' 
               /> 
               </a></h4>
                        </div>
                    </div>
                </div>
            </div>

            <div className="credit">
                <h4> &copy;2022 <span id="creditC"><Link to='/'>BILANGA STORE</Link> </span>  Tous droits réservés </h4> 
                <br/>

                {/* <h4>  <span id="creditC" ><a href="https://iptechgroupe.com" target="_blank">
                
                    Iptechgroupe
                
            </a></span> 

            </h4> */}
            
            </div>
        </div>
    )
}

export default Footer
