import React, { useContext, useEffect, useState } from 'react'
import Footer from './Footer'
import Header from './Header'
import SideBar from './SideBar'
import axios from "axios"
import Moment from 'react-moment'
import { Link, useLocation } from 'react-router-dom'
import { Context } from '../context/Context'
import { jsPDF } from "jspdf";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { FaBars, FaTimes,  } from 'react-icons/fa'



import t1 from "../images/t1.jpg"
import t2 from "../images/t2.jpg"
import t3 from "../images/t3.jpg"
import t4 from "../images/t4.jpg"
import t5 from "../images/t5.jpg"

import b1 from "../images/b1.jpg"
import b2 from "../images/b2.jpg"
import b3 from "../images/b3.jpg"
import b4 from "../images/b4.jpg"
import b5 from "../images/b5.jpg"

import to1 from "../images/to1.jpg"
import to2 from "../images/to2.jpg"
import to3 from "../images/to3.jpg"
import to4 from "../images/to4.jpg"
import to5 from "../images/to5.jpg"

import h1 from "../images/h1.jpg"
import h2 from "../images/h2.jpg"
import h3 from "../images/h3.jpg"
import h4 from "../images/h4.jpg"


// import t1 from "../images/t1.jpg"


import a1 from "../images/a1.jpg"
import a2 from "../images/a2.jpg"
import a3 from "../images/a3.jpg"
import a4 from "../images/a4.jpg"
import a5 from "../images/a5.jpg"

import o1 from "../images/o1.jpg"
import o2 from "../images/o2.jpg"
import o3 from "../images/o3.jpg"
import o4 from "../images/o4.jpg"
import o5 from "../images/o5.jpg"

import ci1 from "../images/ci1.jpg"
import ci2 from "../images/ci2.jpg"
import ci3 from "../images/ci3.jpg"
import ci4 from "../images/ci4.jpg"
import ci5 from "../images/ci5.jpg"

import pi1 from "../images/pi1.jpg"
import pi2 from "../images/pi2.jpg"
import pi3 from "../images/pi3.jpg"
import pi4 from "../images/pi4.jpg"
import pi5 from "../images/pi5.jpg"

import au1 from "../images/au1.jpg"
import au2 from "../images/au2.jpg"
import au3 from "../images/au3.jpg"
import au4 from "../images/au4.jpg"
import au5 from "../images/au5.jpg"

import co1 from "../images/co1.jpg"
import co2 from "../images/co2.jpg"
import co3 from "../images/co3.jpg"
import co4 from "../images/co4.jpg"


import po1 from "../images/po1.jpg"
import po2 from "../images/po2.jpg"
import po3 from "../images/po3.jpg"
import po4 from "../images/po4.jpg"
import po5 from "../images/po5.jpg"

import g1 from "../images/g1.jpg"
import g2 from "../images/g2.jpg"
import g3 from "../images/g3.jpg"
import g4 from "../images/g4.jpg"
import g5 from "../images/g5.jpg"

import pom1 from "../images/pom1.jpg"
import pom2 from "../images/pom2.jpg"
import pom3 from "../images/pom3.jpg"
import pom4 from "../images/pom4.jpg"
import pom5 from "../images/pom5.jpg"

import p1 from "../images/p1.jpg"
import p2 from "../images/p2.jpg"

import m1 from "../images/m1.jpg"
import m2 from "../images/m2.jpg"
import m3 from "../images/m3.jpg"
import m4 from "../images/m4.jpg"
import m5 from "../images/m5.jpg"

// import t1 from "../images/t1.jpg"


import c1 from "../images/c1.jpg"
import c2 from "../images/c2.jpg"
import c3 from "../images/c3.jpg"
import c4 from "../images/c4.jpg"
import c5 from "../images/c5.jpg"

import s1 from "../images/s1.jpg"
import s2 from "../images/s2.jpg"
import Abonnement from './Abonnement'
import LoadingTwo from './LoadingTwo'


// import t1 from "../images/t1.jpg"


const SinglePost = () => {

    // header

    const { dispatch,admin } = useContext(Context);

const handleLogout = ()=>{
    dispatch({type:"LOGOUT"})

}


    const [menu,setMenu] = useState(false);
  const [close,setClose] = useState(true);

   const [fetch,setFetch] = useState(false);
  

  const [cat, setCat] = useState([]);

  useEffect(() => {
    moni();
    
  }, []);


// const PF ="http://localhost:5000/images/"

 const { user } = useContext(Context);
 

const [monitor, setMonitor] = useState(false);
const [userRole, setUserRole] = useState(false);


const moni = ()=>{
if(user){

    if(user.userStatus ==="admin"){
        setMonitor(true);
    }
}
}


//post
const location = useLocation();
const path =location.pathname.split("/")[2];
const [post, setPost] = useState({});


useEffect(() =>{
        window.scrollTo(0,0);


        //Getting post
         const getPostBId = async ()=>{
            const res = await axios.get('https://b-elanga-store.onrender.com/api/post/'+ path);
            setPost(res.data);
            setFetch(true);
            
         };
         getPostBId();
        


    },[path])

    // Delete Post Method 

const handleDeletePost = async()=>{
        try{

            const res = await axios.delete('https://b-elanga-store.onrender.com/api/post/'+path,{
                data:{username:user.username}
            });

           toast.success(`Le post a été supprimé`)
 
                setTimeout(()=>{window.location.replace("/");},3000);                   
 
        }catch(error){
            
            toast.error(` Echec: Le post n'a pas été supprimé`)

            setTimeout(()=>{window.location.reload();},3000); 
        }
 
        }
    
    
    // Delete Post Method BY ADMIN

const handleDeletePostAdmin = async()=>{
        try{

            const res = await axios.delete('https://b-elanga-store.onrender.com/api/post/admin/'+path);

            toast.success(`Le post a été supprimé`)
 
                setTimeout(()=>{window.location.replace("/");},3000);                   
 
        }catch(error){
            
            toast.error(` Echec: Le post n'a pas été supprimé`)

            setTimeout(()=>{window.location.reload();},3000); 
        }
 
    
}


    useEffect(() => {
        moni();
    
  }, []);


  // Photo Random:

const TuberculesdeManioc =[
       t1,
       t2,
       t3,
       t4,
       t5
       
    ]
const BananePlantain =[
        b1,
       b2,
       b3,
       b4,
       b5
    ]

const Tomates =[
      to1,
       to2,
       to3,
       to4,
       to5
    ]
const Haricot =[
       h1,
       h2,
       h3,
       h4
       
      
    ]
const Igname =[
        "t1.jpg",
        ]

const Arachide =[
       a1,
       a2,
       a3,
       a4,
       a5 
     ]


const Oignons =[
        o1,
       o2,
       o3,
       o4,
       o5
    ]
const Ciboules =[
       ci1,
       ci2,
       ci3,
       ci4,
       ci5   ]

const Piment =[
       pi1,
       pi2,
       pi3,
       pi4,
       pi5   ]

const Aubergine =[
       au1,
       au2,
       au3,
       au4,
       au5
    ]

const Concombre =[
        co1,
       co2,
       co3,
       co4 
    ]

const PoivronVert =[
       po1,
       po2,
       po3,
       po4,
       po5
    ]

const Gingembre =[
       g1,
       g2,
       g3,
       g4,
       g5   ]


const PommedeTerre =[
        pom1,
       pom2,
       pom3,
       pom4,
       pom5   ]


const PatateDouce =[
        p1,
       p2,
       
          ]


const Maïs =[
        m1,
       m2,
       m3,
       m4,
       m5    ]


const PoisdAngole =[
         t1,
       
           ]


const Carotte =[
        c1,
       c2,
       c3,
       c4,
       c5   ]


const Soja =[
        s1,
       s2,
       
           ]


const Sorgho =[
        t1,
       
           ]



    const RTuberculesdeManioc = Math.floor(Math.random()*TuberculesdeManioc.length);

    const RBananePlantain = Math.floor(Math.random()*BananePlantain.length);

    const RTomates = Math.floor(Math.random()*Tomates.length);

    
    const RHaricot = Math.floor(Math.random()*Haricot.length);

    
    const RIgname = Math.floor(Math.random()*Igname.length);

    
    const RArachide = Math.floor(Math.random()*Arachide.length);

    
    const ROignons = Math.floor(Math.random()*Oignons.length);

    
    const RCiboules = Math.floor(Math.random()*Ciboules.length);

    
    const RPiment = Math.floor(Math.random()*Piment.length);

    
    const RAubergine = Math.floor(Math.random()*Aubergine.length);

    
    const RConcombre = Math.floor(Math.random()*Concombre.length);

    
    const RPoivronVert = Math.floor(Math.random()*PoivronVert.length);

    
    const RGingembre = Math.floor(Math.random()*Gingembre.length);

    
    const RPommedeTerre = Math.floor(Math.random()*PommedeTerre.length);

    
    const RPatateDouce = Math.floor(Math.random()*PatateDouce.length);

    
    const RMaïs = Math.floor(Math.random()*Maïs.length);

    
    const RPoisdAngole = Math.floor(Math.random()*PoisdAngole.length);

    
    const RCarotte = Math.floor(Math.random()*Carotte.length);

    
    const RSoja = Math.floor(Math.random()*Soja.length);

    
    const RSorgho = Math.floor(Math.random()*Sorgho.length);

  


        
    return (
        <div>
        <div className='navBarO'>

        <div className="navBar">

        <div className="logo">
        <div className="logoVPhoto2">
            <Link to="/" ></Link>
        </div>
        <div className="logoN">
        <Link to="/">
            <h2>BILANGA <span> STORE</span></h2></Link>
        </div>

        </div>
        <div className="menu">

            <div className="userName">
            {user &&(<h3 id='userString'>{user.userFirstName}</h3>)}
              
             {user&&(<Link to='/publier'> <h3 id="userString"> Publier </h3> </Link>)}

               {monitor &&(<Link to='/register' ><h3 id='userString'>S'Enregitrer</h3></Link>)}
                    
                {user &&(<Link to={`/?user=${user.username}`} ><h3 id='userString'>Mes Annonces</h3></Link>)}
             
              {
                user ? (<Link to="/" ><h3 id='userString' onClick={handleLogout}>Se Déconnecter</h3></Link>):(<Link to='/login' ><h3 id='userString'>Se Connecter</h3></Link>)
              }
              
            </div>
            <div>

            {close && (
            <FaBars id='menuBar' size="2em"
                onClick={
                    ()=>{
                        setMenu(!menu)
                        setClose(!close)
                    }
                }
            
          />
          )}

          {!close &&(
          <FaTimes id='menuBarClose' size="2em"
            onClick={() => {
            setMenu(!menu)
            setClose(!close)
                     }
           }
           
           />
         
            )}

            </div>

            

        </div>

        </div>

       
       
        {menu && (

            <div className='menuOpen'>
       
                <ul>

                 <li><Link to='/'> <h2 id="headerA"> Accueil </h2> </Link></li> 

                {user && (<>
                    <li><Link to='/publier'> <h2 id="headerLogin"> Publier </h2> </Link></li>

                <li><Link to={`/?user=${user.username}`}> <h2 id="headerLogin"> Mes Annonces </h2> </Link></li>
                
        </>)}

        {user &&(
            <li><Link to='/admin'> <h2 id="headerLogin"> Admin </h2> </Link></li>
        )}

                    <li><Link to='/about'> <h2 id="headerA"> A Propos de Bilanga Store </h2> </Link></li> 

                    {user && (<li onClick={handleLogout} ><Link to='/'  > <h2 id="headerLogin"> Se Déconnecter </h2> </Link></li>)}
                
                {!user &&(
                    <li><Link to='/login'> <h2 id="headerLogin"> Se Connecter </h2> </Link></li>
                )}

                   
                    
                </ul>

                 </div>

                )}
        

            
        </div>



{!fetch ?(
    <LoadingTwo/>
):(
    <>

   <div className="singlePostO">
            <div className="singlePhoto">

           {post.typeCulture ==="Tubercules de Manioc" ?(<img src={TuberculesdeManioc[RTuberculesdeManioc]} className='imageSingle' alt="photo" />):
          
          ( post.typeCulture ==="Banane Plantain" ? (<img src={BananePlantain[RBananePlantain]} className='imageSingle' alt="photo" />):
          
           (post.typeCulture ==="Tomates" ? (<img src={Tomates[RTomates]} className='imageSingle' alt="photo" />):
           
           (post.typeCulture ==="Haricot" ?(<img src={Haricot[RHaricot]} className='imageSingle' alt="photo" />):
           
           (post.typeCulture ==="Igname" ?(<img src={Igname[RIgname]} className='imageSingle' alt="photo" />):
           
           (post.typeCulture ==="Arachide" ?(<img src={Arachide[RArachide]} className='imageSingle' alt="photo" />):

           (post.typeCulture ==="Oignons" ?(<img src={Oignons[ROignons]} className='imageSingle' alt="photo" />):

           (post.typeCulture ==="Ciboules" ?(<img src={Ciboules[RCiboules]} className='imageSingle' alt="photo" />):

           (post.typeCulture ==="Piment" ?(<img src={Piment[RPiment]} className='imageSingle' alt="photo" />):

           (post.typeCulture ==="Aubergine" ?(<img src={Aubergine[RAubergine]} className='imageSingle' alt="photo" />):

           (post.typeCulture ==="Concombre" ?(<img src={Concombre[RConcombre]} className='imageSingle' alt="photo" />):

           (
            post.typeCulture ==="Poivron Vert" ?(<img src={PoivronVert[RPoivronVert]} className='imageSingle' alt="photo" />):

            (post.typeCulture ==="Gingembre" ?(<img src={Gingembre[RGingembre]} className='imageSingle' alt="photo" />):

            (post.typeCulture ==="Pomme de Terre" ?(<img src={PommedeTerre[RPommedeTerre]} className='imageSingle' alt="photo" />):

            (post.typeCulture ==="Patate Douce" ?(<img src={PatateDouce[RPatateDouce]} className='imageSingle' alt="photo" />):

            (post.typeCulture ==="Maïs" ?(<img src={Maïs[RMaïs]} className='imageSingle' alt="photo" />):

            (post.typeCulture ==="Pois d'Angole" ?(<img src={PoisdAngole[RPoisdAngole]} className='imageSingle' alt="photo" />):

            (post.typeCulture ==="Carotte" ?(<img src={Carotte[RCarotte]} className='imageSingle' alt="photo" />):

            (post.typeCulture ==="Soja" ?(<img src={Soja[RSoja]} className='imageSingle' alt="photo" />):

            (post.typeCulture ==="Sorgho" ?(<img src={Sorgho[RSorgho]} className='imageSingle' alt="photo" />):

            (null)))))))))
           ))))))))))


            )}
          



            </div>

            <div className="singlePostIn">
                <div className="infosSingle">
<div className='infosFourni'>

<div className='infosFixe'>
<ul>
    <li><h2>Nom:</h2></li>
    <li><h2>Prénom:</h2></li>
    <li><h2>Culture:</h2></li>
    <li><h2>Quantité:</h2></li>
    <li><h2>Prix:</h2></li>

    {/* <li><h2>Ville:</h2></li> */}
    
</ul>

</div>
<div className='infosReal'>
<ul>
    <li><h2>{post.lastName}</h2></li>
    <li><h2>{post.firstName}</h2></li>
    <li><h2>{post.typeCulture}</h2></li>
    <li><h2>{post.quantityCulture + " "} kg</h2></li>
    <li><h2>{post.priceCulture +" "}FCFA/ Kg</h2></li>


    {/* <li><h2>{post.farmingAdresse}</h2></li> */}
    
</ul>
</div>

</div>

            
            <div className='btnSupSing'>
            <div>
                <h3 id='protext1'>Souscrivez aux Notifications SMS </h3>
                <h3>Afin de recevoir toutes les<span id='protext2'> informations</span> de tous les Fournisseurs de : {post.typeCulture} </h3>
                
                <h3>Veuilllez vous abonnez en tant qu'<span id='protext2'>Acheteur </span> des Produits Agricoles.</h3>


                <h3>Avec un Abonnement de <span id='protext3'>3.000 <span id='protext5'>FCFA</span> /ans</span> au lieu de <span id='protext4'> 5.000<span id='protext6'> FCFA </span>/ans</span></h3>
            </div>

            
            {monitor &&(<div>
            <button key={post._id} id='dashContentBtn' onClick={handleDeletePostAdmin} >Supprimer</button>
            </div>)}

                </div>

                <Abonnement/>

                </div>

                <>

                <SideBar/>
                </>

            </div>


            
        </div>
        </>
)}
        <ToastContainer/>
        <Footer/>
            
        </div>   
    )
}

export default SinglePost
