import React, { useContext, useEffect, useState } from 'react'
import Footer from './Footer'
import Header from './Header'

import { FaCheckCircle } from 'react-icons/fa';
import axios from "axios"
import { Context } from '../context/Context'
import { ToastContainer, toast } from 'react-toastify';
import { FaEye, FaEyeSlash } from 'react-icons/fa'
import { useLocation } from 'react-router-dom';
import mtn from "../images/mtnmomo.svg"

const Reabonnement = () => {
useEffect(() => {
     window.scrollTo(0,0);

     
    }, [])

    const { user } = useContext(Context)
    const location = useLocation();

    const path =location.pathname.split("/")[2];

const [submit,setSubmit]=useState(false);
const [validatedPay,setValidatedPay]=useState(false);


const userLastName = user.userLastName
const userFirstName = user.userFirstName
const username = user.username
const userCity = user.userCity
const userStatus = user.userStatus

const [password,setPassword] = useState("")



   

    const [momoSucess,setMomoSucess] = useState()
    const [momoFailed,setMomoFailed] = useState()

    
    

     const [partyId,setPartyId] = useState()

          
        const handleSubmit = async (e)=>{
            e.preventDefault();
            

            try{
            const res1 = await axios.post("https://b-elanga-store.onrender.com/api/brouillonUser/register/", {
                userLastName,userFirstName,userCity,username,userStatus,password

            });  
  
             if( res1.data){
            try{
                const res2 = await axios.post('https://b-elanga-store.onrender.com/api/reqtoPay',{partyId});

                setValidatedPay(true);
                const response = res2.data;

                 const momoTransaction = response.financialTransactionId;

                

                toast.success("Votre paiement est en cours...");

                if( response.status ==="SUCCESSFUL"){

                    setMomoSucess(true);

                    const reabonner = true;

                    try{
            const res = await axios.patch("https://b-elanga-store.onrender.com/api/users/" + path, {
                userId:user._id,
                userLastName,userFirstName,userCity,username,password,userStatus,momoTransaction,reabonner


            });



            if( res.data){
                toast.success("Succès: Votre Abonnement a été enregistré ! ");

                try{
           await axios.post('https://b-elanga-store.onrender.com/api/sms',{
                 to:`+242055489004`,
                body:`Mr/Mrs ${userLastName} ${userFirstName}, ELANGA STORE : vous notifie que Votre Réabonnement en tant qu'Agriculteur pour une année a bien été enregistré. Vous pouvez maintenant publier vos annonces.
                #ELANGA STORE SMS Notification
                `
            });
            
        }catch(error){
            
        }

                setTimeout(()=>{window.location.replace("/");},3000);  
                
               
            }
                

               }catch(error){
            
            toast.error(` Echec: Vous avez déja un compte, choissisiez un numéro unique`)


            setTimeout(()=>{window.location.reload();},3000); 
        }

         
        

                }


                if( response.status ==="FAILED"){

                    setMomoFailed(true);
                }


        }catch(error){
           toast.error(` Echec: Votre paiement n'a pas aboutie`)
        }

        
             }

               }catch(error){
            
            toast.error(` Echec: Vérifiez vos Informations`)

            // setTimeout(()=>{window.location.reload();},3000); 
        }

        }



     const [passwordType, setPasswordType] = useState("password");

const showPass = ()=>{

    if(passwordType==="password"){
        
        setPasswordType("text");
        return;
    }else{

        setPasswordType("password");
    } 

}





    return (
        <div>
            <Header/>
            <div className="publishO2">
            <form onSubmit={handleSubmit}
            autoComplete="off"
            >

        
            <div className='payO'>

        <div className='payIn'>
<div>
                <h3> Veuillez vous  Reabonner pour 1 an en payant la somme de <span id='protext3'>2.000 FCFA</span> </h3>
            </div>

        <div className='imgPay' ><img src={mtn} id='imgPay' alt="" srcset="" /></div>
        <div>
        <ul>

        <li><span id='asterix'>*</span>   
                     <input type={passwordType} name="password" 
                      
                     id="numberCl" placeholder='Mot de Pass sur Elanga Store' required
                        onChange={(e)=> setPassword(e.target.value)}

                        onInvalid={e=>e.target.setCustomValidity("Veuillez Entrer Votre Mot de Pass svp")}

               onInput={e=>e.target.setCustomValidity('')}
                    />
                    {passwordType ==="password" ? (<FaEyeSlash size="1.5em" id="eye3" onClick={showPass} />):(<FaEye id="eye3" size="1.5em" 
                        onClick={showPass}
                    />)}
                   </li>


        <li>
        <span id='asterix'>*</span>
                    <input type="number"  name="" id="numberCl" 
                    placeholder=' Votre Numéro ex:(06xxxxxxx)'
                    inputMode="decimal"
                   required
                  
                    min="1"
                    onChange={e=>{setPartyId(e.target.value)}}

                    onInvalid={e=>e.target.setCustomValidity('Veuillez remplir ce champ comme 06 XXX XX XX')}

                    onInput={e=>e.target.setCustomValidity('')}
                />
            </li>

            

            <li>
                 <button id='btn-pay'
            name='nameS' type="submit">
           Confimer 
            </button>   

            </li>
            </ul>

        </div>

        <div className='payOutput'>

             {
            momoFailed &&(
                 <h3>Echec : Votre paiement n'a pas aboutie</h3>
            )
        }

       

        {
            momoSucess &&(
                 <h3>Votre paiement a bien ete effectue</h3>
            )
        }
       

        </div>

        </div>
            
        </div>

        </form>

         </div>




        
            <ToastContainer/>
            <Footer/>
        </div>

    )
}

export default Reabonnement
