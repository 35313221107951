import React from 'react'
import { Link } from 'react-router-dom'

const Abonnement = () => {
    return (
        <div>
            <div className='promoSubscribe'>
    
    <div className="agri">
      <div className="tittlePromo"> <h1>Vous êtes Agriculteur ? </h1></div>
      <div className="descrPromo"> <h3> Abonnez-vous à nos services pour vendre vos Produits Agricoles à partir de vôtre téléphone en utilisant le code *550*0*0*1#</h3>
      
      <h3>Cliquez sur Abonnez-vous</h3></div>
      <div className="ButtonPromo"> <button id="ButtonPromo" type="button" > <Link to="/agriculteurs"> <h3> Abonnez-vous</h3> </Link></button></div>
    </div>

    <div className="ache">
      <div className="tittlePromo"> <h1>Vous êtes Acheteur ? </h1></div>
      <div className="descrPromo"> <h3> Abonnez-vous à nos services pour acheter vos Produits Agricoles à partir de vôtre téléphone en utilisant le code *554*0*0*2#</h3>
      
      <h3>Cliquez sur Abonnez-vous</h3></div>
      <div className="ButtonPromo"> <button id="ButtonPromo2" type="button" ><Link to="/acheteurs"> <h3> Abonnez-vous</h3> </Link></button></div>
    </div>
   
     
     </div>

        </div>
    )
}

export default Abonnement
