import React from 'react'
import Posts from './Posts'
import SideBar from './SideBar'
import {Carousel} from 'react-carousel-minimal'
import { Link } from 'react-router-dom'
import Abonnement from './Abonnement'

const MainPost = ({posts}) => {

    // carousel

    
const data = [
    {
      image: "manioc1.jpg",
      caption: "Tubercule de Manioc"
    },
    {
      image: "mais1.jpg",
      caption: "Mais"
    },
    
    {
      image: "arachide2.jpg",
      caption: "Arachides"
    },
    
    {
      image: "poivronsVert1.jpg",
      caption: "Poivron Vert"
    },
    {
      image: "oignon1.jpg",
      caption: "Oignons"
    },
    {
      image: "piment2.jpg",
      caption: "Piments"
    },
    {
      image: "concombres1.jpg",
      caption: "Concombres"
    },
    {
      image: "bananePlantain1.jpg",
      caption: "Banane Plantain"
    },
    {
      image: "pommes-terre-1.jpg",
      caption: "Pommes de Terre"
    },
    {
      image: "aubergine2.jpg",
      caption: "Aubergines"
    },
    {
      image: "pourpre-patate-1.jpg",
      caption: "Patate Douce"
    },
    {
      image: "carottes2.jpg",
      caption: "Carottes"
    },
    {
      image: "ciboule2.jpg",
      caption: "Ciboules"
    },
    {
      image: "gingembre2.jpg",
      caption: "Gingembre"
    },
    {
      image: "soja_1.jpg",
      caption: "Soja"
    },
    
    {
      image: "tomates1.jpg",
      caption: "Tomates"
    },
  ];

  const captionStyle = {
    fontSize: '2em',
    fontWeight: 'bold',
  }

  const slideNumberStyle = {
    fontSize: '20px',
    fontWeight: 'bold',
  }
 

    return (
    <div className='postOTrue'>

    <Abonnement/>
    
    <div className='postO2'>


         <div className='singleFirst'>
            <div className='singlePictCarousel'>

<Carousel
            data={data}
            time={3000}
            width="360px"
            height="400px"
            captionStyle={captionStyle}
            radius="10px"
            slideNumber={true}
            slideNumberStyle={slideNumberStyle}
            captionPosition="bottom"
            automatic={true}
            dots={true}
            pauseIconColor="red"
            pauseIconSize="40px"
            slideBackgroundColor="#BC4749"
            slideImageFit="cover"
            thumbnails={true}
            thumbnailWidth="100px"
             style={{
              textAlign: "center",
              maxWidth: "360px",
              maxHeight: "100px",
              margin: "20px 0px",
            }}
          />

          </div>

         </div>

          <div className='singleSecond'>
            <div>
                <h3 id='protext1'>Vous êtes un Fournisseur Agricole, ou un Acheteur des Produits Agricoles ?</h3>
                <h3 id='protext1t'>Vendez et Achetez vos Produits Agricoles à partir de vôtre téléphone, et Recevrez des Notifications SMS </h3>
                
                 <h3>Composez <span id='protext2'>*554*1*8# </span> pour vous Abonnez à nos services, et commencez à Vendre, et Recevoir des Notifications SMS concernant la disponibilité des Produits Agricoles</h3>


                <h3 id='protext7'>Avec un Abonement de <span id='protext3'>2.000 <span id='protext5t'>FCFA /ans</span> </span> au lieu de <span id='protext4'> 4.000<span id='protext5t'> FCFA/ans</span></span> pour les Fournisseurs Agricoles.</h3>

                <h3 id='protext7'>Et un Abonement de <span id='protext3'>3.000 <span id='protext5t'>FCFA /ans</span> </span> au lieu de <span id='protext4'> 5.000<span id='protext5t'> FCFA/ans</span></span> pour les Acheteurs Agricoles.</h3>
            </div>
          </div>

          </div>


         <div className='postO'>


        <div className='postIn'>
        {
            posts.slice(0).reverse().map((p)=>(

        <Posts
            post={p}
            key={p._id}
            
        />
            )
        )}
        </div>

        <>
        <SideBar/> 

        </>
 
      
                   
        </div>
 </div>)
}

export default MainPost
