import React, { useEffect, useState } from 'react'
import { FaAngleDoubleRight, FaLandmark, FaLocationArrow, FaSearchLocation } from 'react-icons/fa'
import { MdLocationOn } from 'react-icons/md'
import { Link } from 'react-router-dom'
import Moment from 'react-moment'
import 'moment/locale/fr';
import axios from "axios"


const Posts = ({post}) => {
//get Reservation:

 const [reservation, setReservation] = useState([]);

    useEffect(() =>{
        window.scrollTo(0,0);
        
         const getReservation = async ()=>{
            const res = await axios.get('https://b-elanga-store.onrender.com/api/reserv/');
            setReservation(res.data);
            
         };
         getReservation();
    },[])


  // const PF ="http://localhost:5000/images/"

  // Photo Random:

const TuberculesdeManioc =[
       "t1.jpg",
       "t2.jpg",
       "t3.jpg",
       "t4.jpg",
       "t5.jpg"
    ]
const BananePlantain =[
        "b1.jpg",
       "b2.jpg",
       "b3.jpg",
       "b4.jpg",
       "b5.jpg"
    ]

const Tomates =[
       "to1.jpg",
       "to2.jpg",
       "to3.jpg",
       "to4.jpg",
       "to5.jpg"
    ]
const Haricot =[
       "h1.jpg",
       "h2.jpg",
       "h3.jpg",
       "h4.jpg"
      
    ]
const Igname =[
        "t1.jpg",
        ]

const Arachide =[
        "a1.jpg",
       "a2.jpg",
       "a3.jpg",
       "a4.jpg",
       "a5.jpg"   ]


const Oignons =[
        "o1.jpg",
       "o2.jpg",
       "o3.jpg",
       "o4.jpg",
       "o5.jpg"
    ]
const Ciboules =[
        "ci1.jpg",
       "ci2.jpg",
       "ci3.jpg",
       "ci4.jpg",
       "ci5.jpg"    ]

const Piment =[
        "pi1.jpg",
       "pi2.jpg",
       "pi3.jpg",
       "pi4.jpg",
       "pi5.jpg"    ]

const Aubergine =[
        "au1.jpg",
       "au2.jpg",
       "au3.jpg",
       "au4.jpg",
       "au5.jpg"
    ]

const Concombre =[
        "co1.jpg",
       "co2.jpg",
       "co3.jpg",
       "co4.jpg"
      
    ]
const PoivronVert =[
        "po1.jpg",
       "po2.jpg",
       "po3.jpg",
       "po4.jpg",
       "po5.jpg"
    ]

const Gingembre =[
        "g1.jpg",
       "g2.jpg",
       "g3.jpg",
       "g4.jpg",
       "g5.jpg"    ]


const PommedeTerre =[
        "pom1.jpg",
       "pom2.jpg",
       "pom3.jpg",
       "pom4.jpg",
       "pom5.jpg"    ]


const PatateDouce =[
        "p1.jpg",
       "p2.jpg",
          ]


const Maïs =[
        "m1.jpg",
       "m2.jpg",
       "m3.jpg",
       "m4.jpg",
       "m5.jpg"    ]


const PoisdAngole =[
        "t1.jpg",
           ]


const Carotte =[
        "c1.jpg",
       "c2.jpg",
       "c3.jpg",
       "c4.jpg",
       "c5.jpg"    ]


const Soja =[
        "s1.jpg",
       "s2.jpg",
           ]


const Sorgho =[
        "t1.jpg",
           ]



    const RTuberculesdeManioc = Math.floor(Math.random()*TuberculesdeManioc.length);

    const RBananePlantain = Math.floor(Math.random()*BananePlantain.length);

    const RTomates = Math.floor(Math.random()*Tomates.length);

    
    const RHaricot = Math.floor(Math.random()*Haricot.length);

    
    const RIgname = Math.floor(Math.random()*Igname.length);

    
    const RArachide = Math.floor(Math.random()*Arachide.length);

    
    const ROignons = Math.floor(Math.random()*Oignons.length);

    
    const RCiboules = Math.floor(Math.random()*Ciboules.length);

    
    const RPiment = Math.floor(Math.random()*Piment.length);

    
    const RAubergine = Math.floor(Math.random()*Aubergine.length);

    
    const RConcombre = Math.floor(Math.random()*Concombre.length);

    
    const RPoivronVert = Math.floor(Math.random()*PoivronVert.length);

    
    const RGingembre = Math.floor(Math.random()*Gingembre.length);

    
    const RPommedeTerre = Math.floor(Math.random()*PommedeTerre.length);

    
    const RPatateDouce = Math.floor(Math.random()*PatateDouce.length);

    
    const RMaïs = Math.floor(Math.random()*Maïs.length);

    
    const RPoisdAngole = Math.floor(Math.random()*PoisdAngole.length);

    
    const RCarotte = Math.floor(Math.random()*Carotte.length);

    
    const RSoja = Math.floor(Math.random()*Soja.length);

    
    const RSorgho = Math.floor(Math.random()*Sorgho.length);

    

    return (
        <>
        <div className="post">
        <Link to={`/post/${post._id}`}>
          <div className="postPhoto">

          {post.typeCulture ==="Tubercules de Manioc" ? (<img src={TuberculesdeManioc[RTuberculesdeManioc]} className='photoPost' alt="photo" />):
          
          ( post.typeCulture ==="Banane Plantain" ? (<img src={BananePlantain[RBananePlantain]} className='photoPost' alt="photo" />):
          
           (post.typeCulture ==="Tomates" ? (<img src={Tomates[RTomates]} className='photoPost' alt="photo" />):
           
           (post.typeCulture ==="Haricot" ?(<img src={Haricot[RHaricot]} className='photoPost' alt="photo" />):
           
           (post.typeCulture ==="Igname" ?(<img src={Igname[RIgname]} className='photoPost' alt="photo" />):
           
           (post.typeCulture ==="Arachide" ?(<img src={Arachide[RArachide]} className='photoPost' alt="photo" />):

           (post.typeCulture ==="Oignons" ?(<img src={Oignons[ROignons]} className='photoPost' alt="photo" />):

           (post.typeCulture ==="Ciboules" ?(<img src={Ciboules[RCiboules]} className='photoPost' alt="photo" />):

           (post.typeCulture ==="Piment" ?(<img src={Piment[RPiment]} className='photoPost' alt="photo" />):

           (post.typeCulture ==="Aubergine" ?(<img src={Aubergine[RAubergine]} className='photoPost' alt="photo" />):

           (post.typeCulture ==="Concombre" ?(<img src={Concombre[RConcombre]} className='photoPost' alt="photo" />):

           (
            post.typeCulture ==="Poivron Vert" ?(<img src={PoivronVert[RPoivronVert]} className='photoPost' alt="photo" />):

            (post.typeCulture ==="Gingembre" ?(<img src={Gingembre[RGingembre]} className='photoPost' alt="photo" />):

            (post.typeCulture ==="Pomme de Terre" ?(<img src={PommedeTerre[RPommedeTerre]} className='photoPost' alt="photo" />):

            (post.typeCulture ==="Patate Douce" ?(<img src={PatateDouce[RPatateDouce]} className='photoPost' alt="photo" />):

            (post.typeCulture ==="Maïs" ?(<img src={Maïs[RMaïs]} className='photoPost' alt="photo" />):

            (post.typeCulture ==="Pois d'Angole" ?(<img src={PoisdAngole[RPoisdAngole]} className='photoPost' alt="photo" />):

            (post.typeCulture ==="Carotte" ?(<img src={Carotte[RCarotte]} className='photoPost' alt="photo" />):

            (post.typeCulture ==="Soja" ?(<img src={Soja[RSoja]} className='photoPost' alt="photo" />):

            (post.typeCulture ==="Sorgho" ?(<img src={Sorgho[RSorgho]} className='photoPost' alt="photo" />):

            (null)))))))))
           ))))))))))


            )}
            
          </div>

          <div className="postInfos">
          <h1> Récolte de : {post.typeCulture}</h1>
          <h3>{post.farmingName}</h3>

          {/* <h3><MdLocationOn size="1.2em" id='popIcon2' /> {post.farmingAdresse}</h3> */}

          <h3> {post.quantityCulture} kg Disponible</h3>
          <h1 id='prix' > {post.priceCulture} FCFA/ kg</h1>

          </div>

          <div className="postReserv">
              
              <h3 id='momentDate'> <Moment locale='fr' fromNow>{post.createdAt}</Moment></h3>
          </div>
          </Link>  
        </div>
        
        
        </>
        
    )
}

export default Posts
