import React, { useContext, useState } from 'react'
import { Context } from '../context/Context';
import axios from "axios"
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet'
import { FaEye, FaEyeSlash } from 'react-icons/fa';
import { ToastContainer, toast } from 'react-toastify';


const Login = () => {

    
const [username,setUsername] = useState("");
const [password, setPassword] = useState("");

const {dispatch, isFetching,user} = useContext(Context);

const [passwordType, setPasswordType] = useState("password");
const showPass = ()=>{

    if(passwordType==="password"){
        
        setPasswordType("text");
        return;
    }else{

        setPasswordType("password");
    } 
}

const handleSubmit = async (e)=>{
            e.preventDefault();
            dispatch({type:"LOGIN_START"}); 

            try{ 
            const res = await axios.post("https://b-elanga-store.onrender.com/api/auth/login", {
                username,password
            });

                dispatch({type:"LOGIN_SUCCESS", payload:res.data});

                 toast.success("Succès: Vous ete maintenant connecte ! "); 

                } catch(error){
                    dispatch({type:"LOGIN_FAILURE"});
                    toast.error(` Echec: Mauvaises informations, Veuillez verifiez vos Informations ou vous enregistrez`)

                }

        }


    return (
        <div className='loginO'>
        <Helmet>
        <link rel="canonical" href ="https://bilangastore.com/login"/>

        <title> Se Connecter</title>
        <meta name="description" content="Connecter-vous a votre compte, et acceder aux Annonces des Agriculteurs." />
        
      </Helmet>
        
 
        <div className="loginI">
        <form autoComplete="off" onSubmit={handleSubmit}
         >
            <div className="loginForm">
            <h1> Se Connecter</h1>
                
                <div className="labelE"><label><h2>Utilisateur</h2></label>
                </div>
                <div className="email">
                
                    <input type="text" name="" id="emailI" placeholder="Votre nom d'utilisateur" required autoComplete="off"
                       onChange={e=>{setUsername(e.target.value)}}

                       onInvalid={e=>e.target.setCustomValidity("Veuillez Entrer Votre nom d'utilisateur")}

               onInput={e=>e.target.setCustomValidity('')}
               
               
                    />
                </div>
                <div className="labelP"><label><h2>Mot de Pass</h2></label>
                </div>
                <div className="pass">
                    <input type={passwordType} id="passI" placeholder='Entrez votre Mot de Pass' required autoComplete="off" 
                    name="password"
                        onChange={e=>{setPassword(e.target.value)}}

                        onInvalid={e=>e.target.setCustomValidity("Veuillez Entrer Votre Mot de Pass svp")}

               onInput={e=>e.target.setCustomValidity('')}
                    />
                    {passwordType ==="password" ? (<FaEyeSlash size="1.5em" id="eye" onClick={showPass} />):(<FaEye id="eye" size="1.5em" 
                        onClick={showPass}
                    />)}
                     
                     
                </div>

                <div className="loginB">
                    <button id="loginBI" type="submit" 
                     disabled = {isFetching ? true : false}
                    >Se Connecter</button>
                </div>

                    {user &&(
                        <div className='loginOptions'>
                    <h3>Pas de compte ? <span><Link to="/register"> Enregistrez-Vous</Link></span></h3>
                </div>
                    )}
                

                
            </div>
            </form>
        </div>
            
        </div>
    )
}

export default Login
