import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import axios from "axios"


const SideBar = () => {


     const [cat,setCat] = useState([]);

    useEffect(() =>{
        window.scrollTo(0,0);
        
         const getCat = async ()=>{
            const res = await axios.get('https://b-elanga-store.onrender.com/api/cate/');
            setCat(res.data);
            
         };
         getCat();
    },[])
    

    return (
        <>
        <div className='sideBarO'>

        <div className='sideBarIn'>
            <h1> Rechercher par Types de Cultures</h1>
            <div className='typeCulture'>
                <ul>
                {cat.map((c)=>(

                    <li key={c._id}>
                    <Link to={`/?cate=${c.nameCulture}`}><h3> {c.nameCulture}</h3> </Link> </li>
                ))}
                    
                    
                </ul>
            </div>
        </div>
        </div>
        </>
            
    )
}

export default SideBar
