import React, { useEffect } from 'react'
import Footer from './Footer'
import Header from './Header'
import SideBar from './SideBar'
import { Helmet } from 'react-helmet'
import { FaLinkedin} from 'react-icons/fa'

const About = () => {

useEffect(() => {
     window.scrollTo(0,0);


    }, [])

    return (
        <div>
        <Helmet>
        <link rel="canonical" href ="https://bilangastore.com/about"/>

        <title>A propos de Bilanga Store</title>
        <meta name="description" content="BILANGA STORE est une Startup Congolaise qui met en relation les Agriculteurs du Congo et les différents Revendeurs, pour la Vente et l'Achat des produits Agricoles. 

Bilanga Store se veut accessible à tous Agriculteurs et Revendeurs. Les Agriculteurs peuvent s'abonner a nos services, en passant leurs Annonces par appel en Langue Locale ou officielle, pour la vente de leurs produits Agricoles. Les Revendeurs peuvent aussi s'abonner au service de Notification par SMS, leur permettant de recevoir en temps réel, toutes Informations y compris les contacts des différents Agriculteurs quand une Annonce concernant un produit Agricole est postée à l'instant t. " />
        
      </Helmet>

        <Header/>
            <div className='aboutO'>
                
                <div className="aboutPic">
                <h1> A PROPOS de ELANGA STORE</h1>
                <img src="1c.jpg" alt="" srcset="" id='imgAbout' 

                    style={{backgroundSize:"cover"}}
                />

                

                </div>
                
                <div className="aboutIn">

                <div className="aboutIn2">

                <ul>
                    <li>
                        <h1>BILANGA STORE</h1>
                        <p>BILANGA STORE est une Startup Congolaise qui met en relation les Agriculteurs du Congo et les différents Revendeurs, pour la Vente et l'Achat des produits Agricoles. 

Bilanga Store se veut accessible à tous Agriculteurs et Revendeurs. Les Agriculteurs peuvent s'abonner a nos services, en passant leurs Annonces en utilisanr le service USSD , nootament en tapant le code *550*1*8#, pour la vente de leurs produits Agricoles. Les Revendeurs peuvent aussi s'abonner au service de Notification par SMS, leur permettant de recevoir en temps réel, toutes Informations y compris les contacts des différents Agriculteurs et les différents villes  quand une Annonce concernant un produit Agricole est postée à l'instant t. </p>
                    </li>
                    <li>
                        <h1>Nos Objectifs</h1>
                        <p>Bilanga store permet de rassembler et d'aider les communautées d'Agriculteurs Congolais, en les permettant de vendre   leurs produits Agricoles pour lutter contre toute perte de leurs produits causer par un manque de clientèle, et aussi pouvoir faire face à la concurence des Produits Agricoles importés. Elanga Store permet ainsi de valoriser le Secteur Agricole Congolais.

                        Bilanga Store offre  une façon simple et éfficace de vendre et acheter des produits Agricoles.


</p>
                    </li>
                    <li>
                        <h1>Developpement</h1>
                        <p>La Plateforme <span id="store">BILANGA STORE</span> a été développé par <span id="store">Mbemba Isaac Peniel Leandre </span>, Ingénieur Télécom de formation, et Développeur Fullstack Web.
</p>
                    </li>
                    <li>
                        <div className='photoCredict'>

                    <div className='photoP'>
                    <img src="/pp.jpg" alt="pp" />
                    </div>

                    <div className='social'>

                        <a href="https://www.linkedin.com/in/isaac-peniel-leandre-mbemba-b7b5a4176/" target="_blank" rel="noopener noreferrer">
                            <FaLinkedin id='iconpp' size="3.5em" />
                        </a>


                    </div>
                    
                </div>

                    </li>
                    
                    
                </ul>

                </div>

                <>
                    <SideBar/>
                </>
                </div>

            </div>

            <Footer/>
        </div>
    )
}

export default About
