import React from 'react'
import Loading from './Loading'

const LoadingTwo = () => {
    return (
        <div>
            <div className='loadingPage2'>
        <div className='landingLogo' >
        <>
            <img src="favicon-32x32.png" alt="" />

            <h1>BILANGA <span> STORE</span></h1>

             </>
       
        </div>

        <div className='landingLoading'>
            <Loading/>
        </div>
            
        </div>
        </div>
    )
}

export default LoadingTwo
