import React, { useEffect, useState } from 'react'
import Slider from 'react-slick'



const Cover = () => {

    
const photos = [
  {
    id: 'p1',
    url: "bgn1.png",
  },
  {
    id: 'p2',
    url: 'bgn2.png',
  },  
  {  
    id: 'p3',
    url: 'bgn3.png',
  },
  {
    id: 'p4',
    url: 'bgn4.png',
  }
];


 // show the photo with this index
  const [currentIndex, setCurrentIndex] = useState(0);

  // move to the next photo
  // if we are at the end, go to the first photo
  const next = () => {
    setCurrentIndex((currentIndex + 1) % photos.length);
  };

  // move to the previous photo
  // if we are at the beginning, go to the last photo
  const prev = () => {
    setCurrentIndex((currentIndex - 1 + photos.length) % photos.length);
  };

  // automatic
//
 const interval = 5000;
 let slideInterval ;

  function auto (){
 slideInterval = setInterval(next,interval);
 
 }

 const autoscroll = true;

 useEffect(() => {
    setCurrentIndex(0);
    
}, [])

useEffect(() => {
    
    {autoscroll &&(
        auto()
        
        )}

    return () => clearInterval(slideInterval);
    
}, [currentIndex])

    return (
        <div className='sliderContainerO'>
      {/* Render the carousel */}
      <div className='slider-container'>
        {photos.map((photo) => (
          <div
            key={photo.id}

            // if the photo is the current photo, show it
            className={
              photos[currentIndex].id === photo.id ? 'fade' : 'slide fade'
            }
          >
            <img src={photo.url} alt={photo.title} className='photo' />
            
          </div>
        ))}

        {/* Previous button */}
        <button onClick={prev} className='prev'>
          &lt;
        </button>

        {/* Next button */}
        <button onClick={next} className='next'>
          &gt;
        </button>
      </div>

      {/* Render dots indicator */}
      <div className='dotsm'>
        {photos.map((photo) => (
          <span
            key={photo.id}
            // highlight the dot that corresponds to the current photo
            className={
              photos[currentIndex].id === photo.id ? 'dotm activem' : 'dotm'
            }
            // when the user clicks on a dot, go to the corresponding photo
            onClick={() => setCurrentIndex(photos.indexOf(photo))}
          ></span>
        ))}
      </div>
    </div>
    )
}

export default Cover
