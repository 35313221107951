import React, { useEffect } from 'react'
import Footer from './Footer'
import Header from './Header'
import SideBar from './SideBar'
import { Helmet } from 'react-helmet'

const Termes = () => {

    useEffect(() => {
     window.scrollTo(0,0);

     
    }, [])

    return (
        <div>

        <Helmet>
        <link rel="canonical" href ="https://bilangastore.com/about"/>

        <title>Termes & Conditions</title>
        <meta name="description" content="Les Termes et les Conditions d'utilisation de la plateforme BILANGA STORE" />
        
      </Helmet>
            <Header/>
            <div className='aboutO'>
                
                <div className="aboutPic">
                <h1> TERMES & CONDITIONS</h1>
                <img src="11e.jpg" alt="" srcset="" id='imgAbout' 

                    style={{backgroundSize:"cover"}}
                />

                

                </div>
                
                <div className="aboutIn">

                <div className="aboutIn2">

                <ul>
                    <li>
                        <h1>Frais et Services</h1>
        <p>L'usage de nos services est payant. Les Agriculteurs désirants utiliser notre canal doivent payer un Abonnement de 1.500 fcfa / ans par culture Agricole. La Priorité de Bilanga Store s'est de permettre à tous les Agriculteurs d'écouler facilement leurs produits, et de leur donner de la Visibilité. Quant aux Revendeurs afin de recevoir en tout temps les Notifications SMS des produits Agricoles aux quels ils se sont abonnés, le prix d'Abonnement se facture à hauteur de 3.000 FCFA / ans par Culture Agricole. </p>
                    </li>
                    <li>
                        <h1>Utilisation de nos services</h1>
        <p>Vous n'adopterez pas les comportements répréhensibles suivants:

Donner des informations fausses ou de nature à induire leur destinataire en erreur;
Porter atteinte aux droits détenus par des tiers;
Diffuser des pourriels ou proposer des systèmes de commercialisation à paliers multiples et des pyramides;
Diffuser des virus ou autres technologies de nature à porter atteinte à Bilanga Store ou aux intérêts et biens d'autres utilisateurs de Bilanga Store;
Imposer une charge déraisonnable sur notre infrastructure ou interférer avec le bon fonctionnement de Bilanga Store;
Copier, modifier ou distribuer tout contenu appartenant à autrui;
Utiliser un robot, araignée, racleur ou tout autre moyen électronique pour accéder à Bilanga Store et pour collecter du contenu de site quel qu'en soit l'intention, sans permission écrite de Bilanga Store.
Collecter ou vous procurer de toute autre manière des informations relatives à autrui, notamment les adresses de courriel sans y avoir été autorisé;
Contourner les mesures destinées à prévenir ou restreindre l'accès à Bilanga Store.</p>
                    </li>
                    <li>
                        <h1>L’affichage du site</h1>
        <p>Ce site est optimisé pour Chrome, Firefox, Internet Explorer en versions 8 et supérieures et Safari. Le mode graphique est défini pour un écran standard pour une résolution minimale de 1024 X 640.</p>
                    </li>
                    <li>
                        <h1>Droit d’auteur</h1> 
        <p> Copyright © Textes, illustrations : Tous droits réservés BILANGA <span id='store'>STORE</span> 2023</p>
                    </li>
                    <li>
                        <h1>Droit de reproduction</h1>
       <p>La reproduction sur support papier, la reproduction des pages de ce site sur un support papier n'est pas autorisée</p>
                    </li>
                    <li>
                        <h1>La création des liens vers Bilangastore.com</h1>
       <p>Le site de www.Bilangastore.com autorise la mise en place d’un lien hypertexte pointant vers son contenu, sous réserve de : ne pas utiliser la technique du lien profond (« deep linking »), c’est-à-dire que les pages du site www.Bilangastore.com ne doivent pas être imbriquées à l’intérieur des pages d’un autre site, mais accessible par l’ouverture d’une fenêtre.
        Mentionnant la source qui pointera grâce à un lien hypertexte directement sur le contenu visé. Les informations utilisées ne doivent l’être qu’à des fins personnelles, associatives ou professionnelles ; toute utilisation à des fins commerciales ou publicitaires est exclue. Attention ! Cette autorisation ne s’applique pas aux sites internet diffusant des informations à caractère polémique, pornographique, xénophobe ou pouvant, dans une plus large mesure porter atteinte à la sensibilité du plus grand nombre. Pour d’autres utilisations, veuillez nous consulter.</p>
                    </li>
                    
                    <li>
                        <h1>La protection des données personnelles</h1>
        <p>Aucune information personnelle n’est collectée à votre insu. Aucune information personnelle n’est cédée à des tiers.</p>
                    </li>
                    <li>
                        <h1>Utilisation de vos renseignements personnels</h1>
        <p>Vous acceptez que nous puissions utiliser vos renseignements personnels aux fins suivantes:

vous donner accès à nos services et à notre service de soutien à la clientèle par courriel ou par téléphone;
prévenir, détecter et étudier les activités potentiellement interdites ou illégales, les fraudes et les intrusions et assurer le respect de nos conditions d'utilisation;
personnaliser, évaluer et améliorer nos services, notre contenu et nos publicités;
vous contacter par courriel, par message texte (SMS) ou par téléphone, vous poser des questions sur nos services dans le cadre d'activités de marketing ciblées, de mises à jour et d'offres promotionnelles en fonction de vos préférences de notification
vous fournir les autres services que vous avez demandés, conformément à nos procédures de collecte d'informations</p>
                    </li>
                    <li>
                        <h1>Données statistiques</h1>
        <p>Afin de mieux vous servir, nous mesurons le nombre de pages vues, le nombre de visites, ainsi que l’activité des visiteurs sur le site, et leur fréquence de retour. A cet effet, nous utilisons la technologie Google Analytics pour mieux analyser ces données et pour ameliorer nos services.</p>
                    </li>
                    
                </ul>

                </div>

                <>
                    <SideBar/>
                </>
                </div>

            </div>

            <Footer/>
        </div>
    )
}

export default Termes


//
      