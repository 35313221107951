import React, { useEffect, useState } from 'react'
import { ToastContainer } from 'react-toastify'
import Footer from './Footer'
import Header from './Header'
import axios from "axios"
import {toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const Acheteurs = () => {

    //all SMS Notifications Categories

const [lastNameNoti,setLastNameNoti]=useState("");
const [firstNameNoti,setFirstNameNoti]=useState("");
const [typeCultureNoti,setTypeCultureNoti]=useState("");
const [phoneNumberNoti,setPhoneNumberNoti]=useState("");
const [buyerAdresseNoti,setBuyerAdresseNoti]=useState("");



 const [partyId,setPartyId] = useState();

 const [momoSucess,setMomoSucess] = useState()
    const [momoFailed,setMomoFailed] = useState()
    


// Adding SMS nOTIFICATION

const addingSmsNoti = async (e)=>{
        e.preventDefault();

        const listNoti ={
            lastNameNoti,firstNameNoti,typeCultureNoti,phoneNumberNoti,buyerAdresseNoti
        }
   
        if(typeCultureNoti ==="Tomates"){


        try{
            const res1 = await axios.post("https://b-elanga-store.onrender.com/api/brouillonAcheteurs/",listNoti);
  
             if( res1.data){
            try{
                const res2 = await axios.post('https://b-elanga-store.onrender.com/api/reqtoPay',{partyId});

                  

                const response = res2.data;

                  const momoTransaction = response.financialTransactionId;

                toast.success("Votre paiement est en cours...");

                if( response.status ==="SUCCESSFUL"){

                    setMomoSucess(true);
                   
                     
                    try{
            const res = await axios.post('https://b-elanga-store.onrender.com/api/tomate/',{
            lastNameNoti,firstNameNoti,typeCultureNoti,phoneNumberNoti,buyerAdresseNoti,momoTransaction
        }
                   );

            if( res.data){
                toast.success("Succès: Votre Abonnement a été enregistré ! ");

                try{
           await axios.post('https://b-elanga-store.onrender.com/api/sms',{
                 to:`+242055489004`,
                body:`Mr/Mrs ${lastNameNoti} ${firstNameNoti}, BILANGA STORE : vous notifie que Votre Abonnement en tant qu'Acheteur pour la categorie : ${typeCultureNoti},  pour une anneé a bien été enregistré. Vous allez maintenant recevoir des Notifications SMS de toutes les annonces de: ${typeCultureNoti}.
                #BILANGA STORE SMS Notification
                `
            });
            
        }catch(error){
            
        }

                setTimeout(()=>{window.location.replace("/");},3000);  
                
               
            }
                

               }catch(error){
            
            toast.error(` Echec: Vous avez déja un compte, choissisiez un numéro unique`)


            setTimeout(()=>{window.location.reload();},3000); 
        }

    
                }


                else if( response.status ==="FAILED"){

                    setMomoFailed(true);
                }


        }catch(error){
           toast.error(` Echec: Votre paiement n'a pas aboutie`)
        }

        
             }

               }catch(error){
            
            toast.error(` Echec: Vérifiez vos Informations`)

            setTimeout(()=>{window.location.reload();},3000); 
        }


    }



        else if(typeCultureNoti ==="Arachide"){

             try{
            const res1 = await axios.post("https://b-elanga-store.onrender.com/api/brouillonAcheteurs/",listNoti);
  
             if( res1.data){
            try{
                const res2 = await axios.post('https://b-elanga-store.onrender.com/api/reqtoPay',{partyId});

                  
                const response = res2.data;

                const momoTransaction = response.financialTransactionId;

               

                if( response.status ==="SUCCESSFUL"){

                   

                    setMomoSucess(true);
                     
                  
                    try{
            const res = await axios.post('https://b-elanga-store.onrender.com/api/arachide/',{
            lastNameNoti,firstNameNoti,typeCultureNoti,phoneNumberNoti,buyerAdresseNoti,momoTransaction
        }
                   );

            if( res.data){
                toast.success("Succès: Votre Abonnement a été enregistré ! ");

                try{
           await axios.post('https://b-elanga-store.onrender.com/api/sms',{
                 to:`+242055489004`,
                body:`Mr/Mrs ${lastNameNoti} ${firstNameNoti}, BILANGA STORE : vous notifie que Votre Abonnement en tant qu'Acheteur pour la categorie : ${typeCultureNoti},  pour une anneé a bien été enregistré. Vous allez maintenant recevoir des Notifications SMS de toutes les annonces de: ${typeCultureNoti}.
                #BILANGA STORE SMS Notification
                `
            });
            
        }catch(error){
            
        }

                setTimeout(()=>{window.location.replace("/");},3000);  
                
               
            }
                

               }catch(error){
            
            toast.error(` Echec: Vous avez déja un compte, choissisiez un numéro unique`)


            setTimeout(()=>{window.location.reload();},3000); 
        }

    
                }


                else if( response.status ==="FAILED"){

                    setMomoFailed(true);
                }


        }catch(error){
           toast.error(` Echec: Votre paiement n'a pas aboutie`)
        }

        
             }

               }catch(error){
            
            toast.error(` Echec: Vérifiez vos Informations`)

            setTimeout(()=>{window.location.reload();},3000); 
        }


    }




        else if( typeCultureNoti=== "Tubercules de Manioc"){

try{
            const res1 = await axios.post("https://b-elanga-store.onrender.com/api/brouillonAcheteurs/",listNoti);
  
             if( res1.data){
            try{
                const res2 = await axios.post('https://b-elanga-store.onrender.com/api/reqtoPay',{partyId});

                  
                const response = res2.data;

                const momoTransaction = response.financialTransactionId;

                toast.success("Votre paiement est en cours...");

                if( response.status ==="SUCCESSFUL"){

                    setMomoSucess(true);
                     
            
                    try{
            const res = await axios.post('https://b-elanga-store.onrender.com/api/tuberculeDeManioc/',{
            lastNameNoti,firstNameNoti,typeCultureNoti,phoneNumberNoti,buyerAdresseNoti,momoTransaction
        }
                   );

            if( res.data){
                toast.success("Succès: Votre Abonnement a été enregistré ! ");

                try{
           await axios.post('https://b-elanga-store.onrender.com/api/sms',{
                 to:`+242055489004`,
                body:`Mr/Mrs ${lastNameNoti} ${firstNameNoti}, BILANGA STORE : vous notifie que Votre Abonnement en tant qu'Acheteur pour la categorie : ${typeCultureNoti},  pour une anneé a bien été enregistré. Vous allez maintenant recevoir des Notifications SMS de toutes les annonces de: ${typeCultureNoti}.
                #BILANGA STORE SMS Notification
                `
            });
            
        }catch(error){
            
        }

                setTimeout(()=>{window.location.replace("/");},3000);  
                
               
            }
                

               }catch(error){
            
            toast.error(` Echec: Vous avez déja un compte, choissisiez un numéro unique`)


            setTimeout(()=>{window.location.reload();},3000); 
        }

    
                }


                else if( response.status ==="FAILED"){

                    setMomoFailed(true);
                }


        }catch(error){
           toast.error(` Echec: Votre paiement n'a pas aboutie`)
        }

        
             }

               }catch(error){
            
            toast.error(` Echec: Vérifiez vos Informations`)

            setTimeout(()=>{window.location.reload();},3000); 
        }


    }



 else if(typeCultureNoti === "Banane Plantain"){

    try{
            const res1 = await axios.post("https://b-elanga-store.onrender.com/api/brouillonAcheteurs/",listNoti);
  
             if( res1.data){
            try{
                const res2 = await axios.post('https://b-elanga-store.onrender.com/api/reqtoPay',{partyId});

                  
                const response = res2.data;

                const momoTransaction = response.financialTransactionId;

                toast.success("Votre paiement est en cours...");

                if( response.status ==="SUCCESSFUL"){

                    setMomoSucess(true);

                    
                     
                    try{
            const res = await axios.post('https://b-elanga-store.onrender.com/api/bananePlantain/',{
            lastNameNoti,firstNameNoti,typeCultureNoti,phoneNumberNoti,buyerAdresseNoti,momoTransaction
        }
                   );

            if( res.data){
                toast.success("Succès: Votre Abonnement a été enregistré ! ");

                try{
           await axios.post('https://b-elanga-store.onrender.com/api/sms',{
                 to:`+242055489004`,
                body:`Mr/Mrs ${lastNameNoti} ${firstNameNoti}, BILANGA STORE : vous notifie que Votre Abonnement en tant qu'Acheteur pour la categorie : ${typeCultureNoti},  pour une anneé a bien été enregistré. Vous allez maintenant recevoir des Notifications SMS de toutes les annonces de: ${typeCultureNoti}.
                #BILANGA STORE SMS Notification
                `
            });
            
        }catch(error){
            
        }

                setTimeout(()=>{window.location.replace("/");},3000);  
                
               
            }
                

               }catch(error){
            
            toast.error(` Echec: Vous avez déja un compte, choissisiez un numéro unique`)


            setTimeout(()=>{window.location.reload();},3000); 
        }



                }


                else if( response.status ==="FAILED"){

                    setMomoFailed(true);
                }


        }catch(error){
           toast.error(` Echec: Votre paiement n'a pas aboutie`)
        }

        
             }

               }catch(error){
            
            toast.error(` Echec: Vérifiez vos Informations`)

            setTimeout(()=>{window.location.reload();},3000); 
        }


    }




           
 else if(typeCultureNoti === "Haricot"){

    try{
            const res1 = await axios.post("https://b-elanga-store.onrender.com/api/brouillonAcheteurs/",listNoti);
  
             if( res1.data){
            try{
                const res2 = await axios.post('https://b-elanga-store.onrender.com/api/reqtoPay',{partyId});

                  
                const response = res2.data;

                 const momoTransaction = response.financialTransactionId;

                
                toast.success("Votre paiement est en cours...");

                if( response.status ==="SUCCESSFUL"){

                    setMomoSucess(true);

                 

                    try{
            const res = await axios.post('https://b-elanga-store.onrender.com/api/haricot/',{
            lastNameNoti,firstNameNoti,typeCultureNoti,phoneNumberNoti,buyerAdresseNoti,momoTransaction
        }
                   );

            if( res.data){
                toast.success("Succès: Votre Abonnement a été enregistré ! ");

                try{
           await axios.post('https://b-elanga-store.onrender.com/api/sms',{
                 to:`+242055489004`,
                body:`Mr/Mrs ${lastNameNoti} ${firstNameNoti}, ELANGA STORE : vous notifie que Votre Abonnement en tant qu'Acheteur pour la categorie : ${typeCultureNoti},  pour une anneé a bien été enregistré. Vous allez maintenant recevoir des Notifications SMS de toutes les annonces de: ${typeCultureNoti}.
                #ELANGA STORE SMS Notification
                `
            });
            
        }catch(error){
            
        }

                setTimeout(()=>{window.location.replace("/");},3000);  
                
               
            }
                

               }catch(error){
            
            toast.error(` Echec: Vous avez déja un compte, choissisiez un numéro unique`)


            setTimeout(()=>{window.location.reload();},3000); 
        }




                }


                else if( response.status ==="FAILED"){

                    setMomoFailed(true);
                }


        }catch(error){
           toast.error(` Echec: Votre paiement n'a pas aboutie`)
        }

        
             }

               }catch(error){
            
            toast.error(` Echec: Vérifiez vos Informations`)

            setTimeout(()=>{window.location.reload();},3000); 
        }


    }



 else if(typeCultureNoti === "Igname"){

    try{
            const res1 = await axios.post("https://b-elanga-store.onrender.com/api/brouillonAcheteurs/",listNoti);
  
             if( res1.data){
            try{
                const res2 = await axios.post('https://b-elanga-store.onrender.com/api/reqtoPay',{partyId});

                  
                const response = res2.data;

                const momoTransaction = response.financialTransactionId;

                
                toast.success("Votre paiement est en cours...");

                if( response.status ==="SUCCESSFUL"){

                    setMomoSucess(true);

                   

                    try{
            const res = await axios.post('https://b-elanga-store.onrender.com/api/igname/',{
            lastNameNoti,firstNameNoti,typeCultureNoti,phoneNumberNoti,buyerAdresseNoti,momoTransaction
        }
                   );

            if( res.data){
                toast.success("Succès: Votre Abonnement a été enregistré ! ");

                try{
           await axios.post('https://b-elanga-store.onrender.com/api/sms',{
                 to:`+242055489004`,
                body:`Mr/Mrs ${lastNameNoti} ${firstNameNoti}, ELANGA STORE : vous notifie que Votre Abonnement en tant qu'Acheteur pour la categorie : ${typeCultureNoti},  pour une anneé a bien été enregistré. Vous allez maintenant recevoir des Notifications SMS de toutes les annonces de: ${typeCultureNoti}.
                #ELANGA STORE SMS Notification
                `
            });
            
        }catch(error){
            
        }

                setTimeout(()=>{window.location.replace("/");},3000);  
                
               
            }
                

               }catch(error){
            
            toast.error(` Echec: Vous avez déja un compte, choissisiez un numéro unique`)


            setTimeout(()=>{window.location.reload();},3000); 
        }


                }


                else if( response.status ==="FAILED"){

                    setMomoFailed(true);
                }


        }catch(error){
           toast.error(` Echec: Votre paiement n'a pas aboutie`)
        }

        
             }

               }catch(error){
            
            toast.error(` Echec: Vérifiez vos Informations`)

            setTimeout(()=>{window.location.reload();},3000); 
        }


    }



 else if(typeCultureNoti === "Oignons"){

    try{
            const res1 = await axios.post("https://b-elanga-store.onrender.com/api/brouillonAcheteurs/",listNoti);
  
             if( res1.data){
            try{
                const res2 = await axios.post('https://b-elanga-store.onrender.com/api/reqtoPay',{partyId});

                  
                const response = res2.data;

                const momoTransaction = response.financialTransactionId;

               
                toast.success("Votre paiement est en cours...");

                if( response.status ==="SUCCESSFUL"){

                    setMomoSucess(true);

                

                    try{
            const res = await axios.post('https://b-elanga-store.onrender.com/api/oignons/',{
            lastNameNoti,firstNameNoti,typeCultureNoti,phoneNumberNoti,buyerAdresseNoti,momoTransaction
        }
                   );

            if( res.data){
                toast.success("Succès: Votre Abonnement a été enregistré ! ");

                try{
           await axios.post('https://b-elanga-store.onrender.com/api/sms',{
                 to:`+242055489004`,
                body:`Mr/Mrs ${lastNameNoti} ${firstNameNoti}, ELANGA STORE : vous notifie que Votre Abonnement en tant qu'Acheteur pour la categorie : ${typeCultureNoti},  pour une anneé a bien été enregistré. Vous allez maintenant recevoir des Notifications SMS de toutes les annonces de: ${typeCultureNoti}.
                #ELANGA STORE SMS Notification
                `
            });
            
        }catch(error){
            
        }

                setTimeout(()=>{window.location.replace("/");},3000);  
                
               
            }
                

               }catch(error){
            
            toast.error(` Echec: Vous avez déja un compte, choissisiez un numéro unique`)


            setTimeout(()=>{window.location.reload();},3000); 
        }




                }


                else if( response.status ==="FAILED"){

                    setMomoFailed(true);
                }


        }catch(error){
           toast.error(` Echec: Votre paiement n'a pas aboutie`)
        }

        
             }

               }catch(error){
            
            toast.error(` Echec: Vérifiez vos Informations`)

            setTimeout(()=>{window.location.reload();},3000); 
        }


    }



           
    else if(typeCultureNoti === "Ciboules"){
        // 

    try{
            const res1 = await axios.post("https://b-elanga-store.onrender.com/api/brouillonAcheteurs/",listNoti);
  
             if( res1.data){
            try{
                const res2 = await axios.post('https://b-elanga-store.onrender.com/api/reqtoPay',{partyId});

                  
                const response = res2.data;

                const momoTransaction = response.financialTransactionId;

                
                toast.success("Votre paiement est en cours...");

                if( response.status ==="SUCCESSFUL"){

                    setMomoSucess(true);

                    try{
            const res = await axios.post('https://b-elanga-store.onrender.com/api/ciboules/',{
            lastNameNoti,firstNameNoti,typeCultureNoti,phoneNumberNoti,buyerAdresseNoti,momoTransaction
        }
                   );

            if( res.data){
                toast.success("Succès: Votre Abonnement a été enregistré ! ");

                try{
           await axios.post('https://b-elanga-store.onrender.com/api/sms',{
                 to:`+242055489004`,
                body:`Mr/Mrs ${lastNameNoti} ${firstNameNoti}, ELANGA STORE : vous notifie que Votre Abonnement en tant qu'Acheteur pour la categorie : ${typeCultureNoti},  pour une anneé a bien été enregistré. Vous allez maintenant recevoir des Notifications SMS de toutes les annonces de: ${typeCultureNoti}.
                #ELANGA STORE SMS Notification
                `
            });
            
        }catch(error){
            
        }

                setTimeout(()=>{window.location.replace("/");},3000);  
                
               
            }
                

               }catch(error){
            
            toast.error(` Echec: Vous avez déja un compte, choissisiez un numéro unique`)


            setTimeout(()=>{window.location.reload();},3000); 
        }




                }


                else if( response.status ==="FAILED"){

                    setMomoFailed(true);
                }


        }catch(error){
           toast.error(` Echec: Votre paiement n'a pas aboutie`)
        }

        
             }

               }catch(error){
            
            toast.error(` Echec: Vérifiez vos Informations`)

            setTimeout(()=>{window.location.reload();},3000); 
        }


    }


  
 else if(typeCultureNoti === "Piment"){

    try{
            const res1 = await axios.post("https://b-elanga-store.onrender.com/api/brouillonAcheteurs/",listNoti);
  
             if( res1.data){
            try{
                const res2 = await axios.post('https://b-elanga-store.onrender.com/api/reqtoPay',{partyId});

                  
                const response = res2.data;

                const momoTransaction = response.financialTransactionId;

                toast.success("Votre paiement est en cours...");

                if( response.status ==="SUCCESSFUL"){

                    setMomoSucess(true);

                  
                    try{
            const res = await axios.post('https://b-elanga-store.onrender.com/api/piment/',{
            lastNameNoti,firstNameNoti,typeCultureNoti,phoneNumberNoti,buyerAdresseNoti,momoTransaction
        }
                   );

            if( res.data){
                toast.success("Succès: Votre Abonnement a été enregistré ! ");

                try{
           await axios.post('https://b-elanga-store.onrender.com/api/sms',{
                 to:`+242055489004`,
                body:`Mr/Mrs ${lastNameNoti} ${firstNameNoti}, ELANGA STORE : vous notifie que Votre Abonnement en tant qu'Acheteur pour la categorie : ${typeCultureNoti},  pour une anneé a bien été enregistré. Vous allez maintenant recevoir des Notifications SMS de toutes les annonces de: ${typeCultureNoti}.
                #ELANGA STORE SMS Notification
                `
            });
            
        }catch(error){
            
        }

                setTimeout(()=>{window.location.replace("/");},3000);  
                
               
            }
                

               }catch(error){
            
            toast.error(` Echec: Vous avez déja un compte, choissisiez un numéro unique`)


            setTimeout(()=>{window.location.reload();},3000); 
        }


                }


                else if( response.status ==="FAILED"){

                    setMomoFailed(true);
                }


        }catch(error){
           toast.error(` Echec: Votre paiement n'a pas aboutie`)
        }

        
             }

               }catch(error){
            
            toast.error(` Echec: Vérifiez vos Informations`)

            setTimeout(()=>{window.location.reload();},3000); 
        }


    }



 else if(typeCultureNoti === "Aubergine"){

    try{
            const res1 = await axios.post("https://b-elanga-store.onrender.com/api/brouillonAcheteurs/",listNoti);
  
             if( res1.data){
            try{
                const res2 = await axios.post('https://b-elanga-store.onrender.com/api/reqtoPay',{partyId});

                  
                const response = res2.data;

                const momoTransaction = response.financialTransactionId;

                
                toast.success("Votre paiement est en cours...");

                if( response.status ==="SUCCESSFUL"){

                    setMomoSucess(true);

                     

                    try{
            const res = await axios.post('https://b-elanga-store.onrender.com/api/aubergine/',{
            lastNameNoti,firstNameNoti,typeCultureNoti,phoneNumberNoti,buyerAdresseNoti,momoTransaction
        }
                   );

            if( res.data){
                toast.success("Succès: Votre Abonnement a été enregistré ! ");

                try{
           await axios.post('https://b-elanga-store.onrender.com/api/sms',{
                 to:`+242055489004`,
                body:`Mr/Mrs ${lastNameNoti} ${firstNameNoti}, ELANGA STORE : vous notifie que Votre Abonnement en tant qu'Acheteur pour la categorie : ${typeCultureNoti},  pour une anneé a bien été enregistré. Vous allez maintenant recevoir des Notifications SMS de toutes les annonces de: ${typeCultureNoti}.
                #ELANGA STORE SMS Notification
                `
            });
            
        }catch(error){
            
        }

                setTimeout(()=>{window.location.replace("/");},3000);  
                
                 
            }
                

               }catch(error){
            
            toast.error(` Echec: Vous avez déja un compte, choissisiez un numéro unique`)


            setTimeout(()=>{window.location.reload();},3000); 
        }


                }


                else if( response.status ==="FAILED"){

                    setMomoFailed(true);
                }


        }catch(error){
           toast.error(` Echec: Votre paiement n'a pas aboutie`)
        }

        
             }

               }catch(error){
            
            toast.error(` Echec: Vérifiez vos Informations`)

            setTimeout(()=>{window.location.reload();},3000); 
        }


    }



    else if(typeCultureNoti === "Concombre"){
        

    try{
            const res1 = await axios.post("https://b-elanga-store.onrender.com/api/brouillonAcheteurs/",listNoti);
  
             if( res1.data){
            try{
                const res2 = await axios.post('https://b-elanga-store.onrender.com/api/reqtoPay',{partyId});

                  
                const response = res2.data;

                const momoTransaction = response.financialTransactionId;

                toast.success("Votre paiement est en cours...");

                if( response.status ==="SUCCESSFUL"){

                    setMomoSucess(true);


                    try{
            const res = await axios.post('https://b-elanga-store.onrender.com/api/concombre/',{
            lastNameNoti,firstNameNoti,typeCultureNoti,phoneNumberNoti,buyerAdresseNoti,momoTransaction
        }
                   );

            if( res.data){
                toast.success("Succès: Votre Abonnement a été enregistré ! ");

                try{
           await axios.post('https://b-elanga-store.onrender.com/api/sms',{
                 to:`+242055489004`,
                body:`Mr/Mrs ${lastNameNoti} ${firstNameNoti}, ELANGA STORE : vous notifie que Votre Abonnement en tant qu'Acheteur pour la categorie : ${typeCultureNoti},  pour une anneé a bien été enregistré. Vous allez maintenant recevoir des Notifications SMS de toutes les annonces de: ${typeCultureNoti}.
                #ELANGA STORE SMS Notification
                `
            });
            
        }catch(error){
            
        }

                setTimeout(()=>{window.location.replace("/");},3000);  
                
               
            }
                

               }catch(error){
            
            toast.error(` Echec: Vous avez déja un compte, choissisiez un numéro unique`)


            setTimeout(()=>{window.location.reload();},3000); 
        }




                }


                else if( response.status ==="FAILED"){

                    setMomoFailed(true);
                }


        }catch(error){
           toast.error(` Echec: Votre paiement n'a pas aboutie`)
        }

        
             }

               }catch(error){
            
            toast.error(` Echec: Vérifiez vos Informations`)

            setTimeout(()=>{window.location.reload();},3000); 
        }


    }



 else if(typeCultureNoti === "Poivron Vert"){

    try{
            const res1 = await axios.post("https://b-elanga-store.onrender.com/api/brouillonAcheteurs/",listNoti);
  
             if( res1.data){
            try{
                const res2 = await axios.post('https://b-elanga-store.onrender.com/api/reqtoPay',{partyId});

                  
                const response = res2.data;

                 const momoTransaction = response.financialTransactionId;


                
                toast.success("Votre paiement est en cours...");

                if( response.status ==="SUCCESSFUL"){

                    setMomoSucess(true);

                    try{
            const res = await axios.post('https://b-elanga-store.onrender.com/api/poivronVert/',{
            lastNameNoti,firstNameNoti,typeCultureNoti,phoneNumberNoti,buyerAdresseNoti,momoTransaction
        }
                   );

            if( res.data){
                toast.success("Succès: Votre Abonnement a été enregistré ! ");

                try{
           await axios.post('https://b-elanga-store.onrender.com/api/sms',{
                 to:`+242055489004`,
                body:`Mr/Mrs ${lastNameNoti} ${firstNameNoti}, ELANGA STORE : vous notifie que Votre Abonnement en tant qu'Acheteur pour la categorie : ${typeCultureNoti},  pour une anneé a bien été enregistré. Vous allez maintenant recevoir des Notifications SMS de toutes les annonces de: ${typeCultureNoti}.
                #ELANGA STORE SMS Notification
                `
            });
            
        }catch(error){
            
        }

                setTimeout(()=>{window.location.replace("/");},3000);  
                
               
            }
                

               }catch(error){
            
            toast.error(` Echec: Vous avez déja un compte, choissisiez un numéro unique`)


            setTimeout(()=>{window.location.reload();},3000); 
        }



                }


                else if( response.status ==="FAILED"){

                    setMomoFailed(true);
                }


        }catch(error){
           toast.error(` Echec: Votre paiement n'a pas aboutie`)
        }

        
             }

               }catch(error){
            
            toast.error(` Echec: Vérifiez vos Informations`)

            setTimeout(()=>{window.location.reload();},3000); 
        }


    }



 else if(typeCultureNoti === "Gingembre"){

    try{
            const res1 = await axios.post("https://b-elanga-store.onrender.com/api/brouillonAcheteurs/",listNoti);
  
             if( res1.data){
            try{
                const res2 = await axios.post('https://b-elanga-store.onrender.com/api/reqtoPay',{partyId});

                  
                const response = res2.data;

                const momoTransaction = response.financialTransactionId;

               
                toast.success("Votre paiement est en cours...");

                if( response.status ==="SUCCESSFUL"){

                    setMomoSucess(true);
                    

                    try{
            const res = await axios.post('https://b-elanga-store.onrender.com/api/gingembre/',{
            lastNameNoti,firstNameNoti,typeCultureNoti,phoneNumberNoti,buyerAdresseNoti,momoTransaction
        }
                   );

            if( res.data){
                toast.success("Succès: Votre Abonnement a été enregistré ! ");

                try{
           await axios.post('https://b-elanga-store.onrender.com/api/sms',{
                 to:`+242055489004`,
                body:`Mr/Mrs ${lastNameNoti} ${firstNameNoti}, ELANGA STORE : vous notifie que Votre Abonnement en tant qu'Acheteur pour la categorie : ${typeCultureNoti},  pour une anneé a bien été enregistré. Vous allez maintenant recevoir des Notifications SMS de toutes les annonces de: ${typeCultureNoti}.
                #ELANGA STORE SMS Notification
                `
            });
            
        }catch(error){
            
        }

                setTimeout(()=>{window.location.replace("/");},3000);  
                
               
            }
                

               }catch(error){
            
            toast.error(` Echec: Vous avez déja un compte, choissisiez un numéro unique`)


            setTimeout(()=>{window.location.reload();},3000); 
        }


                }


                else if( response.status ==="FAILED"){

                    setMomoFailed(true);
                }


        }catch(error){
           toast.error(` Echec: Votre paiement n'a pas aboutie`)
        }

        
             }

               }catch(error){
            
            toast.error(` Echec: Vérifiez vos Informations`)

            setTimeout(()=>{window.location.reload();},3000); 
        }


    }



           
    else if(typeCultureNoti === "Pomme de Terre"){
        // 

    try{
            const res1 = await axios.post("https://b-elanga-store.onrender.com/api/brouillonAcheteurs/",listNoti);
  
             if( res1.data){
            try{
                const res2 = await axios.post('https://b-elanga-store.onrender.com/api/reqtoPay',{partyId});

                  
                const response = res2.data;
                
                 const momoTransaction = response.financialTransactionId;

                
                toast.success("Votre paiement est en cours...");

                if( response.status ==="SUCCESSFUL"){

                    setMomoSucess(true);

                    

                    try{
            const res = await axios.post('https://b-elanga-store.onrender.com/api/pommedeTerre/',{
            lastNameNoti,firstNameNoti,typeCultureNoti,phoneNumberNoti,buyerAdresseNoti,momoTransaction
        }
                   );

            if( res.data){
                toast.success("Succès: Votre Abonnement a été enregistré ! ");

                try{
           await axios.post('https://b-elanga-store.onrender.com/api/sms',{
                 to:`+242055489004`,
                body:`Mr/Mrs ${lastNameNoti} ${firstNameNoti}, ELANGA STORE : vous notifie que Votre Abonnement en tant qu'Acheteur pour la categorie : ${typeCultureNoti},  pour une anneé a bien été enregistré. Vous allez maintenant recevoir des Notifications SMS de toutes les annonces de: ${typeCultureNoti}.
                #ELANGA STORE SMS Notification
                `
            });
            
        }catch(error){
            
        }

                setTimeout(()=>{window.location.replace("/");},3000);  
                
               
            }
                

               }catch(error){
            
            toast.error(` Echec: Vous avez déja un compte, choissisiez un numéro unique`)


            setTimeout(()=>{window.location.reload();},3000); 
        }




                }


                else if( response.status ==="FAILED"){

                    setMomoFailed(true);
                }


        }catch(error){
           toast.error(` Echec: Votre paiement n'a pas aboutie`)
        }

        
             }

               }catch(error){
            
            toast.error(` Echec: Vérifiez vos Informations`)

            setTimeout(()=>{window.location.reload();},3000); 
        }


    }



 else if(typeCultureNoti === "Patate Douce"){

    try{
            const res1 = await axios.post("https://b-elanga-store.onrender.com/api/brouillonAcheteurs/",listNoti);
  
             if( res1.data){
            try{
                const res2 = await axios.post('https://b-elanga-store.onrender.com/api/reqtoPay',{partyId});

                  
                const response = res2.data;

                const momoTransaction = response.financialTransactionId;

               
                toast.success("Votre paiement est en cours...");

                if( response.status ==="SUCCESSFUL"){

                    setMomoSucess(true);

                   
                    try{
            const res = await axios.post('https://b-elanga-store.onrender.com/api/patateDouce/',{
            lastNameNoti,firstNameNoti,typeCultureNoti,phoneNumberNoti,buyerAdresseNoti,momoTransaction
        }
                   );

            if( res.data){
                toast.success("Succès: Votre Abonnement a été enregistré ! ");

                try{
           await axios.post('https://b-elanga-store.onrender.com/api/sms',{
                 to:`+242055489004`,
                body:`Mr/Mrs ${lastNameNoti} ${firstNameNoti}, ELANGA STORE : vous notifie que Votre Abonnement en tant qu'Acheteur pour la categorie : ${typeCultureNoti},  pour une anneé a bien été enregistré. Vous allez maintenant recevoir des Notifications SMS de toutes les annonces de: ${typeCultureNoti}.
                #ELANGA STORE SMS Notification
                `
            });
            
        }catch(error){
            
        }

                setTimeout(()=>{window.location.replace("/");},3000);  
                
               
            }
                

               }catch(error){
            
            toast.error(` Echec: Vous avez déja un compte, choissisiez un numéro unique`)


            setTimeout(()=>{window.location.reload();},3000); 
        }

    


                }


                else if( response.status ==="FAILED"){

                    setMomoFailed(true);
                }


        }catch(error){
           toast.error(` Echec: Votre paiement n'a pas aboutie`)
        }

        
             }

               }catch(error){
            
            toast.error(` Echec: Vérifiez vos Informations`)

            setTimeout(()=>{window.location.reload();},3000); 
        }


    }



           
        // 
 else if(typeCultureNoti === "Maïs"){

    try{
            const res1 = await axios.post("https://b-elanga-store.onrender.com/api/brouillonAcheteurs/",listNoti);
  
             if( res1.data){
            try{
                const res2 = await axios.post('https://b-elanga-store.onrender.com/api/reqtoPay',{partyId});

                  
                const response = res2.data;

                const momoTransaction = response.financialTransactionId;

                
                toast.success("Votre paiement est en cours...");

                if( response.status ==="SUCCESSFUL"){

                    setMomoSucess(true);

                    try{
            const res = await axios.post('https://b-elanga-store.onrender.com/api/mais/',{
            lastNameNoti,firstNameNoti,typeCultureNoti,phoneNumberNoti,buyerAdresseNoti,momoTransaction
        }
                   );

            if( res.data){
                toast.success("Succès: Votre Abonnement a été enregistré ! ");

                try{
           await axios.post('https://b-elanga-store.onrender.com/api/sms',{
                 to:`+242055489004`,
                body:`Mr/Mrs ${lastNameNoti} ${firstNameNoti}, ELANGA STORE : vous notifie que Votre Abonnement en tant qu'Acheteur pour la categorie : ${typeCultureNoti},  pour une anneé a bien été enregistré. Vous allez maintenant recevoir des Notifications SMS de toutes les annonces de: ${typeCultureNoti}.
                #ELANGA STORE SMS Notification
                `
            });
            
        }catch(error){
            
        }

                setTimeout(()=>{window.location.replace("/");},3000);  
                
               
            }
                

               }catch(error){
            
            toast.error(` Echec: Vous avez déja un compte, choissisiez un numéro unique`)


            setTimeout(()=>{window.location.reload();},3000); 
        }




                }


                else if( response.status ==="FAILED"){

                    setMomoFailed(true);
                }


        }catch(error){
           toast.error(` Echec: Votre paiement n'a pas aboutie`)
        }

        
             }

               }catch(error){
            
            toast.error(` Echec: Vérifiez vos Informations`)

            setTimeout(()=>{window.location.reload();},3000); 
        }


    }



           
        
 else if(typeCultureNoti === "Pois d'Angole"){

    try{
            const res1 = await axios.post("https://b-elanga-store.onrender.com/api/brouillonAcheteurs/",listNoti);
  
             if( res1.data){
            try{
                const res2 = await axios.post('https://b-elanga-store.onrender.com/api/reqtoPay',{partyId});

                  
                const response = res2.data;

                const momoTransaction = response.financialTransactionId;

               
                toast.success("Votre paiement est en cours...");

                if( response.status ==="SUCCESSFUL"){

                    setMomoSucess(true);

                   

                    try{
            const res = await axios.post('https://b-elanga-store.onrender.com/api/poisdAngole/',{
            lastNameNoti,firstNameNoti,typeCultureNoti,phoneNumberNoti,buyerAdresseNoti,momoTransaction
        }
                   );

            if( res.data){
                toast.success("Succès: Votre Abonnement a été enregistré ! ");

                try{
           await axios.post('https://b-elanga-store.onrender.com/api/sms',{
                 to:`+242055489004`,
                body:`Mr/Mrs ${lastNameNoti} ${firstNameNoti}, ELANGA STORE : vous notifie que Votre Abonnement en tant qu'Acheteur pour la categorie : ${typeCultureNoti},  pour une anneé a bien été enregistré. Vous allez maintenant recevoir des Notifications SMS de toutes les annonces de: ${typeCultureNoti}.
                #ELANGA STORE SMS Notification
                `
            });
            
        }catch(error){
            
        }

                setTimeout(()=>{window.location.replace("/");},3000);  
                
               
            }
                

               }catch(error){
            
            toast.error(` Echec: Vous avez déja un compte, choissisiez un numéro unique`)


            setTimeout(()=>{window.location.reload();},3000); 
        }

    


                }


                else if( response.status ==="FAILED"){

                    setMomoFailed(true);
                }


        }catch(error){
           toast.error(` Echec: Votre paiement n'a pas aboutie`)
        }

        
             }

               }catch(error){
            
            toast.error(` Echec: Vérifiez vos Informations`)

            setTimeout(()=>{window.location.reload();},3000); 
        }


    }



           
        // 
 else if(typeCultureNoti === "Carotte"){

    try{
            const res1 = await axios.post("https://b-elanga-store.onrender.com/api/brouillonAcheteurs/",listNoti);
  
             if( res1.data){
            try{
                const res2 = await axios.post('https://b-elanga-store.onrender.com/api/reqtoPay',{partyId});

                  
                const response = res2.data;

                const momoTransaction = response.financialTransactionId;

                
                toast.success("Votre paiement est en cours...");

                if( response.status ==="SUCCESSFUL"){

                    setMomoSucess(true);

                  

                    try{
            const res = await axios.post('https://b-elanga-store.onrender.com/api/carotte/',{
            lastNameNoti,firstNameNoti,typeCultureNoti,phoneNumberNoti,buyerAdresseNoti,momoTransaction
        }
                   );

            if( res.data){
                toast.success("Succès: Votre Abonnement a été enregistré ! ");

                try{
           await axios.post('https://b-elanga-store.onrender.com/api/sms',{
                 to:`+242055489004`,
                body:`Mr/Mrs ${lastNameNoti} ${firstNameNoti}, ELANGA STORE : vous notifie que Votre Abonnement en tant qu'Acheteur pour la categorie : ${typeCultureNoti},  pour une anneé a bien été enregistré. Vous allez maintenant recevoir des Notifications SMS de toutes les annonces de: ${typeCultureNoti}.
                #ELANGA STORE SMS Notification
                `
            });
            
        }catch(error){
            
        }

                setTimeout(()=>{window.location.replace("/");},3000);  
                
               
            }
                

               }catch(error){
            
            toast.error(` Echec: Vous avez déja un compte, choissisiez un numéro unique`)


            setTimeout(()=>{window.location.reload();},3000); 
        }

    


                }


                else if( response.status ==="FAILED"){

                    setMomoFailed(true);
                }


        }catch(error){
           toast.error(` Echec: Votre paiement n'a pas aboutie`)
        }

        
             }

               }catch(error){
            
            toast.error(` Echec: Vérifiez vos Informations`)

            setTimeout(()=>{window.location.reload();},3000); 
        }


    }


      
        // 

         else if(typeCultureNoti === "Soja"){
try{
            const res1 = await axios.post("https://b-elanga-store.onrender.com/api/brouillonAcheteurs/",listNoti);
  
             if( res1.data){
            try{
                const res2 = await axios.post('https://b-elanga-store.onrender.com/api/reqtoPay',{partyId});

                  
                const response = res2.data;

                const momoTransaction = response.financialTransactionId;


                
                toast.success("Votre paiement est en cours...");

                if( response.status ==="SUCCESSFUL"){

                    setMomoSucess(true);

                    

                    try{
            const res = await axios.post('https://b-elanga-store.onrender.com/api/soja/',{
            lastNameNoti,firstNameNoti,typeCultureNoti,phoneNumberNoti,buyerAdresseNoti,momoTransaction
        }
                   );

            if( res.data){
                toast.success("Succès: Votre Abonnement a été enregistré ! ");

                try{
           await axios.post('https://b-elanga-store.onrender.com/api/sms',{
                 to:`+242055489004`,
                body:`Mr/Mrs ${lastNameNoti} ${firstNameNoti}, ELANGA STORE : vous notifie que Votre Abonnement en tant qu'Acheteur pour la categorie : ${typeCultureNoti},  pour une anneé a bien été enregistré. Vous allez maintenant recevoir des Notifications SMS de toutes les annonces de: ${typeCultureNoti}.
                #ELANGA STORE SMS Notification
                `
            });
            
        }catch(error){
            
        }

                setTimeout(()=>{window.location.replace("/");},3000);  
                
               
            }
                

               }catch(error){
            
            toast.error(` Echec: Vous avez déja un compte, choissisiez un numéro unique`)


            setTimeout(()=>{window.location.reload();},3000); 
        }

    


                }


                else if( response.status ==="FAILED"){

                    setMomoFailed(true);
                }


        }catch(error){
           toast.error(` Echec: Votre paiement n'a pas aboutie`)
        }

        
             }

               }catch(error){
            
            toast.error(` Echec: Vérifiez vos Informations`)

            setTimeout(()=>{window.location.reload();},3000); 
        }


    }


      
    // 

     else if(typeCultureNoti === "Sorgho"){

    try{
            const res1 = await axios.post("https://b-elanga-store.onrender.com/api/brouillonAcheteurs/",listNoti);
  
             if( res1.data){
            try{
                const res2 = await axios.post('https://b-elanga-store.onrender.com/api/reqtoPay',{partyId});

                  
                const response = res2.data;

                const momoTransaction = response.financialTransactionId;

                
                toast.success("Votre paiement est en cours...");

                if( response.status ==="SUCCESSFUL"){

                    setMomoSucess(true);

                    try{
            const res = await axios.post('https://b-elanga-store.onrender.com/api/sorgho/',{
            lastNameNoti,firstNameNoti,typeCultureNoti,phoneNumberNoti,buyerAdresseNoti,momoTransaction
        }
                   );

            if( res.data){
                toast.success("Succès: Votre Abonnement a été enregistré ! ");

                try{
           await axios.post('https://b-elanga-store.onrender.com/api/sms',{
                 to:`+242055489004`,
                body:`Mr/Mrs ${lastNameNoti} ${firstNameNoti}, ELANGA STORE : vous notifie que Votre Abonnement en tant qu'Acheteur pour la categorie : ${typeCultureNoti},  pour une anneé a bien été enregistré. Vous allez maintenant recevoir des Notifications SMS de toutes les annonces de: ${typeCultureNoti}.
                #ELANGA STORE SMS Notification
                `
            });
            
        }catch(error){
            
        }

                setTimeout(()=>{window.location.replace("/");},3000);  
                
               
            }
                

               }catch(error){
            
            toast.error(` Echec: Vous avez déja un compte, choissisiez un numéro unique`)


            setTimeout(()=>{window.location.reload();},3000); 
        }

    


                }


                else if( response.status ==="FAILED"){

                    setMomoFailed(true);
                }


        }catch(error){
           toast.error(` Echec: Votre paiement n'a pas aboutie`)
        }

        
             }

               }catch(error){
            
            toast.error(` Echec: Vérifiez vos Informations`)

            setTimeout(()=>{window.location.reload();},3000); 
        }


    }

    

    }



    const tomate =[
        "1.jpg","2.jpg","3.jpg"
    ]

    const [imgt,setImgt]=useState(false);

    const randomTomates = ()=>{

    setImgt(true);
}

    const randomTomates2 = Math.floor(Math.random()*tomate.length);
   
    useEffect(() => {
         window.scrollTo(0,0);
       
    }, [])



    

   
    return (
        <div>
         <Header/>
                   <div>
                    <div className="singlePostO">
           
            <div className="singlePostIn">
                <div className="infosSingle">

            <div className="infosClient">
            
            <form  className='reservation'
            onSubmit={addingSmsNoti} 
            
            autoComplete="off"
            >
                

                <div className='reservHeading'>

                <h1> Abonnez-vous en tant qu'Acheteur</h1>
        
 
                </div>

                 <div className='nameIn'>
                    
                <input id="nameC" type="text" placeholder=' Ecrivez votre Nom' required 
                onChange={e=>{setLastNameNoti(e.target.value)}}

                onInvalid={e=>e.target.setCustomValidity('Veuillez Entrer Votre Nom svp')}

               onInput={e=>e.target.setCustomValidity('')}

            />
                </div>

                 <div className='nameIn'>
                    
                <input id="nameC" type="text" placeholder=' Ecrivez votre Prenom' required 
                onChange={e=>{setFirstNameNoti(e.target.value)}}

                onInvalid={e=>e.target.setCustomValidity('Veuillez Entrer votre Prenom')}
                onInput={e=>e.target.setCustomValidity('')}
            />
                </div>

                <div className='cityIn'>
                
                <select name="Ville" id="villeIn"
                value={typeCultureNoti}
            onChange={e=>{setTypeCultureNoti(e.target.value)}}  
            required
            onInvalid={e=>e.target.setCustomValidity('Veuillez choisir le Type de Culture')}
            onInput={e=>e.target.setCustomValidity('')}
                
                >

               <option value="Type de Culture">Type de Culture</option>
                <option value="Tubercules de Manioc">Tubercules de Manioc</option>
                <option value="Banane Plantain">Banane Plantain</option>
                <option value="Tomates">Tomates</option>
                <option value="Haricot">Haricot</option>
                <option value="Igname">Igname</option>
                <option value="Arachide">Arachide</option>
                <option value="Oignons">Oignons</option>
                <option value="Ciboules">Ciboules</option>
                <option value="Piment">Piment</option>
                <option value="Aubergine">Aubergine</option>
                <option value="Concombre">Concombre</option>
                <option value="Poivron Vert">Poivron Vert</option>
                <option value="Gingembre">Gingembre</option>
                <option value="Pomme de Terre">Pomme de Terre</option>
                <option value="Patate Douce">Patate Douce</option>
                <option value="Maïs">Maïs</option>
                <option value="Pois d'Angole">Pois d'Angole</option>
                <option value="Carotte">Carotte</option>
                <option value="Soja">Soja</option>
                <option value="Sorgho">Sorgho</option>
                
                

                </select>

                </div>


                <div className='numberC'>
                <input type="number"  name="" id="numberCl" 
                    placeholder=' Votre Numéro ex:(06xxxxxxx)'
                    inputMode="decimal"
                   required
                  
                    min="1"
                    onChange={e=>{setPhoneNumberNoti(e.target.value)}}

                    onInvalid={e=>e.target.setCustomValidity('Veuillez remplir ce champ comme 06 XXX XX XX')}

                    onInput={e=>e.target.setCustomValidity('')}
                />
            </div>

            
                   
                <div className='nameIn'>
                    
                <input id="nameC" type="text" placeholder=' Ecrivez votre Ville ou Vilage' required 
                onChange={e=>{setBuyerAdresseNoti(e.target.value)}}

                onInvalid={e=>e.target.setCustomValidity('Veuillez Entrer votre  votre Ville ou Vilage')}
                onInput={e=>e.target.setCustomValidity('')}
            />
                </div>

            {/* <div className='btnReserver'>

            <button id='btn-reserv' type="submit"> <h3>
            Ajouter
            </h3> </button>

          

            </div> */}

            <div className='payO'>

            

        <div className='payIn'>
<div>
                <h3> Veuillez confirmer votre Abonnement / ans en payant la somme de <span id='protext3'>3.000 FCFA</span> </h3>
            </div>

        <div className='imgPay' ><img src="mtnmomo.svg" id='imgPay' alt="" srcset="" /></div>
        <div>
        <ul>

        <li>
        <span id='asterix'>*</span>
                    <input type="number"  name="" id="numberCl" 
                    placeholder=' Votre Numéro ex:(06xxxxxxx)'
                    inputMode="decimal"
                   required
                  
                    min="1"
                    onChange={e=>{setPartyId(e.target.value)}}

                    onInvalid={e=>e.target.setCustomValidity('Veuillez remplir ce champ comme 06 XXX XX XX')}

                    onInput={e=>e.target.setCustomValidity('')}
                />
            </li>

            <li>
                 <button id='btn-pay'
            name='nameS' type="submit">
           Confimer 
            </button>   

            </li>
            </ul>

        </div>

        <div className='payOutput'>

             {
            momoFailed &&(
                 <h3>Echec : Votre paiement n'a pas aboutie</h3>
            )
        } 

       

         {
            momoSucess &&(
                 <h3>Votre paiement a bien ete effectue</h3>
            )
        }
       

        </div>

        </div>
            
        </div>


            </form>

            </div>

                </div>

            </div>
  

        </div>

                </div>  
     
             <ToastContainer/>
            <Footer/>
        </div>
    )
}

export default Acheteurs
