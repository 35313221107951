import React, { useContext, useEffect, useState } from 'react'
import Footer from './Footer'
import Header from './Header'

import { FaCheckCircle } from 'react-icons/fa';
import axios from "axios"
import { Context } from '../context/Context'
import { ToastContainer, toast } from 'react-toastify';

const Publish = () => {
  

    useEffect(() => {
     window.scrollTo(0,0);

     
    }, [])

     const { user } = useContext(Context)
   

const [submit,setSubmit]=useState(false);

    // Post Annonce 
    // const [lastName, setLastName] = useState("")
    // const [firstName, setFirstName] = useState("")
    // const [contactFarmer, setContactFarmer] = useState("")
    const [farmingAdresse, setFarmingAdresse] = useState("")
    const [typeCulture, setTypeCulture] = useState("")
    const [priceCulture, setPriceCulture] = useState("")
    const [quantityCulture, setQuantityCulture] = useState("")
    
    
    const [file, setFile] = useState(null);
    


const lastName = user.userLastName
 const firstName = user.userFirstName
 const contactFarmer = user.username

    
    const handleAnonce = async (e)=>{
        
        e.preventDefault();

        const newPost ={
           
 lastName,firstName,contactFarmer,farmingAdresse,typeCulture,priceCulture,quantityCulture

        }

       
        try{
                const res = await axios.post('https://b-elanga-store.onrender.com/api/post/',newPost
                   );

                   setSubmit(true);

 toast.success("L'annonce a été postée")
 
                setTimeout(()=>{window.location.reload();},3000);                   
 
        }catch(error){
            
            toast.error(` Echec: L'annonce n'a pas été postée`)

            setTimeout(()=>{window.location.reload();},3000); 
        }


       if(typeCulture === "Tomates"){
                
            tomateList.map(number=> {

  try{
           axios.post('https://b-elanga-store.onrender.com/api/sms',{
                
                to:`+242${number.phoneNumberNoti}`,
                body:`ELANGA STORE : vous notifie que Mr/Mrs ${lastName} ${firstName} met à votre disposition ${quantityCulture} kilos de ${typeCulture}, à ${priceCulture} FCFA / kg  dans la ville de: ${farmingAdresse} , Veuillez directement le/la contacter par le Numéro: ${contactFarmer} pour plus d'Informations.
                
                ELANGA STORE SMS Notification
                ` 
            });

           toast.success(`L'Annonce a été envoye aux Abonnés de : ${typeCulture}`)
 
                setTimeout(()=>{window.location.replace("/");},3000);                   
 
        }catch(error){
            
            toast.error(` Echec: L'Annonce n'a pas été envoye aux Abonnés`)

            setTimeout(()=>{window.location.reload();},3000); 
        }
    })

     }

            else if(typeCulture === "Arachide"){
                
            arachideList.map(number=> {

  try{
           axios.post('https://b-elanga-store.onrender.com/api/sms',{
                 to:`+242${number.phoneNumberNoti}`,
                body:`ELANGA STORE : vous notifie que Mr/Mrs ${lastName} ${firstName} met à votre disposition ${quantityCulture} kilos de ${typeCulture}, à ${priceCulture} FCFA / kg  dans la ville de: ${farmingAdresse} , Veuillez directement le/la contacter par le Numéro: ${contactFarmer} pour plus d'Informations.
                
                ELANGA STORE SMS Notification
                `
            });
            
      toast.success(`L'Annonce a été envoye aux Abonnés de : ${typeCulture}`)
 
                setTimeout(()=>{window.location.replace("/");},3000);                   
 
        }catch(error){
            
            toast.error(` Echec: L'Annonce n'a pas été envoye aux Abonnés`)

            setTimeout(()=>{window.location.reload();},3000); 
        }

    })

     }
            else if(typeCulture === "Tubercules de Manioc"){
                
            TuberculesdeManioc.map(number=> {

  try{
           axios.post('https://b-elanga-store.onrender.com/api/sms',{
                 to:`+242${number.phoneNumberNoti}`,
                body:`ELANGA STORE : vous notifie que Mr/Mrs ${lastName} ${firstName} met à votre disposition ${quantityCulture} kilos de ${typeCulture}, à ${priceCulture} FCFA / kg  dans la ville de: ${farmingAdresse} , Veuillez directement le/la contacter par le Numéro: ${contactFarmer} pour plus d'Informations.
                
                ELANGA STORE SMS Notification
                `
            });
            
       toast.success(`L'Annonce a été envoye aux Abonnés de : ${typeCulture}`)   
 
                setTimeout(()=>{window.location.replace("/");},3000);                   
 
        }catch(error){
            
            toast.error(` Echec: L'Annonce n'a pas été envoye aux Abonnés`)

            setTimeout(()=>{window.location.reload();},3000); 
        }

    })

     }
            else if(typeCulture === "Banane Plantain"){
                
            BananePlantain.map(number=> {

  try{
           axios.post('https://b-elanga-store.onrender.com/api/sms',{
                to:`+242${number.phoneNumberNoti}`,
                body:`ELANGA STORE : vous notifie que Mr/Mrs ${lastName} ${firstName} met à votre disposition ${quantityCulture} kilos de ${typeCulture}, à ${priceCulture} FCFA / kg  dans la ville de: ${farmingAdresse} , Veuillez directement le/la contacter par le Numéro: ${contactFarmer} pour plus d'Informations.
                
                ELANGA STORE SMS Notification
                `                });
            
        toast.success(`L'Annonce a été envoye aux Abonnés de : ${typeCulture}`)
 
                setTimeout(()=>{window.location.replace("/");},3000);                   
 
        }catch(error){
            
            toast.error(` Echec: L'Annonce n'a pas été envoye aux Abonnés`)

            setTimeout(()=>{window.location.reload();},3000); 
        }

    })

     }
            else if(typeCulture === "Haricot"){
                
            Haricot.map(number=> {

  try{
           axios.post('https://b-elanga-store.onrender.com/api/sms',{
                 to:`+242${number.phoneNumberNoti}`,
                body:`ELANGA STORE : vous notifie que Mr/Mrs ${lastName} ${firstName} met à votre disposition ${quantityCulture} kilos de ${typeCulture}, à ${priceCulture} FCFA / kg  dans la ville de: ${farmingAdresse} , Veuillez directement le/la contacter par le Numéro: ${contactFarmer} pour plus d'Informations.
                
                ELANGA STORE SMS Notification
                `
            });
            
       toast.success(`L'Annonce a été envoye aux Abonnés de : ${typeCulture}`)

                setTimeout(()=>{window.location.replace("/");},3000);                   
 
        }catch(error){
            
            toast.error(` Echec: L'Annonce n'a pas été envoye aux Abonnés`)

            setTimeout(()=>{window.location.reload();},3000); 
        }

    })

     }
            else if(typeCulture === "Igname"){
                
            Igname.map(number=> {

  try{
           axios.post('https://b-elanga-store.onrender.com/api/sms',{
                 to:`+242${number.phoneNumberNoti}`,
                body:`ELANGA STORE : vous notifie que Mr/Mrs ${lastName} ${firstName} met à votre disposition ${quantityCulture} kilos de ${typeCulture}, à ${priceCulture} FCFA / kg  dans la ville de: ${farmingAdresse} , Veuillez directement le/la contacter par le Numéro: ${contactFarmer} pour plus d'Informations.
                ELANGA STORE SMS Notification
                `            });
            
       toast.success(`L'Annonce a été envoye aux Abonnés de : ${typeCulture}`)
 
                setTimeout(()=>{window.location.replace("/");},3000);                   
 
        }catch(error){
            
            toast.error(` Echec: L'Annonce n'a pas été envoye aux Abonnés`)

            setTimeout(()=>{window.location.reload();},3000); 
        }

    })

     }
            else if(typeCulture === "Oignons"){
                
            Oignons.map(number=> {

  try{
           axios.post('https://b-elanga-store.onrender.com/api/sms',{
                 to:`+242${number.phoneNumberNoti}`,
                body:`ELANGA STORE : vous notifie que Mr/Mrs ${lastName} ${firstName} met à votre disposition ${quantityCulture} kilos de ${typeCulture}, à ${priceCulture} FCFA / kg  dans la ville de: ${farmingAdresse} , Veuillez directement le/la contacter par le Numéro: ${contactFarmer} pour plus d'Informations.
                
                ELANGA STORE SMS Notification
                `   });
            
        toast.success(`L'Annonce a été envoye aux Abonnés de : ${typeCulture}`)
 
                setTimeout(()=>{window.location.replace("/");},3000);                   
 
        }catch(error){
            
            toast.error(` Echec: L'Annonce n'a pas été envoye aux Abonnés`)

            setTimeout(()=>{window.location.reload();},3000); 
        }

    })

     }
            else if(typeCulture === "Ciboules"){
                
            Ciboules.map(number=> {

  try{
           axios.post('https://b-elanga-store.onrender.com/api/sms',{
                 to:`+242${number.phoneNumberNoti}`,
                body:`ELANGA STORE : vous notifie que Mr/Mrs ${lastName} ${firstName} met à votre disposition ${quantityCulture} kilos de ${typeCulture}, à ${priceCulture} FCFA / kg  dans la ville de: ${farmingAdresse} , Veuillez directement le/la contacter par le Numéro: ${contactFarmer} pour plus d'Informations.
                
                ELANGA STORE SMS Notification
                ` });
            
       toast.success(`L'Annonce a été envoye aux Abonnés de : ${typeCulture}`)
 
                setTimeout(()=>{window.location.replace("/");},3000);                   
 
        }catch(error){
            
            toast.error(` Echec: L'Annonce n'a pas été envoye aux Abonnés`)

            setTimeout(()=>{window.location.reload();},3000); 
        }

    })

     }
            else if(typeCulture === "Piment"){
                
            Piment.map(number=> {

  try{
           axios.post('https://b-elanga-store.onrender.com/api/sms',{
                to:`+242${number.phoneNumberNoti}`,
                body:`ELANGA STORE : vous notifie que Mr/Mrs ${lastName} ${firstName} met à votre disposition ${quantityCulture} kilos de ${typeCulture}, à ${priceCulture} FCFA / kg  dans la ville de: ${farmingAdresse} , Veuillez directement le/la contacter par le Numéro: ${contactFarmer} pour plus d'Informations.
                
                ELANGA STORE SMS Notification
                `   });
            
       toast.success(`L'Annonce a été envoye aux Abonnés de : ${typeCulture}`)
 
                setTimeout(()=>{window.location.replace("/");},3000);                   
 
        }catch(error){
            
            toast.error(` Echec: L'Annonce n'a pas été envoye aux Abonnés`)

            setTimeout(()=>{window.location.reload();},3000); 
        }

    })

     }
            else if(typeCulture === "Aubergine"){
                
            Aubergine.map(number=> {

  try{
           axios.post('https://b-elanga-store.onrender.com/api/sms',{
                to:`+242${number.phoneNumberNoti}`,
                body:`ELANGA STORE : vous notifie que Mr/Mrs ${lastName} ${firstName} met à votre disposition ${quantityCulture} kilos de ${typeCulture}, à ${priceCulture} FCFA / kg  dans la ville de: ${farmingAdresse} , Veuillez directement le/la contacter par le Numéro: ${contactFarmer} pour plus d'Informations.
                
                ELANGA STORE SMS Notification
                `   });
            
      toast.success(`L'Annonce a été envoye aux Abonnés de : ${typeCulture}`)
 
                setTimeout(()=>{window.location.replace("/");},3000);                   
 
        }catch(error){
            
            toast.error(` Echec: L'Annonce n'a pas été envoye aux Abonnés`)

            setTimeout(()=>{window.location.reload();},3000); 
        }

    })

     }
            else if(typeCulture === "Concombre"){
                
            Concombre.map(number=> {

  try{
           axios.post('https://b-elanga-store.onrender.com/api/sms',{
                 to:`+242${number.phoneNumberNoti}`,
                body:`ELANGA STORE : vous notifie que Mr/Mrs ${lastName} ${firstName} met à votre disposition ${quantityCulture} kilos de ${typeCulture}, à ${priceCulture} FCFA / kg  dans la ville de: ${farmingAdresse} , Veuillez directement le/la contacter par le Numéro: ${contactFarmer} pour plus d'Informations.
                
                ELANGA STORE SMS Notification
                ` });
            
        toast.success(`L'Annonce a été envoye aux Abonnés de : ${typeCulture}`)
 
                setTimeout(()=>{window.location.replace("/");},3000);                   
 
        }catch(error){
            
            toast.error(` Echec: L'Annonce n'a pas été envoye aux Abonnés`)

            setTimeout(()=>{window.location.reload();},3000); 
        }

    })

     }
            else if(typeCulture === "Poivron Vert"){
                
            PoivronVert.map(number=> {

  try{
           axios.post('https://b-elanga-store.onrender.com/api/sms',{
                 to:`+242${number.phoneNumberNoti}`,
                body:`ELANGA STORE : vous notifie que Mr/Mrs ${lastName} ${firstName} met à votre disposition ${quantityCulture} kilos de ${typeCulture}, à ${priceCulture} FCFA / kg  dans la ville de: ${farmingAdresse} , Veuillez directement le/la contacter par le Numéro: ${contactFarmer} pour plus d'Informations.
                
                ELANGA STORE SMS Notification
                `  });
            
        toast.success(`L'Annonce a été envoye aux Abonnés de : ${typeCulture}`)
 
                setTimeout(()=>{window.location.replace("/");},3000);                   
 
        }catch(error){
            
            toast.error(` Echec: L'Annonce n'a pas été envoye aux Abonnés`)

            setTimeout(()=>{window.location.reload();},3000); 
        }

    })

     }
            else if(typeCulture === "Gingembre"){
                
            Gingembre.map(number=> {

  try{
           axios.post('https://b-elanga-store.onrender.com/api/sms',{
                 to:`+242${number.phoneNumberNoti}`,
                body:`ELANGA STORE : vous notifie que Mr/Mrs ${lastName} ${firstName} met à votre disposition ${quantityCulture} kilos de ${typeCulture}, à ${priceCulture} FCFA / kg  dans la ville de: ${farmingAdresse} , Veuillez directement le/la contacter par le Numéro: ${contactFarmer} pour plus d'Informations.
                
                ELANGA STORE SMS Notification
                ` });
            
       toast.success(`L'Annonce a été envoye aux Abonnés de : ${typeCulture}`)
 
                setTimeout(()=>{window.location.replace("/");},3000);                   
 
        }catch(error){
            
            toast.error(` Echec: L'Annonce n'a pas été envoye aux Abonnés`)

            setTimeout(()=>{window.location.reload();},3000); 
        }

    })

     }
            else if(typeCulture === "Pomme de Terre"){
                
            PommedeTerre.map(number=> {

  try{
           axios.post('https://b-elanga-store.onrender.com/api/sms',{
                 to:`+242${number.phoneNumberNoti}`,
                body:`ELANGA STORE : vous notifie que Mr/Mrs ${lastName} ${firstName} met à votre disposition ${quantityCulture} kilos de ${typeCulture}, à ${priceCulture} FCFA / kg  dans la ville de: ${farmingAdresse} , Veuillez directement le/la contacter par le Numéro: ${contactFarmer} pour plus d'Informations.
                
                ELANGA STORE SMS Notification
                ` });
            
       toast.success(`L'Annonce a été envoye aux Abonnés de : ${typeCulture}`)
 
                setTimeout(()=>{window.location.replace("/");},3000);                   
 
        }catch(error){
            
            toast.error(` Echec: L'Annonce n'a pas été envoye aux Abonnés`)

            setTimeout(()=>{window.location.reload();},3000); 
        }

    })

     }
            else if(typeCulture === "Patate Douce"){
                
            PatateDouce.map(number=> {

  try{
           axios.post('https://b-elanga-store.onrender.com/api/sms',{
                 to:`+242${number.phoneNumberNoti}`,
                body:`ELANGA STORE : vous notifie que Mr/Mrs ${lastName} ${firstName} met à votre disposition ${quantityCulture} kilos de ${typeCulture}, à ${priceCulture} FCFA / kg  dans la ville de: ${farmingAdresse} , Veuillez directement le/la contacter par le Numéro: ${contactFarmer} pour plus d'Informations.
                
                ELANGA STORE SMS Notification
                ` });
            
        toast.success(`L'Annonce a été envoye aux Abonnés de : ${typeCulture}`)
 
                setTimeout(()=>{window.location.replace("/");},3000);                   
 
        }catch(error){
            
            toast.error(` Echec: L'Annonce n'a pas été envoye aux Abonnés`)

            setTimeout(()=>{window.location.reload();},3000); 
        }

    })

     }
            else if(typeCulture === "Maïs"){
                
            Maïs.map(number=> {

  try{
           axios.post('https://b-elanga-store.onrender.com/api/sms',{
                 to:`+242${number.phoneNumberNoti}`,
                body:`ELANGA STORE : vous notifie que Mr/Mrs ${lastName} ${firstName} met à votre disposition ${quantityCulture} kilos de ${typeCulture}, à ${priceCulture} FCFA / kg  dans la ville de: ${farmingAdresse} , Veuillez directement le/la contacter par le Numéro: ${contactFarmer} pour plus d'Informations.
                
                ELANGA STORE SMS Notification
                ` });
            
      toast.success(`L'Annonce a été envoye aux Abonnés de : ${typeCulture}`)
 
                setTimeout(()=>{window.location.replace("/");},3000);                   
 
        }catch(error){
            
            toast.error(` Echec: L'Annonce n'a pas été envoye aux Abonnés`)

            setTimeout(()=>{window.location.reload();},3000); 
        }

    })

     }
            else if(typeCulture === "Pois d'Angole"){
                
            PoisdAngole.map(number=> {

  try{
           axios.post('https://b-elanga-store.onrender.com/api/sms',{
                 to:`+242${number.phoneNumberNoti}`,
                body:`ELANGA STORE : vous notifie que Mr/Mrs ${lastName} ${firstName} met à votre disposition ${quantityCulture} kilos de ${typeCulture}, à ${priceCulture} FCFA / kg  dans la ville de: ${farmingAdresse} , Veuillez directement le/la contacter par le Numéro: ${contactFarmer} pour plus d'Informations.
                
                ELANGA STORE SMS Notification
                `  });
            
      toast.success(`L'Annonce a été envoye aux Abonnés de : ${typeCulture}`)
 
                setTimeout(()=>{window.location.replace("/");},3000);                   
 
        }catch(error){
            
            toast.error(` Echec: L'Annonce n'a pas été envoye aux Abonnés`)

            setTimeout(()=>{window.location.reload();},3000); 
        }

    })

     }
            else if(typeCulture === "Carotte"){
                
            Carotte.map(number=> {

  try{
           axios.post('https://b-elanga-store.onrender.com/api/sms',{
                 to:`+242${number.phoneNumberNoti}`,
                body:`ELANGA STORE : vous notifie que Mr/Mrs ${lastName} ${firstName} met à votre disposition ${quantityCulture} kilos de ${typeCulture}, à ${priceCulture} FCFA / kg  dans la ville de: ${farmingAdresse} , Veuillez directement le/la contacter par le Numéro: ${contactFarmer} pour plus d'Informations.
                
                ELANGA STORE SMS Notification
                `  });
            
       toast.success(`L'Annonce a été envoye aux Abonnés de : ${typeCulture}`)
 
                setTimeout(()=>{window.location.replace("/");},3000);                   
 
        }catch(error){
            
            toast.error(` Echec: L'Annonce n'a pas été envoye aux Abonnés`)

            setTimeout(()=>{window.location.reload();},3000); 
        }

    })

     }


            else if(typeCulture === "Soja"){
                
            Carotte.map(number=> {

  try{
           axios.post('https://b-elanga-store.onrender.com/api/sms',{
                 to:`+242${number.phoneNumberNoti}`,
                body:`ELANGA STORE : vous notifie que Mr/Mrs ${lastName} ${firstName} met à votre disposition ${quantityCulture} kilos de ${typeCulture}, à ${priceCulture} FCFA / kg  dans la ville de: ${farmingAdresse} , Veuillez directement le/la contacter par le Numéro: ${contactFarmer} pour plus d'Informations.
                
                ELANGA STORE SMS Notification
                `  });
            
       toast.success(`L'Annonce a été envoye aux Abonnés de : ${typeCulture}`)
 
                setTimeout(()=>{window.location.replace("/");},3000);                   
 
        }catch(error){
            
            toast.error(` Echec: L'Annonce n'a pas été envoye aux Abonnés`)

            setTimeout(()=>{window.location.reload();},3000); 
        }

    })

     }


            else if(typeCulture === "Sorgho"){
                
            Carotte.map(number=> {

  try{
           axios.post('https://b-elanga-store.onrender.com/api/sms',{
                 to:`+242${number.phoneNumberNoti}`,
                body:`ELANGA STORE : vous notifie que Mr/Mrs ${lastName} ${firstName} met à votre disposition ${quantityCulture} kilos de ${typeCulture}, à ${priceCulture} FCFA / kg  dans la ville de: ${farmingAdresse} , Veuillez directement le/la contacter par le Numéro: ${contactFarmer} pour plus d'Informations.
                
                ELANGA STORE SMS Notification
                `  });
            
       toast.success(`L'Annonce a été envoye aux Abonnés de : ${typeCulture}`)
      
                setTimeout(()=>{window.location.replace("/");},3000);                   
 
        }catch(error){
            
            toast.error(` Echec: L'Annonce n'a pas été envoye aux Abonnés`)

            setTimeout(()=>{window.location.reload();},3000); 
        }

    })


    }

}
    

// Getting All the sms Notification

        const [tomateList,setTomateList] = useState([]);
        const [arachideList,setArachideList] = useState([]);
        const [TuberculesdeManioc,setTuberculesdeManioc] = useState([]);
        const [BananePlantain,setBananePlantain] = useState([]);
        const [Haricot,setHaricot] = useState([]);
        const [Igname,setIgname] = useState([]);
        const [Oignons,setOignons] = useState([]);
        const [Ciboules,setCiboules] = useState([]);
        const [Piment,setPiment] = useState([]);
        const [Aubergine,setAubergine] = useState([]);
        const [Concombre,setConcombre] = useState([]);
        const [PoivronVert,setPoivronVert] = useState([]);
        const [Gingembre,setGingembre] = useState([]);
        const [PommedeTerre,setPommedeTerre] = useState([]);
        const [PatateDouce,setPatateDouce] = useState([]);
        const [Maïs,setMaïs] = useState([]);
         const [PoisdAngole,setPoisdAngole] = useState([]);
        const [Carotte,setCarotte] = useState([]);
        
useEffect(() =>{
        
         const getTomateList = async ()=>{
            const res = await axios.get('https://b-elanga-store.onrender.com/api/tomate/');

            setTomateList(res.data);     
         };

         const getArachideList = async ()=>{
            const res = await axios.get('https://b-elanga-store.onrender.com/api/arachide/');
            setArachideList(res.data);     
         };

         const getTuberculesdeManiocList = async ()=>{
            const res = await axios.get('https://b-elanga-store.onrender.com/api/tuberculeDeManioc/');
            setTuberculesdeManioc(res.data);     
         };
         const getBananePlantainList = async ()=>{
            const res = await axios.get('https://b-elanga-store.onrender.com/api/bananePlantain/');
            setBananePlantain(res.data);     
         };
         const getHaricotList = async ()=>{
            const res = await axios.get('https://b-elanga-store.onrender.com/api/haricot/');
            setHaricot(res.data);     
         };
         const getIgnameList = async ()=>{
            const res = await axios.get('https://b-elanga-store.onrender.com/api/igname/');
            setIgname(res.data);     
         };
         const getOignonsList = async ()=>{
            const res = await axios.get('https://b-elanga-store.onrender.com/api/oignons/');
            setOignons(res.data);     
         };
         const getCiboulesList = async ()=>{
            const res = await axios.get('https://b-elanga-store.onrender.com/api/ciboules/');
            setCiboules(res.data);     
         };
         const getPimentList = async ()=>{
            const res = await axios.get('https://b-elanga-store.onrender.com/api/piment/');
            setPiment(res.data);     
         };
         const getAubergineList = async ()=>{
            const res = await axios.get('https://b-elanga-store.onrender.com/api/aubergine/');
            setAubergine(res.data);     
         };
         const getConcombreList = async ()=>{
            const res = await axios.get('https://b-elanga-store.onrender.com/api/concombre/');
            setConcombre(res.data);     
         };
         const getPoivronVertList = async ()=>{
            const res = await axios.get('https://b-elanga-store.onrender.com/api/poivronVert/');
            setPoivronVert(res.data);     
         };
         const getGingembreList = async ()=>{
            const res = await axios.get('https://b-elanga-store.onrender.com/api/gingembre/');
            setGingembre(res.data);     
         };
         const getPommedeTerreList = async ()=>{
            const res = await axios.get('https://b-elanga-store.onrender.com/api/pommedeTerre/');
            setPommedeTerre(res.data);     
         };
         const getPatateDouceList = async ()=>{
            const res = await axios.get('https://b-elanga-store.onrender.com/api/patateDouce/');
            setPatateDouce(res.data);     
         };
         const getMaïsList = async ()=>{
            const res = await axios.get('https://b-elanga-store.onrender.com/api/mais/');
            setMaïs(res.data);     
         };
         const getPoisdAngoleList = async ()=>{
            const res = await axios.get('https://b-elanga-store.onrender.com/api/poisdAngole/');
            setPoisdAngole(res.data);     
         };
         const getCarotteList = async ()=>{
            const res = await axios.get('https://b-elanga-store.onrender.com/api/carotte/');
            setCarotte(res.data);     
         };


         getTomateList();
         getArachideList();
        getTuberculesdeManiocList();
        getBananePlantainList();
        getHaricotList();
        getIgnameList();
        getOignonsList();
        getCiboulesList();
        getPimentList();
        getAubergineList();
        getConcombreList();
        getPoivronVertList();
        getGingembreList();
        getPommedeTerreList();
        getPatateDouceList();
        getMaïsList();
        getPoisdAngoleList();
        getCarotteList();
        

    },[])

    return (
        <div>
            <Header/>
            <div className="publishO">
            <form onSubmit={handleAnonce}
            autoComplete="off"
            >

                <div className="publishIn">
                    <div className="publishInfo">
                    <div className='nameIn'>
                    
                <ul>
                    
                    <li> 
                    <h1> Poster votre Annonce :</h1>
                
            </li>
                    {/* <li> <span id='asterix'>*</span>
                    <input id="nameC" type="text" placeholder=' Ecrivez votre Nom' name='nameC'
                    required
                    

                    onChange={e=>{setLastName(e.target.value)}}

                    onInvalid={e=>e.target.setCustomValidity('Veuillez Entrer Votre Nom svp')}

               onInput={e=>e.target.setCustomValidity('')}
                
            /></li> */}


                    {/* <li><span id='asterix'>*</span>
                    <input id="nameC" type="text" placeholder=' Ecrivez votre Prénom' 
                    name='nameF'
                    onChange={e=>{setFirstName(e.target.value)}} 
                    required
                    
                onInvalid={e=>e.target.setCustomValidity('Veuillez Entrer Votre Prénom svp')}

               onInput={e=>e.target.setCustomValidity('')}
            />
            </li> */}


                    {/* <li><span id='asterix'>*</span>
                    <input type="number"  name="" id="numberCl" 
                    placeholder=' Votre Numéro ex:(06xxxxxxx)'
                    inputMode="decimal"
                   required
                  
                    min="1"
                    onChange={e=>{setContactFarmer(e.target.value)}}

                    onInvalid={e=>e.target.setCustomValidity('Veuillez remplir ce champ comme 06 XXX XX XX')}

                    onInput={e=>e.target.setCustomValidity('')}
                />
            </li> */}


                    <li><span id='asterix'>*</span>
                    <input id="nameC" type="text" placeholder=' Adresse ex(Ville de la ferme)'  
                    onChange={e=>{setFarmingAdresse(e.target.value)}} 
                    required
                    name='nameAd'

                    onInvalid={e=>e.target.setCustomValidity('Veuillez Entrer La Ville où se trouve votre Ferme Agricole svp')}

               onInput={e=>e.target.setCustomValidity('')}
                
            />  
            </li>
                    <li>
                    
            <select name="culture" id="villeIn" 
            value={typeCulture}
            onChange={e=>{setTypeCulture(e.target.value)}} 
            required
            
            >

                <option value="Type de Culture">Type de Culture</option>
                <option value="Tubercules de Manioc">Tubercules de Manioc</option>
                <option value="Banane Plantain">Banane Plantain</option>
                <option value="Tomates">Tomates</option>
                <option value="Haricot">Haricot</option>
                <option value="Igname">Igname</option>
                <option value="Arachide">Arachide</option>
                <option value="Oignons">Oignons</option>
                <option value="Ciboules">Ciboules</option>
                <option value="Piment">Piment</option>
                <option value="Aubergine">Aubergine</option>
                <option value="Concombre">Concombre</option>
                <option value="Poivron Vert">Poivron Vert</option>
                <option value="Gingembre">Gingembre</option>
                <option value="Pomme de Terre">Pomme de Terre</option>
                <option value="Patate Douce">Patate Douce</option>
                <option value="Maïs">Maïs</option>
                <option value="Pois d'Angole">Pois d'Angole</option>
                <option value="Carotte">Carotte</option>
                <option value="Soja">Soja</option>
                <option value="Sorgho">Sorgho</option>
                

                </select>
            
            </li>
                    <li><span id='asterix'>*</span>
                    <input id="numberCl" type="number" placeholder=' Le prix pour 1 kilo ex:(1.500)'  
                    name='nameP'
                    onChange={e=>{setPriceCulture(e.target.value)}}
                    step="0.001"
                    inputMode="decimal"
                    min="1"
                    required

                    onInvalid={e=>e.target.setCustomValidity('Veuillez Entrer le Prix pour 1 kilo')}

               onInput={e=>e.target.setCustomValidity('')}
                
            /></li>
                    <li><span id='asterix'>*</span>
                    <input id="numberCl" type="number" placeholder='Kilo Disponible (ex: 100)'  
                    inputMode="decimal"
                    min="0"
                    required
                    name='nameK'

                    onChange={e=>{setQuantityCulture(e.target.value)}}
                    
                    onInvalid={e=>e.target.setCustomValidity('Veuillez mettre le Nombre de kilo Disponible')}

                    onInput={e=>e.target.setCustomValidity('')}
                
            /></li>
                    
            
                </ul>
                </div>

                    </div>

                    

                    <div className="publishButton">
                    <div className='btnReserver'>

            
                
            <button id='btn-reserv'
            name='nameS' type="submit" disabled={submit ? true:false} >
            POSTER
            </button>

             

            </div>

                    </div>

                
                </div>
                 </form>
            </div>
            <ToastContainer/>
            <Footer/>
        </div>
    )
}

export default Publish
