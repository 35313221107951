import React, { useState,useContext, useEffect } from 'react'
import { FaBars, FaTimes,  } from 'react-icons/fa'
import { Link} from 'react-router-dom'
import { Context } from '../context/Context';



const Header = () => {
const { user, dispatch,admin } = useContext(Context);

const handleLogout = ()=>{
    dispatch({type:"LOGOUT"})

}

const [monitor, setMonitor] = useState(false);

const moni = ()=>{
if(user){

    if(user.userStatus ==="admin"){
        setMonitor(true);
    }
}
}

    const [menu,setMenu] = useState(false);
  const [close,setClose] = useState(true);

  

  const [cat, setCat] = useState([]);

  useEffect(() => {
    moni();
    
  }, []);

    return (
        <div className='navBarO'>

        <div className="navBar">

        <div className="logo">
        <div className="logoV">
            <Link to="/" ><img src="favicon-32x32.png" alt="" /></Link>
        </div>
        <div className="logoN">
        <Link to="/">
            <h2>BILANGA <span> STORE</span></h2></Link>
        </div>

        </div>
        <div className="menu">

            <div className="userName">
            {/* {user &&(<h3 id='userString'>{user.userFirstName}</h3>)} */}
              
                  

               {user&&(<Link to={`/profile/${user._id}`}> <h3 id="userString"> {user.userFirstName} </h3> </Link>)}

               {user&&(<Link to='/publier'> <h3 id="userString"> Publier </h3> </Link>)}

               {monitor &&(<Link to='/register' ><h3 id='userString'>S'Enregitrer</h3></Link>)}
                    
                {/* {user &&(<Link to={`/?user=${user.username}`} ><h3 id='userString'>Mes Annonces</h3></Link>)}
                 */}
                {user &&(<Link to={`/reabonnement/${user._id}`} ><h3 id='userString'>Réabonnement</h3></Link>)}

                
             
              {
                user ? (<Link to="/" ><h3 id='userString' onClick={handleLogout}>Se Déconnecter</h3></Link>):(<Link to='/login' ><h3 id='userString'>Se Connecter</h3></Link>)
              }
              
            </div>
            <div>

            {close && (
            <FaBars id='menuBar' size="2em"
                onClick={
                    ()=>{
                        setMenu(!menu)
                        setClose(!close)
                    }
                }
            
          />
          )}

          {!close &&(
          <FaTimes id='menuBarClose' size="2em"
            onClick={() => {
            setMenu(!menu)
            setClose(!close)
                     }
           }
           
           />
         
            )}

            </div>

            

        </div>

        </div>

       
       
        {menu && (

            <div className='menuOpen'>
       
                <ul>

                 <li><Link to='/'> <h2 id="headerA"> Accueil </h2> </Link></li> 

                {user && (<>


                    <li><Link to={`/profile/${user._id}`}> <h2 id="headerLogin"> Profil</h2> </Link></li>

                    <li><Link to='/publier'> <h2 id="headerLogin"> Publier </h2> </Link></li>

                {/* <li><Link to={`/?user=${user.userFirstName}`}> <h2 id="headerLogin"> Mes Annonces </h2> </Link></li> */}
                

                <li><Link to={`/reabonnement/${user._id}`} ><h2 id="headerLogin">Réabonnement</h2></Link></li>
                
        </>)}

        {monitor &&(
            <li><Link to='/admin'> <h2 id="headerLogin"> Admin </h2> </Link></li>
        )}

                    <li><Link to='/about'> <h2 id="headerA"> A Propos de Bilanga Store </h2> </Link></li> 

                    {user && (<li onClick={handleLogout} ><Link to='/'  > <h2 id="headerLogin"> Se Déconnecter </h2> </Link></li>)}
                
                {!user &&(
                    <li><Link to='/login'> <h2 id="headerLogin"> Se Connecter </h2> </Link></li>
                )}

                   
                    
                </ul>

                 </div>

                )}
        

            
        </div>
    )
}

export default Header
