import React, { useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom'
import Cover from './Cover'
import Footer from './Footer'
import Header from './Header'
import MainPost from './MainPost'
import axios from "axios"
import { Helmet } from 'react-helmet'



const Home = () => {

    const [posts, setPost] = useState([]);
        const {search} = useLocation();
       
 
useEffect(() =>{
        //  window.scrollTo(0,0);
         
        const fectchPost = async ()=>{
            const res = await axios.get(`https://b-elanga-store.onrender.com/api/post/`+search);
            
           const timer1 = setTimeout(()=>{setPost(res.data)},0);
            
            
        }

        fectchPost();
        
        
    },[search])



    return (
        <div>
        <Helmet>
        <link rel="canonical" href ="https://bilangastore.com/"/>

        <title>Bilanga Store</title>
        <meta name="description" content="Venez Vendre et Acheter les produits Agricoles sur Le premier site web d'Annonces reserves aux Produits Agricoles au Congo-Brazzaville" />
        
      </Helmet>
        <Header/>
        <Cover/>
       
        <MainPost
            posts={posts}
        />

       
    
        <Footer/>
           
            
        </div>
    )
}

export default Home
