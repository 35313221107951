import React, { useContext, useEffect, useState } from 'react'
import Footer from './Footer'
import Header from './Header'

import { FaCheckCircle } from 'react-icons/fa';
import axios from "axios"
import { Context } from '../context/Context'
import { ToastContainer, toast } from 'react-toastify';
import { FaEye, FaEyeSlash } from 'react-icons/fa'
import { Link, useLocation } from 'react-router-dom';

const Profile = () => {
    useEffect(() => {
     window.scrollTo(0,0);

     
    }, [])

    const { user } = useContext(Context)
    const location = useLocation();

    const path =location.pathname.split("/")[2];

const [submit,setSubmit]=useState(false);


const [password,setPassword] = useState("")
    
    // New Agriculteurs

     const [passwordType, setPasswordType] = useState("password");

const showPass = ()=>{

    if(passwordType==="password"){
        
        setPasswordType("text");
        return;
    }else{

        setPasswordType("password");
    } 

}

    // u

    const handleUpdate = async (e)=>{
            e.preventDefault();
           
            setSubmit(true);

            try{ 
            const res = await axios.patch("https://b-elanga-store.onrender.com/api/users/" + path, {
               userId:user._id,
               password
            });

toast.success("Succès: Votre mot de Pass a été mise à jour ");

             
              setTimeout(()=>{window.location.replace("/");},3000);  

                } catch(error){

                     toast.error(` Echec: Veuillez vérifier vos Informations svp`)
 
                }

        }


    return (
        <div>
            <Header/>
            
<div className="publishO2">
            <form onSubmit={handleUpdate}
            autoComplete="off"
            >

                <div className="publishIn">
                    <div className="publishInfo">
                    <div className='nameIn'>
                    
                <ul>
                    
                    <li> 
                    <h1> Changer votre code Pin</h1>
                
            </li>
                    
               
                   <li><span id='asterix'>*</span>   
                     <input type={passwordType} name="password" 
                      
                     id="numberCl" placeholder='Entrer un code pin de 4 chiffres' required
                        onChange={(e)=> setPassword(e.target.value)}

                        onInvalid={e=>e.target.setCustomValidity("Veuillez Entrer Votre Mot de Pass svp")}

               onInput={e=>e.target.setCustomValidity('')}
                    />
                    {passwordType ==="password" ? (<FaEyeSlash size="1.5em" id="eye3" onClick={showPass} />):(<FaEye id="eye3" size="1.5em" 
                        onClick={showPass}
                    />)}
                   </li>

                   <button id='btn-reserv'
            name='nameS' type="submit" disabled={submit ? true:false} >
            Mettre a Jour
            </button>
                    
            
           

                </ul>
                </div>

                    </div>

                    

                    <div className="publishButton">
                    <div className='btnReserver'>


 <Link to={`/reabonnement/${user._id}`}>
            <button id='btn-reserv2'
            name='nameS' type="button" disabled={submit ? true:false} >
           Se Réabonner
            </button></Link>
           
            </div>

                    </div>

                
                </div>
            
                 
           

            
        </form>

         </div>

         <div className="space">

         

         </div>


        
            <ToastContainer/>
            <Footer/>
        </div>
    )

}

export default Profile
